import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
  FormValidators
} from 'src/app/constants';

import {
  Item
} from 'src/app/domain/models';

@Component({
  selector: 'app-add-item-to-order',
  templateUrl: './add-item-to-order.dialog.html',
  styleUrls: ['./add-item-to-order.dialog.scss']
})
export class AddItemToOrderDialog implements AfterViewInit, OnDestroy, OnInit {

  private _subPartAutoTrigger: Subscription;
  private _subSelectedPart: Subscription;

  public filteredParts: Observable<Item[]>;
  public form: FormGroup;

  @ViewChild('partAutoInput', { read: MatAutocompleteTrigger }) partAutoTrigger: MatAutocompleteTrigger;
  
  public get f(): any {
    return this.form.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<AddItemToOrderDialog>,
    private formBuilder: FormBuilder) {
      console.log(this.data);
      this.form = this.formBuilder.group({
        qtyAvailable: [{ value: null, disabled: true }, Validators.required ],
        qtyToOrder: [{ value: null, disabled: true }, Validators.required ],
        selectedPart: [null, Validators.compose([Validators.required, this._autocompleteValidator.bind(this)])],
        unitPrice: [{ value: null, disabled: true }, Validators.required ],
        uom: [{ value: null, disabled: true }, Validators.required ]
      });
    }

  ngAfterViewInit() {
    this._subPartAutoTrigger = this.partAutoTrigger.panelClosingActions.subscribe(() => {
      if (this.partAutoTrigger.activeOption)
        this.f.selectedPart.setValue(this.partAutoTrigger.activeOption.value);
    });
  }
  ngOnDestroy() {
    if (this._subPartAutoTrigger)
      this._subPartAutoTrigger.unsubscribe();
    if (this._subSelectedPart)
      this._subSelectedPart.unsubscribe();
  }
  ngOnInit() {

    this.filteredParts = this.f.selectedPart.valueChanges
      .pipe(
        map(val => this._filterItems(val)));

    this._subSelectedPart = this.f.selectedPart.valueChanges
      .subscribe((val: Item) => {
        if (!this.f.selectedPart.valid) {
          this.f.qtyAvailable.setValue(null);
          this.f.unitPrice.setValue(null);
          this.f.uom.setValue(null);
          this.f.qtyToOrder.disable();
        }
        else {
          this.f.qtyAvailable.setValue(val.qtyAvailable);
          this.f.unitPrice.setValue(val.price);
          this.f.uom.setValue(val.uom);
          this.f.qtyToOrder.enable();
        }
      });
  }

  private _autocompleteValidator(control: AbstractControl) {
    return control.value && typeof control.value === 'string'
      ? { autocomplete: true }
      : null;
  }

  private _filterItems(match: any) : Item[] {
    // ensure string
    match += '';

    // ignore until length 3
    if (match.length < 3)
      return [];

    // initialize priority arrays
    let priority1Matches: Item[] = [];
    let priority2Matches: Item[] = [];
    let priority3Matches: Item[] = [];
    let priority4Matches: Item[] = [];
    let priority5Matches: Item[] = [];

    // iterate
    for (var i = 0; i < this.data.parts.length; i++) {
      // priority 1: part startsWith
      if (this.data.parts[i].part.toLowerCase().startsWith(match.toLowerCase()))
        priority1Matches.push(this.data.parts[i]);
      // priority 2: part contains
      else if (this.data.parts[i].part.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority2Matches.push(this.data.parts[i]);
      // priority 3: customerItem startsWith
      else if (this.data.parts[i].customerItem.toLowerCase().startsWith(match.toLowerCase()))
        priority3Matches.push(this.data.parts[i]);
      // priority 4: description startsWith
      else if (this.data.parts[i].description.toLowerCase().startsWith(match.toLowerCase()))
        priority4Matches.push(this.data.parts[i]);
      // priority 5: customerItem || description contains
      else if (this.data.parts[i].customerItem.toLowerCase().indexOf(match.toLowerCase()) > -1 || this.data.parts[i].description.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority5Matches.push(this.data.parts[i]);
    }

    // concat, return
    return priority1Matches.concat(priority2Matches).concat(priority3Matches).concat(priority4Matches).concat(priority5Matches);
  }

  private _validateForm(): void {
    Object.keys(this.f).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl)
        control.markAsTouched({ onlySelf: true });
    });
  }

  public async cancel(): Promise<void> {
    this.dialogRef.close();
  }

  public displayPartWith(part: Item): string | undefined {
    return part
      ? `${part.part} (${part.description})`
      : undefined;
  }

  public getQtyAvailableError(): string {
    return this.f.qtyAvailable.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getQtyToOrderError(): string {
    return this.f.qtyToOrder.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getSelectedPartError(): string {
    return this.f.selectedPart.hasError('autocomplete')
      ? FormValidators.autocomplete
      : this.f.selectedPart.hasError('required')
        ? FormValidators.required
        : FormValidators.none;
  }
  public getUnitPriceError(): string {
    return this.f.unitPrice.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getUomError(): string {
    return this.f.uom.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }

  public async submit(): Promise<void> {
    this._validateForm();

    if (!this.form.valid)
      return;

    console.log(this.form.value);
    
    this.dialogRef.close(this.form.value);
  }

}
