import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-renderer',
  template: `
    <mat-icon [ngClass]="class" (click)="onClick($event)">{{icon}}</mat-icon>
    `
})

export class ButtonRendererComponent implements ICellRendererAngularComp {

  class: string;
  icon: string;
  params;

  agInit(params): void {
    this.params = params;
    this.class = this.params.class;
    this.icon = this.params.icon;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(this.params);

    }
  }
  
}
