import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import {
  ApiResponses,
  ApiRoutes
} from 'src/app/constants';

import {
  AddShipToRequest,
  AddShipToResponse,
  CountriesResponse,
  ItemAvailabilityRequest,
  ItemAvailabilityResponse,
  ItemsRequest,
  ItemsResponse,
  MinimumRequiredDateRequest,
  MinimumRequiredDateResponse,
  OrderSummariesRequest,
  OrderSummariesResponse,
  PricelistRequest,
  PricelistResponse,
  ShipToRequest,
  ShipToResponse,
  ShipViaResponse,
  StatesResponse,
  SubmitOrderRequest,
  SubmitOrderResponse
} from 'src/app/domain/requestresponseobjects';

import {
  AjaxService,
  LoggingService,
  UtilityService } from '.';

@Injectable()
export class OrderService {

  private _apiBaseUri: string;

  constructor(
    private ajaxService: AjaxService,
    private http: HttpClient,
    private loggingService: LoggingService,
    private utilityService: UtilityService) {
      this._apiBaseUri = this.utilityService.currentEnvironment === 'Development'
        ? environment.apiDevUri
        : environment.apiBaseUri;
      this.loggingService.warn('OrderService initialized.')
  }

  public addShipTo$(request: AddShipToRequest): Observable<AddShipToResponse> {
    this.loggingService.info('Adding ShipTo...');

    return this.ajaxService
      .POST<AddShipToResponse>(
        ApiRoutes.addshipto,
        request,
        new AddShipToResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ ApiResponses.COMMON_ERROR_MESSAGE ]
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('AddShipToResponse');
          this.loggingService.warn(response);
          if(response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('ShipTo add successful.');
          }
          else {
            // failure
            this.loggingService.error('ShipTo add failed.');
          }

          // return
          return response;
        }));
  }

  public countries$(): Observable<CountriesResponse> {
    this.loggingService.info('Fetching Countries...');

    return this.ajaxService
      .POST<CountriesResponse>(
        ApiRoutes.countries,
        null,
        new CountriesResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ ApiResponses.COMMON_ERROR_MESSAGE ],
          countries: []
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('CountriesResponse');
          this.loggingService.warn(response);
          if(response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('Country fetch successful.');
          }
          else {
            // failure
            this.loggingService.error('Country fetch failed.');
          }

          // return
          return response;
        }));
  }

  public itemAvailability$(request: ItemAvailabilityRequest): Observable<ItemAvailabilityResponse> {
    this.loggingService.info('Fetching Item Availability Date...');

    return this.ajaxService
      .POST<ItemAvailabilityResponse>(
        ApiRoutes.itemavailability,
        request,
        new ItemAvailabilityResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ ApiResponses.COMMON_ERROR_MESSAGE ]
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('ItemAvailabilityResponse');
          this.loggingService.warn(response);
          if(response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('Item Availability fetch successful.');
          }
          else {
            // failure
            this.loggingService.error('Item Availability fetch failed.');
          }

          // return
          return response;
        }));
  }

  public items$(request: ItemsRequest): Observable<ItemsResponse> {
    this.loggingService.info('Fetching Items...');

    return this.ajaxService
      .POST<ItemsResponse>(
        ApiRoutes.items,
        request,
        new ItemsResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ ApiResponses.COMMON_ERROR_MESSAGE ],
          items: []
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('ItemsResponse');
          this.loggingService.warn(response);
          if(response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('Item fetch successful.');
          }
          else {
            // failure
            this.loggingService.error('Item fetch failed.');
          }

          // return
          return response;
        }));
  }

  public minimumRequiredDate$(request: MinimumRequiredDateRequest): Observable<MinimumRequiredDateResponse> {
    this.loggingService.info('Fetching Minimum Order Required Date...');

    let minReqDate: Date = new Date();
    minReqDate.setDate(minReqDate.getDate() + 3);

    return this.ajaxService
      .POST<MinimumRequiredDateResponse>(
        ApiRoutes.minreqdate,
        request,
        new MinimumRequiredDateResponse().deserialize({
          requiredDate: minReqDate,
          messages: [ ApiResponses.COMMON_ERROR_MESSAGE ],
          status: ApiResponses.FAILURE
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('MinimumRequiredDateResponse');
          this.loggingService.warn(response);
          if(response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('Minimum Order Required Date fetch successful.');
          }
          else {
            // failure
            this.loggingService.error('Minimum Order Required Date fetch failed.');
          }

          // return
          return response;
        }));
  }

  public orderSummaries$(request: OrderSummariesRequest): Observable<OrderSummariesResponse> {
    this.loggingService.info('Fetching Order Summaries...');

    return this.ajaxService
      .POST<OrderSummariesResponse>(
        ApiRoutes.ordersummaries,
        request,
        new OrderSummariesResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ ApiResponses.COMMON_ERROR_MESSAGE ],
          orderSummaries: []
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('OrderSummariesResponse');
          this.loggingService.warn(response);
          if(response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('Order Summary fetch successful.');
          }
          else {
            // failure
            this.loggingService.error('Order Summary fetch failed.');
          }

          // return
          return response;
        }));
  }

  public pricelists$(request: PricelistRequest): Observable<PricelistResponse> {
    this.loggingService.info('Fetching Pricelists...');

    return this.ajaxService
      .POST<PricelistResponse>(
        ApiRoutes.pricelists,
        request,
        new PricelistResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ ApiResponses.COMMON_ERROR_MESSAGE ],
          pricelists: []
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('PricelistResponse');
          this.loggingService.warn(response);
          if(response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('Pricelist fetch successful.');
          }
          else {
            // failure
            this.loggingService.error('Pricelist fetch failed.');
          }

          // return
          return response;
        }));
  }

  public pdfInvoice(invoiceNo: string): void {
    this.utilityService.showLoader();
    this.http.post(
      `${this._apiBaseUri}${ApiRoutes.pdfinvoice}/${invoiceNo}`,
      null,
      { responseType: 'blob'})
      .subscribe(data => {
        var file = new Blob([data], { type: 'application/pdf' });
        var fileUrl = URL.createObjectURL(file);
        this.utilityService.dismissLoader();
        var w = window.open(fileUrl);
      }, (ex) => {
        this.utilityService.dismissLoader();
      });
  }

  public pdfSalesOrder(salesorderNo: string): void {
    this.utilityService.showLoader();
    this.http.post(
      `${this._apiBaseUri}${ApiRoutes.pdfsalesorder}/${salesorderNo}`,
      null,
      { responseType: 'blob'})
      .subscribe(data => {
        var file = new Blob([data], { type: 'application/pdf' });
        var fileUrl = URL.createObjectURL(file);
        this.utilityService.dismissLoader();
        var w = window.open(fileUrl);
        //w.print();
      }, (ex) => {
        this.utilityService.dismissLoader();
      });
  }

  public shipTos$(request: ShipToRequest): Observable<ShipToResponse> {
    this.loggingService.info('Fetching ShipTos...');

    return this.ajaxService
      .POST<ShipToResponse>(
        ApiRoutes.shipto,
        request,
        new ShipToResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ ApiResponses.COMMON_ERROR_MESSAGE ],
          shipTos: []
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('ShipToResponse');
          this.loggingService.warn(response);
          if(response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('ShipTo fetch successful.');
          }
          else {
            // failure
            this.loggingService.error('ShipTo fetch failed.');
          }

          // return
          return response;
        }));
  }

  public shipVias$(): Observable<ShipViaResponse> {
    this.loggingService.info('Fetching ShipVias...');

    return this.ajaxService
      .POST<ShipViaResponse>(
        ApiRoutes.shipvia,
        null,
        new ShipViaResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ ApiResponses.COMMON_ERROR_MESSAGE ],
          shipVias: []
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('ShipViaResponse');
          this.loggingService.warn(response);
          if(response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('ShipVia fetch successful.');
          }
          else {
            // failure
            this.loggingService.error('ShipVia fetch failed.');
          }

          // return
          return response;
        }));
  }

  public states$(): Observable<StatesResponse> {
    this.loggingService.info('Fetching States...');

    return this.ajaxService
      .POST<StatesResponse>(
        ApiRoutes.states,
        null,
        new StatesResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ ApiResponses.COMMON_ERROR_MESSAGE ],
          states: []
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('StatesResponse');
          this.loggingService.warn(response);
          if(response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('States fetch successful.');
          }
          else {
            // failure
            this.loggingService.error('States fetch failed.');
          }

          // return
          return response;
        }));
  }

  public submitOrder$(request: SubmitOrderRequest): Observable<SubmitOrderResponse> {
    this.loggingService.info('Submitting Order...');

    return this.ajaxService
      .POST<SubmitOrderResponse>(
        ApiRoutes.submitorder,
        request,
        new SubmitOrderResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ ApiResponses.COMMON_ERROR_MESSAGE ]
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('SubmitOrderResponse');
          this.loggingService.warn(response);
          if(response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('Order submission successful.');
          }
          else {
            // failure
            this.loggingService.error('Order submission failed.');
          }

          // return
          return response;
        }));
  }

}
