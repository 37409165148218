import { Component } from '@angular/core';
import { AgRendererComponent, ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { OrderSummary } from 'src/app/domain/models';
import { OrderService } from 'src/app/services';

@Component({
  selector: 'pdf-link-renderer',
  template: `
  <ng-container *ngIf="displayAsLink">
    <a class="pdf-link" [ngClass]="linkClass()" *ngFor="let title of titles" (click)="clickHandler(title)">{{ title }}</a>
  </ng-container>
  <ng-container *ngIf="!displayAsLink">
    <span *ngFor="let title of titles">{{ title }}</span>
  </ng-container>
  `
})
export class PdfLinkRendererComponent implements ICellRendererAngularComp {

  private _isInvoice: boolean = false;
  private _params: ICellRendererParams;

  public displayAsLink: boolean = false;
  public titles: string[] = [];

  constructor(private orderService: OrderService) { }

  agInit(params: ICellRendererParams): void {
    this._params = params;
    this._setFieldAndDisplay();
    this._setTitles();
  }
  public clickHandler(val: string): void {
    if (this._isInvoice)
      this.orderService.pdfInvoice(val);
    else
      this.orderService.pdfSalesOrder(val);
  }
  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  private _setFieldAndDisplay(): void {
    // set field
    this._isInvoice = this._params.colDef.field === 'invoice';
    // set display
    if (this._isInvoice) {
      this.displayAsLink = true;
    }
    else {
      this.displayAsLink = (<OrderSummary>this._params.data).status !== 'Shipped' || (<OrderSummary>this._params.data).invoice?.length === 0;
    }
  }
  private _setTitles(): void {
    //this.title = this._params.valueFormatted;
    if (this._isInvoice) {
      this.titles = (<string>this._params.valueFormatted)
        .replace(' ', '')
        .split(',');
      if (this.titles.length === 1 && this.titles[0] === '')
        this.titles = [];
      
    }
    else {
      this.titles = [ this._params.valueFormatted ];
    }
  }

  public linkClass(): string {
    return this.titles.length > 1
      ? 'comma-after'
      : null;
  }
}
