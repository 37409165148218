
<mat-card>

  <mat-card-content>
    <img class="aw-logo" src="../../../../assets/images/Logo.png"/>
    <h2 class="outer-heading">Please Sign In</h2>
  
    <div class="aw-message">
      <div>For Technical Assistance:</div>
      <div>Please contact our Customer Service Center.</div>
      <div>{{companyPhone}}</div>
    </div>
    
    <form [formGroup]="form">

      <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>Email Address:</mat-label>
        <input matInput formControlName="email" type="email" />
        <mat-error *ngIf="f.email.invalid">{{getEmailError()}}</mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>Password:</mat-label>
        <input matInput formControlName="password" type="password" />
        <mat-error *ngIf="f.password.invalid">{{getPasswordError()}}</mat-error>
      </mat-form-field>

      <div class="content-right">
        <button mat-flat-button color="primary" (click)="submit()">Sign In</button>
      </div>
      
    </form>
    
    <span class="aw-underlink">
      Forgot your password? <a (click)="openResetModal()">Reset here.</a>
    </span>
    <span class="aw-underlink">
      No account? <a (click)="openRegisterModal()">Register here.</a>
    </span>

  </mat-card-content>

</mat-card>
