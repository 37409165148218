import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import {
  IdentityService,
  UtilityService
} from '../services';

@Injectable({ providedIn: 'root' })
export class AnonGuard implements CanActivate {
    
    constructor(
        private identityService: IdentityService,
        private utilityService: UtilityService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let canActivate = !this.identityService.validateToken(false);

        if (!canActivate)
            this.utilityService.redirectToDashboard();

        return canActivate;
    }

}
