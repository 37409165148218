
<mat-card>

  <mat-card-title>
    {{action}} PDI Form
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">

      <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>PDI Form Title</mat-label>
        <input matInput formControlName="formTitle" type="text" />
        <mat-error *ngIf="f.formTitle.invalid">{{getFormTitleError()}}</mat-error>
      </mat-form-field>

      <mat-label>Product Lines:</mat-label>
      <ul>
        <li *ngFor="let pl of productLines; let i = index" >
          <mat-checkbox value="pl.productLine">({{pl.productLine}}) {{pl.description}}</mat-checkbox>
        </li>
      </ul>

      <!-- <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>Part</mat-label>
        <input #partAutoInput matInput placeholder="Type to Search..." formControlName="selectedPart" [matAutocomplete]="partAuto" type="text" />
        <mat-error *ngIf="f.selectedPart.invalid">{{getSelectedPartError()}}</mat-error>
        <mat-autocomplete #partAuto="matAutocomplete" [displayWith]="displayPartWith" autoActiveFirstOption>
          <mat-option disabled><div class="ac-span ac-span-header ac-span-20">PART #</div><div class="ac-span ac-span-header ac-span-50">DESCRIPTION</div><div class="ac-span ac-span-header ac-span-30">CUST PART #</div></mat-option>
          <mat-option *ngFor="let p of filteredParts | async" [value]="p"><div class="ac-span ac-span-20">{{p.part}}</div><div class="ac-span ac-span-50">{{p.description}}</div><div class="ac-span ac-span-30">{{p.customerItem}}</div></mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4-2-1">
        <mat-label>Unit Price</mat-label>
        <input matInput formControlName="unitPrice" type="text" />
        <mat-error *ngIf="f.unitPrice.invalid">{{getUnitPriceError()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4-2-1">
        <mat-label>Qty Available</mat-label>
        <input matInput formControlName="qtyAvailable" type="text" />
        <mat-error *ngIf="f.qtyAvailable.invalid">{{getQtyAvailableError()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4-2-1">
        <mat-label>Qty to Order</mat-label>
        <input matInput formControlName="qtyToOrder" type="text" />
        <mat-error *ngIf="f.qtyToOrder.invalid">{{getQtyToOrderError()}}</mat-error>
      </mat-form-field> -->
    
      <div class="content-right">
        <button mat-flat-button (click)="submit()">Save</button>
        <button mat-flat-button (click)="cancel()">Cancel</button>
      </div>

    </form>
  </mat-card-content>

</mat-card>
