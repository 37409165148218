
<mat-card>

  <mat-card-title>
    PDI Management
  </mat-card-title>

  <mat-card-content>

    <h2>PDI Forms</h2>

    <div class="content-right">
      <button mat-flat-button (click)="addPdi()">ADD NEW PDI FORM</button>
    </div>

    <section>

      <ag-grid-angular class="ag-theme-alpine"
        [columnDefs]="columnDefs" domLayout="autoHeight"
        [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)" [rowData]="pdiHeaders"
        style="width: 100%;"></ag-grid-angular>

    </section>
  
  </mat-card-content>
  
</mat-card>
