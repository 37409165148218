import { _deserializable } from '../_deserializable';
import { PdiForm } from 'src/app/domain/models';

export class RegisterProductRequest extends _deserializable {

    public comments: string;
    public deliveryDate: Date;
    public modelNumber: string;
    public ownerId: string;
    public part: string;
    public serialNumber: string;
    public warrantyCode: string;

    public pdiForm: PdiForm;
    
}
