import { _deserializable } from '../_deserializable';
import { Customer } from './customer.model';
import { Role } from './role.model';

export class User extends _deserializable {

  public id: string;

  public accountNumbers: string;
  public city: string;
  public company: string;
  public currentIpAddress: string;
  public customerIds: string;
  public customers: Customer[] = [];
  public email: string;
  public firstName: string;
  public name: string;
  public lastLoginIpAddress: string;
  public lastLoginTimestamp: Date;
  public lastName: string;
  public line1: string;
  public line2: string;
  public line3: string;
  public phoneNumber: string;
  public pricelist: string;
  public role: string;
  public roles: Role[] = [];
  public state: string;
  public status: number;
  public title: string;
  public zip: string;

  public createdOn: Date;
  public updatedOn: Date;
  public isDeleted: boolean;
  public deletedOn: Date;

}
