import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

import { PdiQuestion } from 'src/app/domain/models';

import {
} from 'src/app/services'

@Component({
  selector: 'app-pdi-question-ui',
  templateUrl: './pdi-question-ui.component.html',
  styleUrls: ['./pdi-question-ui.component.scss']
})
export class PdiQuestionUiComponent {

  @Input() index: number;
  @Input() question: PdiQuestion;

  @ViewChild('checkNA') checkNA: MatCheckbox;
  @ViewChild('checkOK') checkOK: MatCheckbox;
  @ViewChild('inputComment') inputComment: ElementRef;

  private _touched: boolean = false;
  private _value: string = null;

  public readonly: boolean = false;

  public get displayValidationError(): boolean {
    return (!this._value && this._touched)
      ? true
      : false;
  }
  public get questionId(): string {
    return this.question.id;
  }
  public get value(): string {
    return this._value;
  }

  constructor() {
  }

  public onChangeCheckNA(ev: any) {
    this._touched = true;

    if (this.checkNA.checked) {
      this.checkOK.checked = false;
      this.inputComment.nativeElement.value = null;
    }

    this._setValue();
  }
  public onChangeCheckOK(ev: any) {
    this._touched = true;

    if (this.checkOK.checked) {
      this.checkNA.checked = false;
      this.inputComment.nativeElement.value = null;
    }

    this._setValue();
  }
  public onChangeInputComment(ev: any) {
    this._touched = true;

    if (this.inputComment.nativeElement.value) {
      this.checkNA.checked = false;
      this.checkOK.checked = false;
    }

    this._setValue();
  }

  public isValid(applyTouch: boolean): boolean {
    if (applyTouch)
      this._touched = true;
    return (this._value)
      ? true
      : false;
  }
  private _setValue(): void {
    if (this.checkNA.checked)
      this._value = 'N/A';
    else if (this.checkOK.checked)
      this._value = 'OK';
    else if (this.inputComment.nativeElement.value)
      this._value = this.inputComment.nativeElement.value;
    else
      this._value = null;
  }
  public setReadonlyValue(value: string): void {
    console.warn('readonly set with value', value);
    this.readonly = true;

    switch (value) {
      case 'N/A':
        this.checkNA.writeValue(true);
        break;
      case 'OK':
        this.checkOK.writeValue(true);
        break;
      default:
        this.inputComment.nativeElement.value = value;
        break;
    }
  }

}
