import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import {
  ApiResponses,
  AppAlerts,
  FormValidators
} from 'src/app/constants';

import {
  IdentityService,
  UtilityService
} from 'src/app/services';
import { ResetPasswordDialog } from '..';

@Component({
  selector: 'app-send-reset',
  templateUrl: './send-reset.dialog.html',
  styleUrls: ['./send-reset.dialog.scss']
})
export class SendResetDialog implements OnInit {

  public formSubmitted: boolean = false;
  public form: FormGroup
  public responseMessage: string;
  public responseSuccess: boolean = false;

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ResetPasswordDialog>,
    private formBuilder: FormBuilder,
    private identityService: IdentityService,
    private utilityService: UtilityService) {
      this.form = this.formBuilder.group({
        email: ['', Validators.compose([Validators.required, Validators.email])]
      });
    }

  ngOnInit() {
  }

  private _validateForm(): void {
    Object.keys(this.f).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl)
        control.markAsTouched({ onlySelf: true });
    });
  }

  public getEmailError(): string {
    return this.f.email.hasError('required')
      ? FormValidators.required
      : this.f.email.hasError('email')
        ? FormValidators.email
        : FormValidators.none;
  }

  public async cancel(): Promise<void> {
    this.dialogRef.close();
  }
  public async submit(): Promise<void> {
    this._validateForm();
    
    if (this.formSubmitted || !this.form.valid)
      return;

    this.formSubmitted = true;

    this.identityService.sendReset(this.f.email.value)
      .subscribe(response => {
        Swal.fire('Success', AppAlerts.SendReset.success, 'success');
        this.dialogRef.close();
      });
  }

}
