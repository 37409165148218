import { Component, OnInit } from '@angular/core';

import { TextToken } from 'src/app/domain/models';

import { ContentService } from 'src/app/services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  text: string;

  constructor(private contentService: ContentService) {
  }

  ngOnInit() {
    this.contentService.texttokens$()
      .subscribe(tokens => {
        this.text = tokens.filter(t => t.name === 'HomePage.MainContentText')[0].text;
      });
  }

}
