<mat-card>
    <mat-card-title>
        {{ title }}
    </mat-card-title>

    <mat-card-content>
        <mat-label>(Click <a href="https://artsway.com/sales-person-bonus-registration/" target="_blank">here</a> for the Sales Person Bonus Registration form.)</mat-label><br/><br/><br/>
        <form [formGroup]="form">

            <h1>Registration Information</h1>

            <mat-form-field appearance="fill" class="col-2-2-1">
                <mat-label>Customer</mat-label>
                <input #customerAutoInput *ngIf="!isDealer" matInput placeholder="Type to Search..."
                    formControlName="selectedCustomer" [matAutocomplete]="customerAuto" type="text" />
                <mat-autocomplete #customerAuto="matAutocomplete" [displayWith]="displayCustomerWith">
                    <mat-option disabled>
                        <div class="ac-span ac-span-header ac-span-20">CUST #</div>
                        <div class="ac-span ac-span-header ac-span-50">NAME</div>
                        <div class="ac-span ac-span-header ac-span-30">LOCATION</div>
                    </mat-option>
                    <mat-option *ngFor="let c of filteredCustomers | async" [value]="c">
                        <div class="ac-span ac-span-20">{{c.customer}}</div>
                        <div class="ac-span ac-span-50">{{c.name}}</div>
                        <div class="ac-span ac-span-30">{{c.line1}} {{c.city}}, {{c.state}}</div>
                    </mat-option>
                </mat-autocomplete>
                <mat-select *ngIf="isDealer" formControlName="selectedCustomer">
                    <mat-option *ngFor="let c of filteredCustomers | async" [value]="c">{{c.customer}}</mat-option>
                </mat-select>
                <mat-error *ngIf="f.selectedCustomer.invalid">{{getSelectedCustomerError()}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-2-2-1">
                <mat-label>Owner</mat-label>
                <input #ownerAutoInput matInput placeholder="Type to Search..."
                    formControlName="selectedOwner" [matAutocomplete]="ownerAuto" type="text" />
                <mat-autocomplete #ownerAuto="matAutocomplete" [displayWith]="displayOwnerWith">
                    <mat-option disabled>
                        <div class="ac-span ac-span-header ac-span-20">Owner #</div>
                        <div class="ac-span ac-span-header ac-span-50">NAME</div>
                        <div class="ac-span ac-span-header ac-span-30">LOCATION</div>
                    </mat-option>
                    <mat-option value="ADD_NEW">ADD NEW...</mat-option>
                    <mat-option *ngFor="let o of filteredOwners | async" [value]="o">
                        <div class="ac-span ac-span-20">{{o.ownerId}}</div>
                        <div class="ac-span ac-span-50">{{o.name}}</div>
                        <div class="ac-span ac-span-30">{{o.address}} {{o.city}}, {{o.state}}</div>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="f.selectedOwner.invalid">{{getSelectedOwnerError()}}</mat-error>
            </mat-form-field>
          
            <mat-form-field appearance="fill" class="col-1-1-1">
                <mat-label>Part</mat-label>
                <input #partAutoInput matInput placeholder="Type to Search..." formControlName="selectedPart"
                    [matAutocomplete]="partAuto" type="text" />
                <mat-error *ngIf="f.selectedPart.invalid">{{getSelectedPartError()}}</mat-error>
                <mat-autocomplete #partAuto="matAutocomplete" [displayWith]="displayPartWith">
                    <mat-option disabled>
                        <div class="ac-span ac-span-header ac-span-20">PART #</div>
                        <div class="ac-span ac-span-header ac-span-50">DESCRIPTION</div>
                        <div class="ac-span ac-span-header ac-span-30">SERIAL #</div>
                    </mat-option>
                    <mat-option *ngFor="let p of filteredParts | async" [value]="p">
                        <div class="ac-span ac-span-20">{{p.part}}</div>
                        <div class="ac-span ac-span-50">{{p.description}}</div>
                        <div class="ac-span ac-span-30">{{p.serial}}</div>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="f.selectedOwner.invalid">{{getSelectedPartError()}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-2-2-1">
                <mat-label>Serial Number:</mat-label>
                <input matInput formControlName="serialNumber" type="text" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-2-2-1">
                <mat-label>Product Line:</mat-label>
                <input matInput formControlName="productLine" type="text" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-3-3-1">
                <mat-label>Model:</mat-label>
                <input matInput formControlName="model" type="text" />
                <mat-error *ngIf="f.model.invalid">{{getModelError()}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-3-3-1">
                <mat-label>Delivery Date</mat-label>
                <input matInput formControlName="deliveryDate" type="date" id="deliveryDate" />
                <mat-error *ngIf="f.deliveryDate.invalid">{{getDeliveryDateError()}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-3-3-1">
                <mat-label>Warranty Code:</mat-label>
                <input matInput formControlName="warrantyCode" type="text" maxlength="10" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-1-1-1">
                <mat-label>Comments</mat-label>
                <textarea matInput formControlName="comments" type="text" maxlength="280"></textarea>
                <mat-error *ngIf="f.comments.invalid">{{getCommentsError()}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-1-1-1">
                <mat-label>Sales Person</mat-label>
                <input matInput formControlName="salesPerson" type="text" maxlength="50" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-1-1-1">
                <mat-label>How did the Customer hear about this product? (In their words)</mat-label>
                <textarea matInput formControlName="howHeard" type="text" maxlength="500"></textarea>
                <mat-error *ngIf="f.howHeard.invalid">{{getHowHeardError()}}</mat-error>
            </mat-form-field>

            <hr/>

            <h1>Pre-Delivery Inspection</h1>

            <section *ngIf="!displayPdiForm">
                <mat-label><i>{{noPdiFormText}}</i></mat-label>
            </section>

            <section *ngIf="displayPdiForm">

                <h3>{{selectedPdiHeader.formTitle}}</h3>
                <h3>{{selectedPdiHeader.formSubtitle}}</h3>

                <ng-container *ngFor="let question of selectedPdiHeader.questions; index as idx">
                    <app-pdi-question-ui [index]="idx" [question]="question"></app-pdi-question-ui>
                </ng-container>

                <hr/>

                <h1>e-Signature</h1>

                <mat-form-field appearance="fill" class="col-esig-text">
                    <mat-label>Name of person completing the PDI form</mat-label>
                    <input matInput formControlName="personCompleting" type="text" id="eSignature" />
                    <mat-error *ngIf="f.personCompleting.invalid">Value is required</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" class="col-esig-check">
                    <mat-label>Inspection Date</mat-label>
                    <input matInput formControlName="inspectionDate" type="date" id="inspectionDate" />
                    <mat-error *ngIf="f.inspectionDate.invalid">Value is required</mat-error>
                </mat-form-field>

                <mat-checkbox formControlName="certifyComplete">I certify this PDI is complete and correct</mat-checkbox>
                <mat-label *ngIf="certifyCompleteError" style="color: red; display: block; font-size: 75%;">Must be checked</mat-label>
                <mat-label *ngIf="!certifyCompleteError" style="display: block; font-size: 75%;">&nbsp;</mat-label>

            </section>
            
            <div class="content-right">
                <button mat-flat-button color="primary" (click)="cancel()">{{cancelText}}</button>
                <button *ngIf="displaySubmit" mat-flat-button color="primary" (click)="submit()">Submit</button>
                <mat-label *ngIf="!hideSubmitError" style="color: red; display: block; font-size: 75%;">Please correct all fields marked above in red and re-submit</mat-label>
                <mat-label *ngIf="hideSubmitError" style="display: block; font-size: 75%;">&nbsp;</mat-label>
            </div>

        </form>

    </mat-card-content>

</mat-card>
