import { _deserializable } from '../_deserializable';

export class Customer extends _deserializable {

  public city: string;
  public customer: string;
  public line1: string;
  public line2: string;
  public line3: string;
  public name: string;
  public state: string;
  public zip: string;

}
