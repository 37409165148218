
<mat-card>

  <mat-card-title>
    Product Registration
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      
      <hr />
      <button mat-flat-button *ngIf="!filtersOpened && !isDesktop" (click)="filtersToggle()">Filters...<mat-icon>keyboard_arrow_down</mat-icon></button>
      <button mat-flat-button *ngIf="filtersOpened && !isDesktop" (click)="filtersToggle()"><mat-icon>keyboard_arrow_up</mat-icon>...Filters</button>

      <section [hidden]="!filtersOpened">
        <mat-form-field appearance="fill" class="infix-sm col-3-3-1">
          <mat-label>Filter by Date Range?</mat-label>
          <input matInput formControlName="includeDateRange" type="text" [hidden]="true" />
          <mat-checkbox formControlName="includeDateRange">Yes</mat-checkbox>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-3-3-1">
          <mat-label>Delivery Date Range Start</mat-label>
          <input matInput [disabled]="!f.includeDateRange" type="text" id="deliveryStartDate" />
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-3-3-1">
          <mat-label>Delivery Date Range End</mat-label>
          <input matInput [disabled]="!f.includeDateRange" type="text" id="deliveryEndDate" />
        </mat-form-field>

      </section>

      <hr />

    </form>

    <div>
      <button mat-flat-button color="primary" (click)="addNew()">Register Product</button>
    </div>

    <div class="content-right">
      <mat-form-field appearance="fill" class="grid-pagecount-selector">
        <mat-label>Records per Page</mat-label>
        <mat-select [(ngModel)]="paginationPageSize" (selectionChange)="onPaginationPageSizeChange()" name="paginationPageSize">
          <mat-option [value]="10">10</mat-option>
          <mat-option [value]="25">25</mat-option>
          <mat-option [value]="50">50</mat-option>
          <mat-option [value]="100">100</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ag-grid-angular
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    domLayout="autoHeight"
    [frameworkComponents]="frameworkComponents"
    (gridReady)="onGridReady($event)"
    [pagination]="true"
    [paginationNumberFormatter]="paginationNumberFormatter"
    [paginationPageSize]="paginationPageSize"
    [rowData]="products"
    style="width: 100%;"
    suppressCellSelection="true"></ag-grid-angular>
  </mat-card-content>

</mat-card>
