import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';

import {
  ApiResponses
} from 'src/app/constants';

import {
  IKeyValuePair,
  UserStatus
} from 'src/app/domain/kvp';

import {
  User
} from 'src/app/domain/models';

import {
  UsersRequest,
  UsersResponse
} from 'src/app/domain/requestresponseobjects';

import {
  RegisterDialog
} from 'src/app/components/dialogs';

import {
  IdentityService,
  UtilityService
} from 'src/app/services';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss']
})
export class RoleManagementComponent implements OnDestroy, OnInit {

  private _gridApi: any;

  public columnDefs = [];
  public form: FormGroup;
  //public isDesktop: boolean = false;
  public paginationPageSize: number = 25;
  public users: User[];
  public userStatuses: IKeyValuePair<number>[] = UserStatus.collection;

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private identityService: IdentityService,
    private utilityService: UtilityService) {
      this.form = this.formBuilder.group({
        selectedStatus: 1
      });
    }

  ngOnDestroy() {
    this._gridApi = null;
  }
  ngOnInit() {
    this._setColumnDefs();
  }

  private async _loadUsers(): Promise<void> {
    if (this._gridApi)
      this._gridApi.showLoadingOverlay();

    this.identityService.users$(new UsersRequest().deserialize({
      status: Number(this.f.selectedStatus.value)
    }))
      .subscribe(async (response: UsersResponse) => {
        if (this._gridApi)
          this._gridApi.hideOverlay();
        this.users = response.users;
      });

  }

  private _setColumnDefs(): void {
    this.columnDefs = [
      this.utilityService.gridColumnDef('name', 'Name', null, true, 'cell-link'),
      this.utilityService.gridColumnDef('email', 'Email Address', null)//,
      //this.utilityService.gridColumnDef('role', 'Role', null),
      //this.utilityService.gridColumnDef('', '', null)
    ];
  }

  public async onCellClicked(ev: any): Promise<void> {
    // if (ev.colDef.field === 'name' && ev.value)
    //   this._openRegisterModal(<User>ev.data, 'Edit');
  }
  public onGridReady(ev: any): void {
    this._gridApi = ev.api;

    this._loadUsers();
  }
  public async onPaginationPageSizeChange(): Promise<void> {
    if (this._gridApi)
      this._gridApi.paginationSetPageSize(this.paginationPageSize);
  }

  public paginationNumberFormatter(params: any): string {
    return `[${params.value}]`;
  }

}
