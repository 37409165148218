
<mat-label style="display: block;">{{index + 1}}. {{question.questionText}}</mat-label>

<mat-form-field appearance="fill" class="col-q-check">
    <mat-checkbox #checkNA (change)="onChangeCheckNA($event)" [disabled]="readonly">N/A</mat-checkbox>
    <input matInput type="text" [hidden]="true" />
</mat-form-field>
<mat-form-field appearance="fill" class="col-q-check">
    <mat-checkbox #checkOK (change)="onChangeCheckOK($event)" [disabled]="readonly">OK</mat-checkbox>
    <input matInput type="text" [hidden]="true" />
</mat-form-field>
<mat-form-field appearance="fill" class="col-q-text">
    <mat-label>Problem / Corrective Action / Comment</mat-label>
    <input matInput type="text" #inputComment (input)="onChangeInputComment($event)" [disabled]="readonly"/>
</mat-form-field>

<mat-label *ngIf="displayValidationError && !readonly" style="color: red; display: block; font-size: 75%;">Value is required</mat-label>
<mat-label *ngIf="!displayValidationError && !readonly" style="display: block; font-size: 75%;">&nbsp;</mat-label>
