
<nav>
  <ul>
    <li><a routerLinkActive="active" [routerLink]="['/','dashboard']"><mat-icon>home</mat-icon><span class="link-text">Home</span></a></li>
    <li><a routerLinkActive="active" [routerLink]="['/','productavailability']"><mat-icon>event</mat-icon><span class="link-text">Product Availability</span></a></li>
    <li><a routerLinkActive="active" [routerLink]="['/','orderstatus']"><mat-icon>send</mat-icon><span class="link-text">Order Status</span></a></li>
    <li><a routerLinkActive="active" [routerLink]="['/','orderentry']"><mat-icon>post_add</mat-icon><span class="link-text">Order Entry</span></a></li>
    <li><a routerLinkActive="active" [routerLink]="['/','productregistration']"><mat-icon>inventory_2</mat-icon><span class="link-text">Product Registration</span></a></li>
    <li><a routerLinkActive="active" [routerLink]="['/','warrantymanagement']"><mat-icon>verified</mat-icon><span class="link-text">Warranty Management</span></a></li>
    <li *ngIf="isAdmin"><a routerLinkActive="active" [routerLink]="['/','usermanagement']"><mat-icon>group</mat-icon><span class="link-text">User Management</span></a></li>
    <!-- <li *ngIf="isAdmin"><a routerLinkActive="active" [routerLink]="['/','rolemanagement']"><mat-icon>badge</mat-icon><span class="link-text">Role Management</span></a></li> -->
    <li *ngIf="isAdmin"><a routerLinkActive="active" [routerLink]="['/','pdimanagement']"><mat-icon>rule</mat-icon><span class="link-text">PDI Management</span></a></li>
    <li *ngIf="isAdmin"><a routerLinkActive="active" [routerLink]="['/','admin']"><mat-icon>settings</mat-icon><span class="link-text">System Admin</span></a></li>
  </ul>
</nav>
