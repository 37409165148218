<mat-card>

  <mat-card-title>
    Order Entry
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <hr />
      <h3 class="section-title">Order Details:</h3>

      <section>

        <mat-form-field appearance="fill" class="col-1-1-1">
          <mat-label>Customer</mat-label>
          <input #customerAutoInput *ngIf="!isDealer" matInput placeholder="Type to Search..."
            formControlName="selectedCustomer" [matAutocomplete]="customerAuto" type="text" />
          <mat-autocomplete #customerAuto="matAutocomplete" [displayWith]="displayCustomerWith" autoActiveFirstOption>
            <mat-option disabled>
              <div class="ac-span ac-span-header ac-span-20">CUST #</div>
              <div class="ac-span ac-span-header ac-span-50">NAME</div>
              <div class="ac-span ac-span-header ac-span-30">LOCATION</div>
            </mat-option>
            <mat-option *ngFor="let c of filteredCustomers | async" [value]="c">
              <div class="ac-span ac-span-20">{{c.customer}}</div>
              <div class="ac-span ac-span-50">{{c.name}}</div>
              <div class="ac-span ac-span-30">{{c.line1}} {{c.city}}, {{c.state}}</div>
            </mat-option>
          </mat-autocomplete>
          <mat-select *ngIf="isDealer" formControlName="selectedCustomer">
            <mat-option *ngFor="let c of filteredCustomers | async" [value]="c">{{c.customer}}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.selectedCustomer.invalid">{{getSelectedCustomerError()}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-1-1-1">
          <mat-label>Ship To</mat-label>
          <mat-select formControlName="selectedShipTo" [disabled]="!f.selectedCustomer.value">
            <mat-option value="ADD_NEW">ADD NEW...</mat-option>
            <mat-option *ngFor="let st of shipTos" [value]="st.ship">{{st.name}} - {{st.line1}} ({{st.city}},
              {{st.state}})</mat-option>
          </mat-select>
          <mat-error *ngIf="f.selectedShipTo.invalid">{{getSelectedShipToError()}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-4-2-1">
          <mat-label>PO Number</mat-label>
          <input matInput formControlName="poNumber" type="text" />
          <mat-hint align="start">Letters, numbers, . - _ / are allowed</mat-hint>
          <mat-error *ngIf="f.poNumber.invalid">{{getPoNumberError()}}</mat-error>
          <mat-error *ngIf="f.poNumber?.hasError('pattern')">{{"Invalid characters"}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-4-2-1">
          <mat-label>Channel</mat-label>
          <mat-select formControlName="channel">
            <mat-option *ngFor="let oc of orderChannels" [value]="oc.value">{{oc.key}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="infix-sm col-4-2-1">
          <mat-label>Allow Partial Shipments?</mat-label>
          <input matInput formControlName="partialShipment" type="text" [hidden]="true" />
          <mat-checkbox formControlName="partialShipment">Yes</mat-checkbox>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-4-2-1">
          <mat-label>Ship Via</mat-label>
          <mat-select formControlName="selectedShipVia">
            <mat-option *ngFor="let sv of shipVias" [value]="sv.shipVia">{{sv.description}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-2-2-1">
          <mat-label>Order Date</mat-label>
          <input matInput type="text" id="orderDate" />
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-2-2-1">
          <mat-label>Required Date</mat-label>
          <input matInput type="text" id="requiredDate" />
        </mat-form-field>

        <div class="tr-col-2-2-1 disp-ib">

          <mat-form-field appearance="fill" class="col-1-1-1">
            <mat-label>Owner Contact</mat-label>
            <input matInput type="text" formControlName="ownerContact" />
          </mat-form-field>

          <mat-form-field appearance="fill" class="col-1-1-1">
            <mat-label>Owner Email</mat-label>
            <input matInput type="email" formControlName="ownerEmail" />
            <mat-error *ngIf="f.ownerEmail.invalid">{{getOwnerEmailError()}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="col-1-1-1">
            <mat-label>Owner Phone</mat-label>
            <input matInput type="text" formControlName="ownerPhone" />
          </mat-form-field>

        </div>

        <div class="tr-col-2-2-1 disp-ib">

          <mat-form-field appearance="fill" class="col-1-1-1">
            <mat-label>Comments</mat-label>
            <textarea matInput style="resize: none; height: 141px;" formControlName="comments"></textarea>
          </mat-form-field>

        </div>

      </section>
      <hr />
      <h3 class="section-title">Order Items:</h3>

      <button mat-flat-button (click)="openAddItemModal()" [disabled]="!addItemEnabled()">Add Item</button>
      <span *ngIf="!orderItemsHasError()" class="grid-header-hint">Double-click a <b>Qty Ordered</b> cell to edit</span>
      <span *ngIf="orderItemsHasError() && submitAttempted"
        class="grid-header-hint grid-error">{{getOrderItemsError()}}</span>

      <section>

        <ag-grid-angular (cellValueChanged)="onCellValueChanged($event)" class="ag-theme-alpine"
          [columnDefs]="columnDefs" domLayout="autoHeight" [editType]="'cell'"
          [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)" [rowData]="orderItems"
          style="width: 100%;"></ag-grid-angular>

      </section>

      <div class="content-right">
        <button mat-flat-button (click)="submit()" [disabled]="formSubmitted">PLACE ORDER</button>
      </div>

    </form>
  </mat-card-content>

</mat-card>
