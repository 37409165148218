
<mat-card>

  <mat-card-title>
    Product Availability
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" *ngIf="!isDealer">

      <hr />
      <button mat-flat-button *ngIf="!filtersOpened && !isDesktop" (click)="filtersToggle()">Filters...<mat-icon>keyboard_arrow_down</mat-icon></button>
      <button mat-flat-button *ngIf="filtersOpened && !isDesktop" (click)="filtersToggle()"><mat-icon>keyboard_arrow_up</mat-icon>...Filters</button>
      
      <section [hidden]="!filtersOpened">

        <mat-form-field appearance="fill" class="col-3-2-1">
          <mat-label>Price List</mat-label>
          <mat-select formControlName="selectedPricelist" panelClass="select-panel">
            <mat-option *ngFor="let pricelist of pricelists" [value]="pricelist.pricelist">{{pricelist.pricelist}}</mat-option>
          </mat-select>
        </mat-form-field>

      </section>

      <hr />

    </form>
    
    <div class="content-right">
      <mat-form-field appearance="fill" class="grid-pagecount-selector">
        <mat-label>Records per Page</mat-label>
        <mat-select [(ngModel)]="paginationPageSize" (selectionChange)="onPaginationPageSizeChange()" name="paginationPageSize">
          <mat-option [value]="10">10</mat-option>
          <mat-option [value]="25">25</mat-option>
          <mat-option [value]="50">50</mat-option>
          <mat-option [value]="100">100</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
    <ag-grid-angular
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      domLayout="autoHeight"
      (gridReady)="onGridReady($event)"
      [pagination]="true"
      [paginationNumberFormatter]="paginationNumberFormatter"
      [paginationPageSize]="paginationPageSize"
      [rowData]="items"
      style="width: 100%;"></ag-grid-angular>
    

  </mat-card-content>
  
</mat-card>
