
<mat-card>

  <mat-card-title>
    Role Management
  </mat-card-title>

  <mat-card-content>
    
    <div class="content-right">
      <!-- <button mat-flat-button color="primary" (click)="addNew()">Add New User</button> -->
      <!-- <button mat-flat-button color="primary"  (click)="export()">Export to Excel</button> -->
      <mat-form-field appearance="fill" class="grid-pagecount-selector">
        <mat-label>Records per Page</mat-label>
        <mat-select [(ngModel)]="paginationPageSize" (selectionChange)="onPaginationPageSizeChange()" name="paginationPageSize">
          <mat-option [value]="10">10</mat-option>
          <mat-option [value]="25">25</mat-option>
          <mat-option [value]="50">50</mat-option>
          <mat-option [value]="100">100</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
    <ag-grid-angular
      (cellClicked)="onCellClicked($event)"
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      domLayout="autoHeight"
      (gridReady)="onGridReady($event)"
      [pagination]="true"
      [paginationNumberFormatter]="paginationNumberFormatter"
      [paginationPageSize]="paginationPageSize"
      [rowData]="users"
      style="width: 100%;"></ag-grid-angular>
  </mat-card-content>
  
</mat-card>
