
export const FormValidators = {

  autocomplete: 'Must select an option from the list',
  email: 'Must be a valid email address',
  exactMatch: 'Must exactly match',
  existingAccount: 'Must provide account number(s) if you selected that you have existing Art\'s Way account(s)',
  none: '',
  orderItems: 'Order must contain at least one item',
  phone: 'Must be a valid 10-digit phone number',
  required: 'Value is required',
  password: 'Value is not a valid password*'
}
