
<app-header *ngIf="currentUser" [currentUser]="currentUser" [links]="linksHeader"></app-header>

<div *ngIf="currentUser">
  <mat-checkbox *ngIf="!deviceIsMobile" [(ngModel)]="navLocked" (change)="lockChanged()">Lock Menu</mat-checkbox>
  <button mat-stroked-button *ngIf="!navOpened && !navLocked" (click)="navOpen()">Menu...<mat-icon>chevron_right</mat-icon></button>
  <button mat-stroked-button *ngIf="navOpened && !navLocked" (click)="navClose()"><mat-icon>chevron_left</mat-icon>...Menu</button>
</div>

<mat-sidenav-container>

  <mat-sidenav *ngIf="currentUser" [mode]="navMode" [(opened)]="navOpened">
    <app-navigation [isAdmin]="isAdmin"></app-navigation>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>

<app-footer *ngIf="currentUser" [links]="linksFooter"></app-footer>

<app-loader></app-loader>
