import { Component, Input, OnInit } from '@angular/core';

import { Link } from 'src/app/domain/models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public currYear: number = new Date().getFullYear();
  @Input() links: Link[] = [];
  
  constructor() { }

  ngOnInit() {
  }

}
