import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

import {
  Item,
  Pricelist
} from 'src/app/domain/models';

import {
  ItemsRequest,
  PricelistRequest
} from 'src/app/domain/requestresponseobjects';

import {
  IdentityService,
  OrderService,
  UtilityService
} from 'src/app/services';

@Component({
  selector: 'app-product-availability',
  templateUrl: './product-availability.component.html',
  styleUrls: ['./product-availability.component.scss']
})
export class ProductAvailabilityComponent implements OnDestroy, OnInit {

  private _gridApi: any;
  private _subSelectedPricelist: Subscription;

  public columnDefs = [];
  public filtersOpened: boolean = false;
  public form: FormGroup;
  public isDealer = true;
  public isDesktop: boolean = false;
  public items: Item[] = [];
  public paginationPageSize: number = 25;
  public pricelists: Pricelist[];

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private formBuilder: FormBuilder,
    private identityService: IdentityService,
    private orderService: OrderService,
    private utilityService: UtilityService) {
      this.form = this.formBuilder.group({
        selectedPricelist: this.identityService.getPricelist()
      });
    }

  ngOnDestroy() {
    if (this._subSelectedPricelist)
      this._subSelectedPricelist.unsubscribe();
  }

  ngOnInit() {
    this.filtersOpened = this.isDesktop = this.deviceDetectorService.isDesktop();
    this.isDealer = this.identityService.userIsInRole('Dealer');
    this._setColumnDefs();
  }

  private _loadItems(): void {
    this._gridApi.showLoadingOverlay();

    this.orderService.items$(new ItemsRequest().deserialize({ pricelist: this.f.selectedPricelist.value }))
      .subscribe(response => {
        this._gridApi.hideOverlay();
        this.items = response.items;
      });
  }
  private _loadPricelist(): void {
    this.orderService.pricelists$(new PricelistRequest())
      .subscribe(response => {
        this.pricelists = response.pricelists;
        this._loadItems();
      });
  }
  
  private _setColumnDefs(): void {
    this.columnDefs = [
      this.utilityService.gridColumnDef('part', 'Part', null),
      this.utilityService.gridColumnDef('description', 'Description', null),
      this.utilityService.gridColumnDef('qtyAvailable', 'Quantity Available', null),
      this.utilityService.gridColumnDef('price', 'Price', this.utilityService.gridFormatterCurrency),
      this.utilityService.gridColumnDef('uom', 'UoM', null)
    ];
  }

  public async filtersToggle(): Promise<void> {
    this.filtersOpened = !this.filtersOpened;
  }

  public onGridReady(ev: any): void {
    this._gridApi = ev.api;
        
    if (this.isDealer) {
      this._loadItems();
    }
    else {
      this._loadPricelist();
      this._subSelectedPricelist = this.f.selectedPricelist.valueChanges
        .subscribe((val) => {
          this._loadItems();
        });
    }
  }
  public async onPaginationPageSizeChange(): Promise<void> {
    this._gridApi.paginationSetPageSize(this.paginationPageSize);
  }

  public paginationNumberFormatter(params: any): string {
    return `[${params.value}]`;
  }

}
