import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonRendererComponent } from 'src/app/components/cell-renderers';
import Swal from 'sweetalert2';

import {
  ApiResponses,
  AppAlerts,
  FormValidators
} from 'src/app/constants';

import { UserStatus } from 'src/app/domain/kvp';

import {
  Country,
  Customer,
  Role,
  State,
  User
} from 'src/app/domain/models';

import {
  RegisterRequest
} from 'src/app/domain/requestresponseobjects';

import {
  IdentityService,
  OrderService,
  UtilityService
} from 'src/app/services';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.dialog.html',
  styleUrls: ['./claim.dialog.scss']
})
export class RegisterDialog implements AfterViewInit, OnDestroy, OnInit {

  private _countries: Country[];
  private _customers: Customer[];
  private _gridApi: any;
  private _mode: string;
  private _states: State[];
  private _subCountryAutoTrigger: Subscription;
  private _subCustomerAutoTrigger: Subscription;
  private _subSelectedCustomer: Subscription;
  private _subStateAutoTrigger: Subscription;

  public columnDefs = [];
  public currentStatus: string;
  public displayRegistrationMessage: boolean = false;
  public filteredCountries: Observable<Country[]>;
  public filteredCustomers: Observable<Customer[]>;
  public filteredStates: Observable<State[]>;
  public form: FormGroup;
  public formSubmitted: boolean = false;
  public frameworkComponents: any = { buttonRenderer: ButtonRendererComponent };
  public gridCustomers: Customer[] = [];
  public roles: Role[] = [];
  public statusText: string;
  public statusText2: string;
  public submitText: string;
  public title: string;
  public user: User;

  public get isDealer(): boolean {
    return this.user.roles[0]?.name === 'Dealer';
  }

  public get isNewUser(): boolean {
    return this._mode === 'Register' || this._mode === 'Add';
  }

  public get isRegistration(): boolean {
    return this._mode === 'Register';
  }

  @ViewChild('countryAutoInput', { read: MatAutocompleteTrigger }) countryAutoTrigger: MatAutocompleteTrigger;
  private customerAutoTrigger: MatAutocompleteTrigger;
  @ViewChild('customerAutoInput', { read: MatAutocompleteTrigger }) set elementSetter(content: MatAutocompleteTrigger) {
    if (content) {
      this.customerAutoTrigger = content;
      if (!this._subCustomerAutoTrigger)
        this._subCustomerAutoTrigger = this.customerAutoTrigger.panelClosingActions.subscribe(() => {
          if (this.customerAutoTrigger.activeOption)
            this.f.selectedCustomer.setValue(this.customerAutoTrigger.activeOption.value);
        });
    }
  }
  @ViewChild('stateAutoInput', { read: MatAutocompleteTrigger }) stateAutoTrigger: MatAutocompleteTrigger;

  public get f(): any {
    return this.form.controls;
  }
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<RegisterDialog>,
    private formBuilder: FormBuilder,
    private identityService: IdentityService,
    private orderService: OrderService,
    private utilityService: UtilityService) {
      this.user = <User>this.data.user;
      this._setStatus();
    }

  ngAfterViewInit() {
    this._subCountryAutoTrigger = this.countryAutoTrigger.panelClosingActions.subscribe(() => {
      if (this.countryAutoTrigger.activeOption)
        this.f.country.setValue(this.countryAutoTrigger.activeOption.value);
    });
    this._subStateAutoTrigger = this.stateAutoTrigger.panelClosingActions.subscribe(() => {
      if (this.stateAutoTrigger.activeOption)
        this.f.state.setValue(this.stateAutoTrigger.activeOption.value);
    });
  }
  ngOnDestroy() {
    if (this._subCountryAutoTrigger)
      this._subCountryAutoTrigger.unsubscribe();
    if (this._subCustomerAutoTrigger)
      this._subCustomerAutoTrigger.unsubscribe();
    if (this._subSelectedCustomer)
      this._subSelectedCustomer.unsubscribe();
    if (this._subStateAutoTrigger)
      this._subStateAutoTrigger.unsubscribe();
  }
  ngOnInit() {
    this._setColumnDefs();
    this._setMode();
    this._initializeForm(this.user);
    this._subSelectedCustomer = this.f.selectedCustomer.valueChanges
      .subscribe((val) => {
        if (this.f.selectedCustomer.valid && this.f.selectedCustomer.value) {
          this._gridCustomersAdd(this.f.selectedCustomer.value);
          this.form.patchValue({
            selectedCustomer: null
          });
          this.form.markAsUntouched();
          this.form.markAsPristine();
        }
      });
    this._loadCountries();
    this._loadCustomers();
    this._loadStates();
    this._loadRoles();
    
  }

  public editStatus(action: string): void {
    this.identityService.changeStatus(this.user.id, action)
      .subscribe(result => {
        this.user = result;
        this._setStatus();
      });
  }
  public onGridReady(ev: any): void {
    this._gridApi = ev.api;
  }
  public onRoleChange(ev: any): void {
    const roles = this.roles;
    this.user.roles = roles.filter(r => r.id === ev.value);
    for (const key in this.f)
      this.form.get(key).updateValueAndValidity();
    this._gridCustomersSet();
  }
  public onRowDelete(params: any): void {
    this._gridCustomersRemove(params);
  }
  
  private _autocompleteValidator(control: AbstractControl) {
    return control.value && typeof control.value === 'string'
      ? { autocomplete: true }
      : null;
  }
  private _dealerRequiredObjectValidator(control: FormControl) {
    return !this.isDealer
      ? null
      : control.value && typeof control.value === 'object'
        ? null
        : { required: true };
  }
  private _dealerRequiredStringValidator(control: FormControl) {
    return !this.isDealer
      ? null
      : control.value && typeof control.value === 'string'
        ? null
        : { required: true };
  }
  private _exactMatchValidator(control: FormControl) {
    return this._mode === 'Edit'
      ? null
      : control.value === this.f.password.value
      ? null
      : { exactMatch: true };
  }
  private _passwordValidator(control: FormControl) {
    return this._mode === 'Edit'
      ? null
      : control.value && typeof control.value === 'string'
        ? null
        : { password: true };
  }

  private _filterCountries(match: any) : Country[] {
    // ensure countries
    if (!this._countries)
      return [];

    // ensure string
    match += '';

    // initialize priority array
    let priority1Matches: Country[] = [];
    let priority2Matches: Country[] = [];
    let priority3Matches: Country[] = [];

    // iterate
    for (var i = 0; i < this._countries.length; i++) {
      // priority 1: description startsWith
      if (this._countries[i].description.toLowerCase().startsWith(match.toLowerCase()))
        priority1Matches.push(this._countries[i]);
      // priority 3: country startsWith
      else if (this._countries[i].country.toLowerCase().startsWith(match.toLowerCase()))
        priority3Matches.push(this._countries[i]);
      // priority 2: description contains
      else if (this._countries[i].description.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority2Matches.push(this._countries[i]);
    }

    // concat, return
    return priority1Matches.concat(priority2Matches).concat(priority3Matches);
  }
  private _filterCustomers(match: any) : Customer[] {
    // ensure string
    match += '';

    // ignore until length 3
    if (match.length < 3)
      return [];

    // initialize priority arrays
    let priority1Matches: Customer[] = [];
    let priority2Matches: Customer[] = [];
    let priority3Matches: Customer[] = [];
    let priority4Matches: Customer[] = [];

    // iterate
    for (var i = 0; i < this._customers.length; i++) {
      // priority 1: customer startsWith
      if (this._customers[i].customer.toLowerCase().startsWith(match.toLowerCase()))
        priority1Matches.push(this._customers[i]);
      // priority 2: customer contains
      else if (this._customers[i].customer.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority2Matches.push(this._customers[i]);
      // priority 3: name startsWith
      else if (this._customers[i].name.toLowerCase().startsWith(match.toLowerCase()))
        priority3Matches.push(this._customers[i]);
      // priority 4: name containas
      else if (this._customers[i].name.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority4Matches.push(this._customers[i]);
    }

    // concat, return
    return priority1Matches.concat(priority2Matches).concat(priority3Matches).concat(priority4Matches);
  }
  private _filterStates(match: any) : State[] {
    // ensure states
    if (!this._states)
      return [];

    // ensure string
    match += '';

    // initialize priority array
    let priority1Matches: State[] = [];
    let priority2Matches: State[] = [];
    let priority3Matches: State[] = [];

    // iterate
    for (var i = 0; i < this._states.length; i++) {
      // priority 1: description startsWith
      if (this._states[i].description.toLowerCase().startsWith(match.toLowerCase()))
        priority1Matches.push(this._states[i]);
      // priority 3: state startsWith
      else if (this._states[i].state.toLowerCase().startsWith(match.toLowerCase()))
        priority3Matches.push(this._states[i]);
      // priority 2: description contains
      else if (this._states[i].description.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority2Matches.push(this._states[i]);
    }

    // concat, return
    return priority1Matches.concat(priority2Matches).concat(priority3Matches);
  }

  private _gridCustomersAdd(customer: Customer): void {
    if (!customer || this.gridCustomers.filter(c => c.customer === customer?.customer).length > 0)
      return;
    this.gridCustomers.unshift(customer);
    this._gridApi.applyTransaction({
      add: [ customer ],
      addIndex: 0,
    });
  }
  private _gridCustomersClear(): void {
    this.gridCustomers = [];
  }
  private _gridCustomersRemove(rowNode: any): void {
    this.gridCustomers = this.gridCustomers.filter(c => c.customer !== rowNode.data.customer);
  }
  private _gridCustomersSet(): void {
    this._gridCustomersClear();
    this.user.customers.forEach(c => this._gridCustomersAdd(c));
  }

  private _initializeForm(user: User): void {
    this.form = this.formBuilder.group({
      accountNumbers: user.accountNumbers,
      city: [user.city, this._dealerRequiredStringValidator.bind(this)],
      company: [user.company, this._dealerRequiredStringValidator.bind(this)],
      country: ['USA', Validators.compose([ this._dealerRequiredObjectValidator.bind(this), this._autocompleteValidator.bind(this) ])],
      domain: ['MFG', Validators.required],
      email: [user.email, Validators.compose([Validators.required, Validators.email])],
      existingAccount: true,
      firstName: [user.firstName, Validators.required],
      lastName: [user.lastName, Validators.required],
      line1: [user.line1, this._dealerRequiredStringValidator.bind(this)],
      line2: [user.line2],
      line3: [user.line3],
      password: [null, Validators.compose([ this._passwordValidator.bind(this), Validators.minLength(10), Validators.maxLength(20), Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$') ])],
      phone: [user.phoneNumber, Validators.compose([ Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$') ])],
      role: [null, Validators.required],
      selectedCustomer: [null, this._autocompleteValidator.bind(this)],
      state: [user.state, Validators.compose([ this._dealerRequiredObjectValidator.bind(this), this._autocompleteValidator.bind(this) ])],
      title: [user.title],
      zip: [user.zip, this._dealerRequiredStringValidator.bind(this)]
    });

    this.form.addControl('confirmPassword', new FormControl(null, Validators.compose([ this._passwordValidator.bind(this), this._exactMatchValidator.bind(this) ])));
  }

  private _loadCountries(): void {
    this.filteredCountries = this.f.country.valueChanges
      .pipe(
        map(val => this._filterCountries(val)));

    this.orderService.countries$()
      .subscribe(response => {
        this._countries = response.countries;
        this.f.country.setValue(this._countries.filter(c => c.country === 'USA')[0])
      });
  }
  private _loadCustomers(): void {
    this.filteredCustomers = this.f.selectedCustomer.valueChanges
      .pipe(
        map(val => this._filterCustomers(val)));

    if (this._mode === 'Register')
        return;

    this.identityService.customers$()
      .subscribe(response => {
        this._customers = response.customers;
        if (this.user.roles[0].name === 'Dealer' && this.user.customerIds?.length > 0)
          this._setUserCustomers();
          this._gridCustomersSet();
      });
  }
  private _loadRoles(): void {
    this.identityService.roles$()
      .subscribe(response => {
        this.roles = response;
    
        const assignedRole = this._mode === 'Edit'
          ? this.user.roles[0]
          : this._mode === 'Register'
            ? this.roles.filter(r => r.name === 'Dealer')[0]
            : null;
        if (assignedRole)
          this.form.patchValue({
            role: assignedRole?.id
          });
      });
  }
  private _loadStates(): void {
    this.filteredStates = this.f.state.valueChanges
      .pipe(
        map(val => this._filterStates(val)));

    this.orderService.states$()
      .subscribe(response => {
        this._states = response.states;
    
        const assignedState = this._mode === 'Edit'
          ? this._states.filter(s => s.state === this.user.state)[0]
          : null;
        if (assignedState)
          this.form.patchValue({
            state: assignedState
          });
      });
  }

  private _setColumnDefs(): void {
    this.columnDefs = [{
      // customer
      field: 'customer',
      headerName: 'Customer No',
      filter: false,
      flex: 2
    }, {
      // name
      field: 'name',
      headerName: 'Name',
      filter: false,
      flex: 2
    }, {
      // line1
      field: 'line1',
      headerName: 'Address',
      filter: false,
      flex: 4
    }, {
      // city
      field: 'city',
      headerName: 'City', 
      filter: false,
      flex: 2
    }, {
      // state
      field: 'state',
      headerName: 'State',
      filter: false,
      flex: 1
    }, {
      // delete btn
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.onRowDelete.bind(this),
        icon: 'clear'
      },
      headerName: '',
      filter: false,
      flex: 1
    }];
  }
  private _setMode(): void {
    this._mode = this.data.mode;
    switch (this._mode) {
      case 'Add':
        this.displayRegistrationMessage = false;
        this.submitText = 'Create';
        this.title = 'Add New User';
        break;
      case 'Edit':
        this.displayRegistrationMessage = false;
        this.submitText = 'Save';
        this.title = 'Edit User';
        break;
      case 'Register':
      default:
        this.displayRegistrationMessage = true;
        this.submitText = 'Register';
        this.title = 'New User Registration';
        break;
    }
  }
  private _setStatus(): void {
    this.currentStatus = UserStatus.key(this.user.status);
      switch (this.currentStatus) {
        case 'Active':
          this.statusText = 'Deactivate';
          this.statusText2 = '';
          break;
        case 'Inactive':
          this.statusText = 'Activate';
          this.statusText2 = '';
          break;
        case 'Locked Out':
          this.statusText = 'Unlock';
          this.statusText2 = '';
          break;
        case 'Registered (Pending)':
          this.statusText = 'Approve';
          this.statusText2 = 'Reject';
          break;
      }
  }
  private _setUserCustomers(): void {
    var userCustomers: Customer[] = [];
    this.user.customerIds.split(',').forEach(id => {
      const customers = this._customers.filter(c => c.customer === id);
      if (customers.length > 0)
        customers.forEach(c => {
          userCustomers.push(c);
        })
    });
    this.user.customers = userCustomers;
  }

  private _validateForm(): void {
    Object.keys(this.f).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl)
        control.markAsTouched({ onlySelf: true });
    });
  }

  public async cancel(): Promise<void> {
    this.dialogRef.close();
  }

  public displayCountryWith(country: Country): string | undefined {
    return country
      ? country.description
      : undefined;
  }
  public displayCustomerWith(customer: Customer): string | undefined {
    return customer
      ? `${customer.customer} (${customer.name})`
      : undefined;
  }
  public displayStateWith(state: State): string | undefined {
    return state
      ? state.description
      : undefined;
  }

  public getAccountNumbersError(): string {
    return this.f.accountNumbers.hasError('existingAccount')
      ? FormValidators.existingAccount
      : FormValidators.none;
  }
  public getCityError(): string {
    return this.f.city.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getCompanyError(): string {
    return this.f.company.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getConfirmPasswordError(): string {
    return this.f.confirmPassword.hasError('password')
      ? FormValidators.required
      : this.f.confirmPassword.hasError('exactMatch')
        ? FormValidators.exactMatch
        : FormValidators.none;
  }
  public getCountryError(): string {
    return this.f.country.hasError('autocomplete')
      ? FormValidators.autocomplete
      : this.f.country.hasError('required')
        ? FormValidators.required
        : FormValidators.none;
  }
  public getDomainError(): string {
    return this.f.domain.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getEmailError(): string {
    return this.f.email.hasError('required')
      ? FormValidators.required
      : this.f.email.hasError('email')
        ? FormValidators.email
        : FormValidators.none;
  }
  public getFirstNameError(): string {
    return this.f.firstName.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getLastNameError(): string {
    return this.f.lastName.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getLine1Error(): string {
    return this.f.line1.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getPasswordError(): string {
    return this.f.password.hasError('minlength') || this.f.password.hasError('maxlength') || this.f.password.hasError('pattern')
    ? FormValidators.password
    : this.f.password.hasError('password')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getPhoneError(): string {
    return this.f.phone.hasError('minlength') || this.f.phone.hasError('maxlength') || this.f.phone.hasError('pattern')
      ? FormValidators.phone
      : this.f.phone.hasError('required')
        ? FormValidators.required
        : FormValidators.none;
  }
  public getRoleError(): string {
    return this.f.role.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getStateError(): string {
    return this.f.state.hasError('autocomplete')
      ? FormValidators.autocomplete
      : this.f.state.hasError('required')
        ? FormValidators.required
        : FormValidators.none;
  }
  public getSelectedCustomerError(): string {
    return this.f.state.hasError('autocomplete')
      ? FormValidators.autocomplete
      : this.f.state.hasError('selectedCustomer')
        ? FormValidators.required
        : FormValidators.none;
  }
  public getZipError(): string {
    return this.f.zip.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }

  public async submit(): Promise<void> {
    this._validateForm();

    if (this.formSubmitted || !this.form.valid) 
      return;

    this.utilityService.showLoader();
    this.formSubmitted = true;

    const request = new RegisterRequest().deserialize({
      accountNumbers: this.f.accountNumbers.value,
      city: this.f.city.value,
      company: this.f.company.value,
      confirmPassword: this.f.confirmPassword.value,
      country: (<Country>this.f.country.value)?.country,
      customers: this.gridCustomers,
      domain: this.f.domain.value,
      email: this.f.email.value,
      existingAccount: this.f.existingAccount.value,
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      line1: this.f.line1.value,
      line2: this.f.line2.value,
      line3: this.f.line3.value,
      password: this.f.password.value,
      phone: this.f.phone.value,
      roleId: this.f.role.value,
      state: (<State>this.f.state.value)?.state,
      title: this.f.title.value,
      zip: this.f.zip.value
    });

    if (this.isNewUser)
      this.identityService.register(request)
        .subscribe(response => {
          if (response.status === ApiResponses.SUCCESS) {
            Swal.fire('Success', AppAlerts.Register.success, 'success');
            this.utilityService.dismissLoader();
            this.dialogRef.close(true);
          }
          else
            Swal.fire('Error', AppAlerts.Register.failure, 'error');

          this.utilityService.dismissLoader();
          this.formSubmitted = false;
        });
    else
      this.identityService.update(request)
        .subscribe(response => {
          if (response.status === ApiResponses.SUCCESS) {
            Swal.fire('Success', AppAlerts.Save.success, 'success');
            this.utilityService.dismissLoader();
            this.dialogRef.close(true);
          }
          else
            Swal.fire('Error', AppAlerts.Save.failure, 'error');

          this.utilityService.dismissLoader();
          this.formSubmitted = false;
        });
  }
}
