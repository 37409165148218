
<footer>

  <ul>
    <li *ngFor="let link of links">
      <a target="_blank" [href]="link.href">{{ link.text }}</a>
    </li>
  </ul>

  <span><mat-icon>copyright</mat-icon> Copyright 2020-{{currYear}} Art's Way</span>

</footer>
