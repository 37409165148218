import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ButtonRendererComponent } from 'src/app/components/cell-renderers';

import { environment } from 'src/environments/environment';

import {
  LinkLocation,
  UserStatus
} from 'src/app/domain/kvp';

import {
  Link,
  TextToken
} from 'src/app/domain/models';

import {
  UsersRequest
} from 'src/app/domain/requestresponseobjects';

import {
  ContentService,
  IdentityService,
  SystemService,
  UtilityService
} from 'src/app/services';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  private _gridApi: any;
  private _script: HTMLScriptElement;

  public apiEnv: string;
  public apiUrl: string = this.utilityService.currentEnvironment === 'Development'
    ? environment.apiDevUri
    : environment.apiBaseUri;

  public appHost: string = window.location.host;
  public appEnv: string = this.utilityService.currentEnvironment;

  public columnDefs: any[] = [];
  public emailText: TextToken = new TextToken();
  public frameworkComponents: any = { buttonRenderer: ButtonRendererComponent };
  public homeText: TextToken = new TextToken();
  public links: Link[] = [];
  public phoneText: TextToken = new TextToken();
  public pingStatus: string;
  public qadroot: string;
  public textTokens: TextToken[] = [];
  public userCount: number;

  constructor(
    private contentService: ContentService,
    private identityService: IdentityService,
    private systemService: SystemService,
    private utilityService: UtilityService) { }

  ngOnInit() {
    this._setColumnDefs();
    this._loadLinks();
    this._loadTexts();
    this.loadActiveUserCount();
    this.loadApiEnv();
    this.loadPingStatus();
    this.loadQadRoot();
  }

  private _editorInject(): void {
    this._script = document.createElement('script');
    this._script.type = 'text/javascript';
    this._script.text = 'var quill = new Quill(\'#editor\', { theme: \'snow\' });';
    document.getElementsByTagName('head')[0].appendChild(this._script);
  }

  private _loadTexts(): void{
    this.contentService.texttokens$()
    .subscribe(textTokens => {
      this.homeText = textTokens.filter(tt => tt.name === 'HomePage.MainContentText')[0];
      this.phoneText = textTokens.filter(tt => tt.name === 'CompanyPhoneNumber')[0];
      this.emailText = textTokens.filter(tt => tt.name === 'CompanyEmail')[0];
    });
  }

  private _loadLinks(): void {
    this.contentService.links$()
      .subscribe(links => {
        this.links = links;
      });
  }

  private _setColumnDefs(): void {
    this.columnDefs = [
      {
        // displayOrder
        editable: true,
        field: 'displayOrder',
        headerName: 'Display Order',
        filter: false,
        flex: 1
      }, {
        // location
        editable: true,
        field: 'location',
        headerName: 'Link Location',
        filter: false,
        flex: 1
      }, {
        // text
        editable: true,
        field: 'text',
        headerName: 'Link Text',
        filter: false,
        flex: 2
      }, {
        // href
        editable: true,
        field: 'href',
        headerName: 'Href',
        filter: false,
        flex: 2
      }, {
        // delete btn
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.rowDelete.bind(this),
          icon: 'clear'
        },
        headerName: '',
        filter: false,
        flex: 1
      }
    ];
  }

  private loadActiveUserCount(): void {
    this.identityService.users$(new UsersRequest().deserialize({ status: UserStatus.value('Active') }))
      .subscribe(response => this.userCount = response.users.length);
  }

  private loadApiEnv(): void {
    this.systemService.apiEnv$()
      .subscribe(response => this.apiEnv = response);
  }

  private loadPingStatus(): void {
    this.systemService.ping$()
      .subscribe(response => this.pingStatus = response);
  }

  private loadQadRoot(): void {
    this.systemService.qadroot$()
      .subscribe(response => this.qadroot = response);
  }

  public onCellValueChanged(ev: any) {
    this.contentService.postLink(ev.data)
      .subscribe(l => {
        this._loadLinks();
      });
  }
  public onGridReady(ev: any): void {
    this._gridApi = ev.api;
  }
  public selectedTabChange(ev: any) {
    // if (ev.index === 1 && !this._script)
    //   this._editorInject();
  }


  public postHomeText(): void {
    this.contentService.postTextToken(this.homeText)
      .subscribe(token => {
        this.homeText = token;
        Swal.fire('Success', 'Home Page Text updated successfully', 'success')
          .then(() => { });
      });
  }

  public postPhoneText(): void {
    this.contentService.postTextToken(this.phoneText)
    .subscribe(token => {
      this.phoneText = token;
      Swal.fire('Success', 'Phone Text updated successfully', 'success')
        .then(() => { });
    });
  }

  public postEmailText(): void {
    this.contentService.postTextToken(this.emailText)
    .subscribe(token => {
      this.emailText = token;
      Swal.fire('Success', 'Email Text updated successfully', 'success')
        .then(() => { });
    });
  }

  public rowDelete(params: any): void {
    this.contentService.deleteLink(params.data.id)
      .subscribe(result => {
        this._loadLinks();
      });
  }

}
