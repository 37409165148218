
import { ModuleWithProviders, NgModule } from '@angular/core';

import {
    AjaxService,
    ContentService,
    IdentityService,
    LoggingService,
    OrderService,
    ProductService,
    SystemService,
    UtilityService
} from './';

@NgModule()
export class ServiceModule {
    constructor(
        private ajaxService: AjaxService,
        private contentService: ContentService,
        private identityService: IdentityService,
        private loggingService: LoggingService,
        private orderService: OrderService,
        private productService: ProductService,
        private systemService: SystemService,
        private utilityService: UtilityService) {
            this.loggingService.warn('ServiceModule instantiated.');
        }

    static forRoot(): ModuleWithProviders<ServiceModule> {
        return {
            ngModule: ServiceModule,
            providers: [
                AjaxService,
                ContentService,
                IdentityService,
                LoggingService,
                OrderService,
                ProductService,
                SystemService,
                UtilityService
            ]
        };
    }
}
