
export const ApiResponses = {

  COMMON_ERROR_MESSAGE: 'An error occured. Please try again, or contact customer support if the problem persists.',
  FAILURE: 'FAILURE',
  GUID_EMPTY: '00000000-0000-0000-0000-000000000000',
  INVALID_PASSWORD_RESET_TOKEN: 'INVALID_PASSWORD_RESET_TOKEN',
  PASSWORD_CHANGE_REQUIRED: 'PASSWORD_CHANGE_REQUIRED',
  SUCCESS: 'SUCCESS',
  USER_LOCKED: 'USER_LOCKED',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
}
