import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ValueFormatterParams } from 'ag-grid-community';
import { lodash } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { LocalRoutes } from '../constants';

import { LoggingService } from './logging.service';

@Injectable()
export class UtilityService {

  private _loading: BehaviorSubject<boolean>;
  public loading$: Observable<boolean>;

  constructor(
    private loggingService: LoggingService,
    private router: Router) {
      this._loading = new BehaviorSubject(false);
      this.loading$ = this._loading.asObservable();
      this.loggingService.info(`Detection:\n\tHost '${window.location.host}'\n\tEnvironment '${this.currentEnvironment}'`);

      this.loggingService.warn('UtilityService initialized.');
    }

  public get currentEnvironment(): string {
    return environment.production
      ? 'Production'
      : window.location.host === 'localhost:4200'
        ? 'Development'
        : 'Staging';
  }

  // loading screen
  public dismissLoader(): void {
    console.log('dismiss loader');
    this._loading.next(false);
  }
  public showLoader(): void {
    console.log('show loader');
    this._loading.next(true);
  }

  // ag grid formatters, col def creators
  public cellGridColumnDef(cellRenderer: string, field: string, headerName: string, valueFormatter: any = null, filter: boolean = true, resizable: boolean = false, cellClass: string = null, flex: number = 1) {
    return {cellRenderer: cellRenderer, field: field, headerName: headerName, valueFormatter: valueFormatter, filter: filter, resizable: resizable, sortable: true,  flex: flex, floatingFilter: true,  cellClass: cellClass };
  }
  public gridColumnDef(field: string, headerName: string, valueFormatter: any = null, filter: boolean = true, cellClass: string = null, flex: number = 1) {
    return { field: field, headerName: headerName, valueFormatter: valueFormatter, sortable: true, filter: filter, flex: flex, floatingFilter: true, cellClass: cellClass };
  }
  public gridFormatterBoolean(params: ValueFormatterParams): string {
    return (params.value as boolean)
      ? 'Yes'
      : 'No';
  }
  public gridFormatterCapitalizeAll(params: ValueFormatterParams): string {
    return params.value.toUpperCase();
  }
  public gridFormatterCurrency(params: ValueFormatterParams): string {
    return `$${params.value.toFixed(2)}`;
  }
  public gridFormatterDate(params: ValueFormatterParams): string {
    return params.value
      ? new Date(params.value).toLocaleDateString('en-US')
      : '';
  }
  public gridFormatterPhone(params: ValueFormatterParams): string {
    return params.value;
  }
  
  // object/datatype evals/conversions
  public nts(n: number): string {
    return n + '';
  }
  public objectEquality(value: any, other: any): boolean {
    return lodash.isEqual(value, other);
  }

  // routing
  public redirectToDashboard(): void {
    this.router.navigate([LocalRoutes.dashboard]);
  }
  public redirectToHome(): void {
    this.router.navigate([LocalRoutes.home]);
  }

}
