import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { UtilityService } from 'src/app/services';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnDestroy, OnInit {

  private _loadingSub: Subscription;

  public loading: boolean = false;
  
  constructor(private utilityService: UtilityService) { }

  ngOnDestroy() {
    if (this._loadingSub)
      this._loadingSub.unsubscribe();
  }
  ngOnInit() {
    this._loadingSub = this.utilityService.loading$
      .subscribe(loading => this.loading = loading);
  }

}
