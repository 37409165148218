
<mat-card>

  <mat-card-title>
    Order Status
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">

      <hr />
      <button mat-flat-button *ngIf="!filtersOpened && !isDesktop" (click)="filtersToggle()">Filters...<mat-icon>keyboard_arrow_down</mat-icon></button>
      <button mat-flat-button *ngIf="filtersOpened && !isDesktop" (click)="filtersToggle()"><mat-icon>keyboard_arrow_up</mat-icon>...Filters</button>

      <section [hidden]="!filtersOpened">
  
        <mat-form-field appearance="fill" class="infix-sm col-3-3-1">
          <mat-label>Filter by Date Range?</mat-label>
          <input matInput formControlName="includeDateRange" type="text" [hidden]="true" />
          <mat-checkbox formControlName="includeDateRange">Yes</mat-checkbox>
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-3-3-1">
          <mat-label>Order Date Range Start</mat-label>
          <input matInput [disabled]="!f.includeDateRange" type="text" id="orderStartDate" />
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-3-3-1">
          <mat-label>Order Date Range End</mat-label>
          <input matInput [disabled]="!f.includeDateRange" type="text" id="orderEndDate" />
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-2-1-1">
          <mat-label>Part</mat-label>
          <input #partAutoInput matInput placeholder="Type to Search..." formControlName="selectedPart" [matAutocomplete]="partAuto" type="text" />
          <mat-error *ngIf="f.selectedPart.invalid">{{getSelectedPartError()}}</mat-error>
          <mat-autocomplete #partAuto="matAutocomplete" [displayWith]="displayPartWith" autoActiveFirstOption>
            <mat-option disabled><div class="ac-span ac-span-header ac-span-20">PART #</div><div class="ac-span ac-span-header ac-span-50">DESCRIPTION</div><div class="ac-span ac-span-header ac-span-30">CUST PART #</div></mat-option>
            <mat-option *ngFor="let p of filteredParts | async" [value]="p"><div class="ac-span ac-span-20">{{p.part}}</div><div class="ac-span ac-span-50">{{p.description}}</div><div class="ac-span ac-span-30">{{p.customerItem}}</div></mat-option>
          </mat-autocomplete>
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-2-2-1">
          <mat-label>Order Status</mat-label>
          <mat-select formControlName="status">
            <mat-option [value]="2">Open Orders</mat-option>
            <mat-option [value]="3">Closed Orders</mat-option>
            <mat-option [value]="1">All Orders</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-2-2-1">
          <mat-label>Shipped Status</mat-label>
          <mat-select formControlName="shipped">
            <mat-option [value]="1">All</mat-option>
            <mat-option [value]="2">No Shipments</mat-option>
            <mat-option [value]="3">Partially Shipped</mat-option>
            <mat-option [value]="4">Shipped</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-2-2-1">
          <mat-label>Order Confirmed</mat-label>
          <mat-select formControlName="confirmed">
            <mat-option [value]="0">Any</mat-option>
            <mat-option [value]="1">Yes</mat-option>
            <mat-option [value]="2">No</mat-option>
          </mat-select>
        </mat-form-field>

      </section>

      <hr />

    </form>


    <span class="grid-header-hint lowered"><span class="grid-error">*Note:</span> If you are not able to view SOs and Invoices; please ensure your browser is not blocking PopUps.</span>
    <div class="content-right">
      <mat-form-field appearance="fill" class="grid-pagecount-selector">
        <mat-label>Records per Page</mat-label>
        <mat-select [(ngModel)]="paginationPageSize" (selectionChange)="onPaginationPageSizeChange()" name="paginationPageSize">
          <mat-option [value]="10">10</mat-option>
          <mat-option [value]="25">25</mat-option>
          <mat-option [value]="50">50</mat-option>
          <mat-option [value]="100">100</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
    <ag-grid-angular
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      domLayout="autoHeight"
      [frameworkComponents]="frameworkComponents"
      (gridReady)="onGridReady($event)"
      [pagination]="true"
      [paginationNumberFormatter]="paginationNumberFormatter"
      [paginationPageSize]="paginationPageSize"
      [rowData]="orders"
      style="width: 100%;"
      suppressCellSelection="true"></ag-grid-angular>
  </mat-card-content>
  
</mat-card>
