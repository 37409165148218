import { _deserializable } from '../_deserializable';
import { OrderItem } from '../models';

export class SubmitOrderRequest extends _deserializable {

  public channel: string;
  public comments: string;
  public customer: string;
  public orderDate: Date;
  public ownerContact: string;
  public ownerEmail: string;
  public ownerPhone: string;
  public partial: boolean;
  public po: string;
  public requiredDate: Date;
  public shipTo: string;
  public shipVia: string;
  public submittedBy: string;
  public webUser: string;

  public orderItems: OrderItem[];

}
