import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import {
  IdentityService,
  UtilityService
} from '../services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    
    constructor(
        private identityService: IdentityService,
        private utilityService: UtilityService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const tokenIsValid = this.identityService.validateToken(true);
        const requiredRoles = route.data.requiredRoles;

        // first, enforce a valid token
        if (!tokenIsValid)
            return this.block();
        // enforce required role(s)
        else if (requiredRoles) {
            let requiredRoleExists = false;
            (requiredRoles as Array<string>).forEach(r => {
                const roleExists = this.identityService.userIsInRole(r);
                if (roleExists)
                    requiredRoleExists = true;
            });
            return requiredRoleExists
                ? this.allow()
                : this.block();
        }
        // checks have passed, we can allow
        else 
            return this.allow();
    }

    private allow(): boolean {
        return true;
    }
    private block(): boolean {
        this.utilityService.redirectToHome();
        return false;
    }

}
