import { IKeyValuePair } from './kvp.interface';

export class KeyValuePairCollection<T> {

  public collection: IKeyValuePair<T>[];

  public constructor(c: IKeyValuePair<T>[]) {
    this.collection = c;
  }

  public key(value: T): string {
    let k = null;
    // check for match in collection
    this.collection.forEach(kvp => {
      if (kvp.value === value)
        k = kvp.key;
    });
    return k;
  }
  public value(key: string): T {
    let v = null;
    // check for match in collection
    this.collection.forEach(kvp => {
      if (kvp.key === key)
        v = kvp.value;
    });
    return v;
  }

}
