
<mat-card>

  <mat-card-title>
    Create New Shipping Address
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">

      <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" type="text" />
        <mat-error *ngIf="f.name.invalid">{{getNameError()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-2-2-1">
        <mat-label>Attn</mat-label>
        <input matInput formControlName="attn" type="text" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-2-2-1">
        <mat-label>Phone</mat-label>
        <input matInput formControlName="phone" type="text" />
        <mat-error *ngIf="f.phone.invalid">{{getPhoneError()}}</mat-error>
        <mat-hint>10-digits only, no characters or spaces</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>Address Line 1</mat-label>
        <input matInput formControlName="line1" type="text" />
        <mat-error *ngIf="f.line1.invalid">{{getLine1Error()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>Address Line 2</mat-label>
        <input matInput formControlName="line2" type="text" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>Address Line 3</mat-label>
        <input matInput formControlName="line1" type="text" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4-2-1">
        <mat-label>City</mat-label>
        <input matInput formControlName="city" type="text" />
        <mat-error *ngIf="f.city.invalid">{{getCityError()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4-2-1">
        <mat-label>State/Province</mat-label>
        <input #stateAutoInput matInput placeholder="Select a State/Province..." formControlName="state" [matAutocomplete]="stateAuto" type="text" />
        <mat-error *ngIf="f.state.invalid">{{getStateError()}}</mat-error>
        <mat-autocomplete #stateAuto="matAutocomplete" [displayWith]="displayStateWith" autoActiveFirstOption>
          <mat-option *ngFor="let s of filteredStates | async" [value]="s">{{s.description}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4-2-1">
        <mat-label>Zip/Postal</mat-label>
        <input matInput formControlName="zip" type="text" />
        <mat-error *ngIf="f.zip.invalid">{{getZipError()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4-2-1">
        <mat-label>Country</mat-label>
        <input #countryAutoInput matInput placeholder="Select a Country..." formControlName="country" [matAutocomplete]="countryAuto" type="text" />
        <mat-error *ngIf="f.country.invalid">{{getCountryError()}}</mat-error>
        <mat-autocomplete #countryAuto="matAutocomplete" [displayWith]="displayCountryWith" autoActiveFirstOption>
          <mat-option *ngFor="let c of filteredCountries | async" [value]="c">{{c.description}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div class="content-right">
        <button mat-flat-button (click)="submit()">Add</button>
        <button mat-flat-button (click)="cancel()">Cancel</button>
      </div>
        
    </form>
  </mat-card-content>

</mat-card>
