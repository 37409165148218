import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services';

@Component({
  selector: 'app-warranty-management',
  templateUrl: './warranty-management.component.html',
  styleUrls: ['./warranty-management.component.scss']
})
export class WarrantyManagementComponent implements OnInit {

  public companyEmail: string;
  public companyPhone: string;

  constructor(private contentService: ContentService) {
  }

  ngOnInit() {
    this.contentService.texttokens$()
    .subscribe(tokens => {
      this.companyPhone = tokens.filter(t => t.name === 'CompanyPhoneNumber')[0].text;
      this.companyEmail = tokens.filter(t => t.name === 'CompanyEmail')[0].text;
    });
  }

}
