import { _deserializable } from '../_deserializable';

export class OrderItem extends _deserializable {

  public description: string;
  public dueDate: Date;
  public line: number;
  public part: string;
  public qtyAvailable: number;
  public qtyOrdered: number;
  public unitPrice: number;
  public uom: string;

  public get linePrice(): number {
    return this.unitPrice * this.qtyOrdered;
  }
  
}
