import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DeviceDetectorService } from 'ngx-device-detector';

import Litepicker from 'litepicker';
import { DateTime } from 'litepicker/dist/types/datetime';

import {
  FormValidators
} from 'src/app/constants';

import {
  Item,
  OrderSummary
} from 'src/app/domain/models';

import {
  ItemsRequest,
  OrderSummariesRequest
} from 'src/app/domain/requestresponseobjects';

import { PdfLinkRendererComponent } from 'src/app/components/cell-renderers';

import {
  IdentityService,
  OrderService,
  UtilityService
} from 'src/app/services';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements AfterViewInit, OnDestroy, OnInit {

  private readonly _date: Date = new Date();

  private _datePickerStart: Litepicker;
  private _datePickerEnd: Litepicker;
  private _gridApi: any;
  private _isDealer: boolean = true;
  private _parts: Item[];
  private _subConfirmed: Subscription;
  private _subIncludeDateRange: Subscription;
  private _subPartAutoTrigger: Subscription;
  private _subSelectedPart: Subscription;
  private _subStatus: Subscription;
  private _subShipped: Subscription;

  public columnDefs = [];
  public filteredParts: Observable<Item[]>;
  public filteredOrders: Observable<OrderSummary[]>;
  public filtersOpened: boolean = false;
  public form: FormGroup = new FormGroup({});
  public formSubmitted: boolean = false;
  public frameworkComponents: any = { pdfLinkRendererComponent: PdfLinkRendererComponent };
  public isDealer: boolean = true;
  public isDesktop: boolean = false;
  public endDate: Date = this._date;
  public orders: OrderSummary[] = [];
  public paginationPageSize: number = 25;
  public startDate: Date = new Date(this._date.getFullYear(), this._date.getMonth() - 2, 1);

  @ViewChild('partAutoInput', { read: MatAutocompleteTrigger }) partAutoTrigger: MatAutocompleteTrigger;

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private formBuilder: FormBuilder,
    private identityService: IdentityService,
    private orderService: OrderService,
    private utilityService: UtilityService) {
      this.form = this.formBuilder.group({
        confirmed: 0,
        includeDateRange: true,
        selectedPart: [null, this._autocompleteValidator.bind(this)],
        status: 2,
        shipped: 1
      });
      this._isDealer = this.identityService.userIsInRole('Dealer');
    }

  ngAfterViewInit() {
    this._subPartAutoTrigger = this.partAutoTrigger.panelClosingActions.subscribe(() => {
      if (this.partAutoTrigger.activeOption)
        this.f.selectedPart.setValue(this.partAutoTrigger.activeOption.value);
    });

  }
  ngOnDestroy() {
    if (this._subConfirmed)
      this._subConfirmed.unsubscribe();
    if (this._subIncludeDateRange)
      this._subIncludeDateRange.unsubscribe();
    if (this._subPartAutoTrigger)
      this._subPartAutoTrigger.unsubscribe();
    if (this._subSelectedPart)
      this._subSelectedPart.unsubscribe();
    if (this._subStatus)
      this._subStatus.unsubscribe();
    if (this._subShipped)
      this._subShipped.unsubscribe();
  }
  ngOnInit() {
    this.filtersOpened = this.isDesktop = this.deviceDetectorService.isDesktop();
    this.isDealer = this.identityService.userIsInRole('Dealer');
    this._setColumnDefs();
    this._setDatePickers();

    this._subConfirmed = this.f.confirmed.valueChanges
    .subscribe((val) => {
        this._loadOrders();
    });
    this._subIncludeDateRange = this.f.includeDateRange.valueChanges
     .subscribe((val) => {
         this._loadOrders();
     });
    this._subSelectedPart = this.f.selectedPart.valueChanges
      .subscribe((val) => {
          this._loadOrders();
      });  
    this._subStatus = this.f.status.valueChanges
      .subscribe((val) => {
          this._loadOrders();
      });   
    this._subShipped = this.f.shipped.valueChanges
      .subscribe((val) => {
          this._loadOrders();
      }); 
  }

  private _autocompleteValidator(control: AbstractControl) {
    return control.value && typeof control.value === 'string'
      ? { autocomplete: true }
      : null;
  }

  private _filterItems(match: any) : Item[] {
    // ensure string
    match += '';

    // ignore until length 3
    if (match.length < 3)
      return [];

    // initialize priority arrays
    let priority1Matches: Item[] = [];
    let priority2Matches: Item[] = [];
    let priority3Matches: Item[] = [];
    let priority4Matches: Item[] = [];
    let priority5Matches: Item[] = [];

    // iterate
    for (var i = 0; i < this._parts.length; i++) {
      // priority 1: part startsWith
      if (this._parts[i].part.toLowerCase().startsWith(match.toLowerCase()))
        priority1Matches.push(this._parts[i]);
      // priority 2: part contains
      else if (this._parts[i].part.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority2Matches.push(this._parts[i]);
      // priority 3: customerItem startsWith
      else if (this._parts[i].customerItem.toLowerCase().startsWith(match.toLowerCase()))
        priority3Matches.push(this._parts[i]);
      // priority 4: description startsWith
      else if (this._parts[i].description.toLowerCase().startsWith(match.toLowerCase()))
        priority4Matches.push(this._parts[i]);
      // priority 5: customerItem || description contains
      else if (this._parts[i].customerItem.toLowerCase().indexOf(match.toLowerCase()) > -1 || this._parts[i].description.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority5Matches.push(this._parts[i]);
    }

    // concat, return
    return priority1Matches.concat(priority2Matches).concat(priority3Matches).concat(priority4Matches).concat(priority5Matches);
  }
 
  private _loadItems(): void {
    this.filteredParts = this.f.selectedPart.valueChanges
      .pipe(
        map(val => this._filterItems(val)));

    this.orderService.items$(new ItemsRequest().deserialize({ pricelist: 'awl'}))
      .subscribe(response => {
        this._parts = response.items;
      });
  }
  private _loadOrders(): void {
    this._gridApi.showLoadingOverlay();

    this.orderService.orderSummaries$(new OrderSummariesRequest().deserialize({
      endDate: this.f.includeDateRange.value
        ? this._datePickerEnd.getDate().toJSDate()
        : null,
      part: this.f.selectedPart.valid && this.f.selectedPart.value
        ? this.f.selectedPart.value.part
        : '',
      orderType: Number(this.f.status.value),
      startDate: this.f.includeDateRange.value
        ? this._datePickerStart.getDate().toJSDate()
        : null,
      shipped: Number(this.f.shipped.value),
      confirmed: Number(this.f.confirmed.value)}))
          .subscribe(response => {
            this._gridApi.hideOverlay();
            this.orders = response.orderSummaries;
          });
  }

  private _setColumnDefs(): void {
    if (this._isDealer)
      this.columnDefs = [
        this.utilityService.gridColumnDef('po', 'PO #', this.utilityService.gridFormatterCapitalizeAll),
        this.utilityService.cellGridColumnDef('pdfLinkRendererComponent', 'so', 'SO #', this.utilityService.gridFormatterCapitalizeAll, true),
        this.utilityService.cellGridColumnDef('pdfLinkRendererComponent', 'invoice', 'Invoice(s)', this.utilityService.gridFormatterCapitalizeAll, true, true),
        this.utilityService.gridColumnDef('invDate', 'Invoice Date', this.utilityService.gridFormatterDate, false),
        this.utilityService.gridColumnDef('shipTo', 'Ship To', this.utilityService.gridFormatterCapitalizeAll, true),
        this.utilityService.gridColumnDef('confirmed', 'Confirmed', this.utilityService.gridFormatterBoolean, false),
        this.utilityService.gridColumnDef('status', 'Ship Status', null, false)
      ];
    else
      this.columnDefs = [
        this.utilityService.gridColumnDef('po', 'PO #', this.utilityService.gridFormatterCapitalizeAll),
        this.utilityService.cellGridColumnDef('pdfLinkRendererComponent', 'so', 'SO #', this.utilityService.gridFormatterCapitalizeAll, true),
        this.utilityService.cellGridColumnDef('pdfLinkRendererComponent', 'invoice', 'Invoice(s)', this.utilityService.gridFormatterCapitalizeAll, true, true),
        this.utilityService.gridColumnDef('invDate', 'Invoice Date', this.utilityService.gridFormatterDate, false),
        this.utilityService.gridColumnDef('customerName', 'Customer', null, true, null, 2),
        this.utilityService.gridColumnDef('customer', 'Customer #', this.utilityService.gridFormatterCapitalizeAll),
        this.utilityService.gridColumnDef('shipTo', 'Ship To', this.utilityService.gridFormatterCapitalizeAll, true),
        this.utilityService.gridColumnDef('confirmed', 'Confirmed', this.utilityService.gridFormatterBoolean, false),
        this.utilityService.gridColumnDef('status', 'Ship Status', null, false)
      ];
  }
  private _setDatePickers(): void {
    this._datePickerStart = new Litepicker({
      dropdowns: {
        maxYear: null,
        minYear: 1990,
        months: true,
        years: true
      },
      element: document.getElementById('orderStartDate'),
      format: 'MM/DD/YYYY',
      maxDate: new Date(),
      setup: (picker) => {
        picker.on('show', () => {
          if (!this.f.includeDateRange.value)
            picker.hide();
        }),
        picker.on('selected', (d: DateTime) => {
          if (d.isAfter(this._datePickerEnd.getDate()))
            picker.setDate(this._datePickerEnd.getDate());
          this._loadOrders();
        })
      },
      singleMode: true,
      startDate: this.startDate
    });
    this._datePickerEnd = new Litepicker({
      dropdowns: {
        maxYear: null,
        minYear: 1990,
        months: true,
        years: true
      },
      element: document.getElementById('orderEndDate'),
      format: 'MM/DD/YYYY',
      maxDate: new Date(),
      setup: (picker) => {
        picker.on('show', () => {
          if (!this.f.includeDateRange.value)
            picker.hide();
        }),
        picker.on('selected', (d: DateTime) => {
          if (d.isBefore(this._datePickerStart.getDate()))
            picker.setDate(this._datePickerStart.getDate());
          this._loadOrders();
        })
      },
      singleMode: true,
      startDate: this.endDate
    });
  }

  public displayPartWith(part: Item): string | undefined {
    return part
      ? `${part.part} (${part.description})`
      : undefined;
  }

  public async filtersToggle(): Promise<void> {
    this.filtersOpened = !this.filtersOpened;
  }

  public getSelectedPartError(): string {
    return this.f.selectedPart.hasError('autocomplete')
      ? FormValidators.autocomplete
      : FormValidators.none;
  }

  public onGridReady(ev: any): void {
    this._gridApi = ev.api;

    this._loadItems();
    this._loadOrders();
  }
  public async onPaginationPageSizeChange(): Promise<void> {
    this._gridApi.paginationSetPageSize(this.paginationPageSize);
  }

  public paginationNumberFormatter(params: any): string {
    return `[${Number(params.value).toLocaleString('en-US')}]`;
  }

}
