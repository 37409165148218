import { Component, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';

import {
  Link,
  User
} from 'src/app/domain/models';

import {
  ResetPasswordDialog
} from 'src/app/components/dialogs';

import {
  IdentityService
} from 'src/app/services'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() currentUser: User;
  @Input() links: Link[] = [];

  public isMobile: boolean = false;
  public showLinkMenu: boolean = false;
  public showUserMenu: boolean = false;

  constructor(
    private dialog: MatDialog,
    private deviceDetectorService: DeviceDetectorService,
    private identityService: IdentityService) {
      this.isMobile = this.deviceDetectorService.isMobile();
      this.showLinkMenu = this.showUserMenu = !this.isMobile;
    }

  @HostListener('document:click', ['$event'])
  public documentClick($ev: MouseEvent) {
    if (!this.isMobile)
      return;

    let shouldHide: boolean = true;
    ($ev.target as Element).classList.forEach(c => {
      if (c === 'toggle')
        shouldHide = false;
    })
    
    if (shouldHide)
      this.showLinkMenu = this.showUserMenu = false;
  }

  public toggleLinkMenu(): void {
    this.showUserMenu = false;
    this.showLinkMenu = !this.showLinkMenu;
  }

  public toggleUserMenu(): void {
    this.showLinkMenu = false;
    this.showUserMenu = !this.showUserMenu;
  }

  public async openResetPasswordModel(): Promise<void>{
      this.identityService.getPasswordResetToken()
      .subscribe(token => {
        this.openPasswordDialog(token);
      });
  }

  public async openPasswordDialog(token: string)
  {
    this.dialog.open(
      ResetPasswordDialog,
      {
        data: {
          token: token
        }
      });
  }
}
