<mat-card>
    <mat-card-title>
        {{ title }}
    </mat-card-title>

    <mat-card-content>
        <form [formGroup]="form">

            <mat-form-field appearance="fill" class="col-3-3-1">
                <mat-label>Full Name:</mat-label>
                <input matInput formControlName="name" type="text" />
                <mat-error *ngIf="f.name.invalid">{{getNameError()}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-3-3-1">
                <mat-label>Email:</mat-label>
                <input matInput formControlName="email" type="text" />
                <mat-error *ngIf="f.email.invalid">{{getEmailError()}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-3-3-1">
                <mat-label>Phone:</mat-label>
                <input matInput formControlName="phone" type="text" />
                <mat-error *ngIf="f.phone.invalid">{{getPhoneError()}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-2-2-1">
                <mat-label>Address:</mat-label>
                <input matInput formControlName="address" type="text" />
                <mat-error *ngIf="f.address.invalid">{{getAddressError()}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-2-2-1">
                <mat-label>Address 2:</mat-label>
                <input matInput formControlName="address2" type="text" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-4-2-1">
                <mat-label>City:</mat-label>
                <input matInput formControlName="city" type="text" />
                <mat-error *ngIf="f.city.invalid">{{getCityError()}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-4-2-1">
                <mat-label>State/Province:</mat-label>
                <input #stateAutoInput matInput placeholder="Select a State/Province..." formControlName="state"
                    [matAutocomplete]="stateAuto" type="text" autocomplete="no-autocomplete-thanks" />
                <mat-error *ngIf="f.state.invalid">{{getStateError()}}</mat-error>
                <mat-autocomplete #stateAuto="matAutocomplete" [displayWith]="displayStateWith" autoActiveFirstOption>
                    <mat-option *ngFor="let s of filteredStates | async" [value]="s">{{s.description}}</mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-4-2-1">
                <mat-label>Zip/Postal:</mat-label>
                <input matInput formControlName="zip" type="text" />
                <mat-error *ngIf="f.zip.invalid">{{getZipError()}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-4-2-1">
                <mat-label>Country:</mat-label>
                <input #countryAutoInput matInput placeholder="Select a Country..." formControlName="country"
                    [matAutocomplete]="countryAuto" type="text" autocomplete="no-autocomplete-thanks" />
                <mat-error *ngIf="f.country.invalid">{{getCountryError()}}</mat-error>
                <mat-autocomplete #countryAuto="matAutocomplete" [displayWith]="displayCountryWith"
                    autoActiveFirstOption>
                    <mat-option *ngFor="let c of filteredCountries | async" [value]="c">{{c.description}}</mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <!--customer (for admin only)-->

            <div class="content-right">
                <button mat-flat-button color="primary" (click)="cancel()">Cancel</button>
                <button mat-flat-button color="primary" (click)="submit()">{{ submitText }}</button>
            </div>

        </form>
    </mat-card-content>
</mat-card>