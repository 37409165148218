import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ApiResponses,
    ApiRoutes
} from 'src/app/constants';

import {
    Link,
    TextToken
} from 'src/app/domain/models';

import { 
    AjaxService,
    LoggingService
} from '.';

@Injectable()
export class ContentService {

    constructor(
        private ajaxService: AjaxService,
        private loggingService: LoggingService) {
        this.loggingService.warn('ContentService initialized.')
    }

    public links$(): Observable<Link[]> {
        this.loggingService.info('Fetching links...');

        return this.ajaxService
        .GET<Link[]>(
            ApiRoutes.links,
            [])
        .pipe(
            map(response => {
            this.loggingService.warn('LinkResponse');
            this.loggingService.warn(response);
            this.loggingService.info('link fetch successful.');

            // return
            return response;
            }));
    }
    public texttokens$(): Observable<TextToken[]> {
        this.loggingService.info('Fetching texttokens...');

        return this.ajaxService
        .GET<TextToken[]>(
            ApiRoutes.texttokens,
            [])
        .pipe(
            map(response => {
            this.loggingService.warn('TextTokenResponse');
            this.loggingService.warn(response);
            this.loggingService.info('texttoken fetch successful.');

            // return
            return response;
            }));
    }

    public deleteLink(id: number): Observable<string> {
        return this.ajaxService
        .DELETE<string>(
            `${ApiRoutes.links}/${id}`,
            ApiResponses.FAILURE);
    }
    public deleteTextToken(id: number): Observable<string> {
        return this.ajaxService
        .DELETE<string>(
            `${ApiRoutes.texttokens}/${id}`,
            ApiResponses.FAILURE);
    }

    public postLink(link: Link): Observable<Link> {
        return this.ajaxService
            .POST<Link>(
                ApiRoutes.links,
                link,
                link);
    }
    public postTextToken(token: TextToken): Observable<TextToken> {
        return this.ajaxService
            .POST<TextToken>(
                ApiRoutes.texttokens,
                token,
                token);
    }

}
