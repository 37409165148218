import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IdentityService, LoggingService } from '../services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private identityService: IdentityService, private loggingService: LoggingService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loggingService.info('JwtInterceptor injected into AJAX request...');
        // add authorization header with jwt token if available
        let userToken = this.identityService.userToken;
        if (userToken) {
            let bearerToken = `Bearer ${userToken}`;
            this.loggingService.info('User found; injecting Bearer Token.');
            request = request.clone({headers: request.headers.set('Authorization', bearerToken)});
        }
        else
            this.loggingService.info('No user found; skipping Bearer Token injection.');

        return next.handle(request);
    }

}
