import { _deserializable } from '../_deserializable';

export class Owner extends _deserializable {
    public ownerId: string;
    public name: string;
    public email: string;
    public phone: string;
    public address: string;
    public address2: string;
    public city: string;
    public state: string;
    public zip: string;
    public country: string;
    public customerId: string;
}