import { _deserializable } from '../_deserializable';
import { Customer } from '../models';

export class RegisterRequest extends _deserializable {

  public accountLocked: boolean;
  public accountNumbers: string;
  public city: string;
  public company: string;
  public confirmPassword: string;
  public country: string;
  public customers: Customer[] = [];
  public domain: string;
  public email: string;
  public existingAccount: boolean;
  public firstName: string;
  public lastName: string;
  public line1: string;
  public line2: string;
  public line3: string;
  public password: string;
  public phone: string;
  public roleId: string;
  public salesRepId: string;
  public state: string;
  public title: string;
  public zip: string;

}
