
<mat-card>

  <mat-card-title>
    Request Password Reset
  </mat-card-title>

  <mat-card-content>
    <div class="aw-message">
      <div>Please enter your email address.</div>
      <div>You will receive an email with instructions on resetting your password.</div>
    </div>
    
    <form [formGroup]="form">

      <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>Email Address:</mat-label>
        <input matInput formControlName="email" type="email" />
        <mat-error *ngIf="f.email.invalid">{{getEmailError()}}</mat-error>
      </mat-form-field>
    
      <div class="content-right">
        <button mat-flat-button color="primary" *ngIf="!responseSuccess" (click)="submit()">Submit</button>
      </div>

    </form>
    
    <span class="aw-underlink">
      <a (click)="cancel()">Cancel</a>
    </span>
    
  </mat-card-content>
  
</mat-card>
