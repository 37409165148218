// framework
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
// 3rd party components
import { AgGridModule } from 'ag-grid-angular';
// modules
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
// http interceptors
import { JwtInterceptor } from './http-interceptors/jwt.interceptor';
// services
import { ServiceModule } from './services/service.module';
// validators
//import { conditionallyRequired } from './validators';
// app component
import { AppComponent } from './app.component';
// cell-renderer components
import {
  ButtonRendererComponent, PdfLinkRendererComponent
} from './components/cell-renderers';
// dialog components
import {
  AddItemToOrderDialog,
  CreateShiptoDialog,
  OwnerDialog,
  PdiDialog,
  ProductDialog,
  RegisterDialog,
  ResetPasswordDialog,
  SendResetDialog
} from './components/dialogs';
// inline components
import {
  FooterComponent,
  HeaderComponent,
  LoaderComponent,
  NavigationComponent,
  PdiQuestionUiComponent
} from './components/inline';
// page components
import {
  AdminComponent,
  DashboardComponent,
  HomeComponent,
  OrderEntryComponent,
  OrderStatusComponent,
  PdiManagementComponent,
  ProductAvailabilityComponent,
  ProductRegistrationComponent,
  RoleManagementComponent,
  SignOutComponent,
  UserManagementComponent,
  WarrantyManagementComponent
} from './components/pages';

@NgModule({
  declarations: [
    AppComponent,

    // cell-renderers
    ButtonRendererComponent,
    PdfLinkRendererComponent,

    // dialogs
    AddItemToOrderDialog,
    CreateShiptoDialog,
    OwnerDialog,
    PdiDialog,
    ProductDialog,
    RegisterDialog,
    ResetPasswordDialog,
    SendResetDialog,

    // inline
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    NavigationComponent,
    PdiQuestionUiComponent,
    
    // pages
    AdminComponent,
    DashboardComponent,
    HomeComponent,
    OrderEntryComponent,
    OrderStatusComponent,
    PdiManagementComponent,
    ProductAvailabilityComponent,
    ProductRegistrationComponent,
    RoleManagementComponent,
    SignOutComponent,
    UserManagementComponent,
    WarrantyManagementComponent,

    // validators
    //conditionallyRequired
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,

    ServiceModule.forRoot(),

    AgGridModule.withComponents([])
  ],
  providers: [
    // interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
