import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import {
    Customer,
    EligibleItem,
    Owner,
    PdiAnswer,
    PdiForm,
    PdiHeader,
    ProductRegistration,
} from 'src/app/domain/models';

import {
    EligibleItemsRequest,
    RegisterProductRequest,
} from 'src/app/domain/requestresponseobjects';

import {
    ApiResponses,
    AppAlerts,
    FormValidators
} from 'src/app/constants';

import {
    IdentityService,
    LoggingService,
    ProductService,
    UtilityService
} from 'src/app/services';

import { OwnerDialog } from 'src/app/components/dialogs'

import { PdiQuestionUiComponent } from 'src/app/components/inline';

@Component({
    selector: 'app-product',
    templateUrl: './product.dialog.html',
    styleUrls: ['./product.dialog.scss']
})

export class ProductDialog implements AfterViewInit, OnDestroy, OnInit {

    private _customers: Customer[];
    private _firstSubmit: boolean = false;
    private _formIsSubmitted: boolean = false;
    private _formIsValid: boolean = false;
    private _mode: string;
    private _ownerDialogOpen: boolean = false;
    private _owners: Owner[];
    private _parts: EligibleItem[];
    private _pdiHeaders: PdiHeader[];
    private _subCustomerAutoTrigger: Subscription;
    private _subOwnerAutoTrigger: Subscription;
    private _subSelectedCustomer: Subscription;
    private _subSelectedOwner: Subscription;
    private _subSelectedPart: Subscription;
    private _subPartAutoTrigger: Subscription;

    public cancelText: string;
    public filteredCustomers: Observable<Customer[]>;
    public filteredOwners: Observable<Owner[]>;
    public filteredParts: Observable<string[]>;
    public form: FormGroup;
    public isDealer: boolean = this.identityService.userIsInRole('Dealer');
    public title: string;
    public product: ProductRegistration;
    public selectedPdiHeader: PdiHeader;

    @ViewChild('customerAutoInput', { read: MatAutocompleteTrigger })
    customerAutoTrigger: MatAutocompleteTrigger;
    @ViewChild('ownerAutoInput', { read: MatAutocompleteTrigger })
    ownerAutoTrigger: MatAutocompleteTrigger;
    @ViewChild('partAutoInput', { read: MatAutocompleteTrigger })
    partAutoTrigger: MatAutocompleteTrigger;
    @ViewChildren(PdiQuestionUiComponent)
    pdiQuestions: QueryList<PdiQuestionUiComponent>;

    public get displayPdiForm(): boolean {
        return (this.selectedPdiHeader)
            ? true
            : false;
    }
    public get displaySubmit(): boolean {
        return (this.f.selectedPart.valid)
            ? true
            : false;
    }
    public get f(): any {
        return this.form.controls;
    }
    public get hideSubmitError(): boolean {
        this._validateForm(false);
        return this._formIsValid || !this._firstSubmit;
    }
    public get noPdiFormText(): string {
        return (this._mode === 'Detail')
            ? 'Unavailable for this record'
            : (this.f.selectedPart.valid)
                ? 'No PDI Form for this Part'
                : 'No Part has been selected';
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ProductDialog>,
        private formBuilder: FormBuilder,
        private identityService: IdentityService,
        private loggingService: LoggingService,
        private productService: ProductService,
        private utilityService: UtilityService) {
        this.product = <ProductRegistration>this.data.product;
    }

    ngAfterViewInit() {
        if (this._mode === 'Create') {
            this._registerAutoCompleteTriggers();
            this._loadCustomers();
            this._loadPdiHeaders();
        }
    }
    ngOnDestroy() {
        if (this._subCustomerAutoTrigger)
            this._subCustomerAutoTrigger.unsubscribe();
        if (this._subOwnerAutoTrigger)
            this._subOwnerAutoTrigger.unsubscribe();
        if (this._subPartAutoTrigger)
            this._subPartAutoTrigger.unsubscribe();

        if (this._subSelectedCustomer)
            this._subSelectedCustomer.unsubscribe();
        if (this._subSelectedPart)
            this._subSelectedPart.unsubscribe();
        if (this._subSelectedOwner)
            this._subSelectedOwner.unsubscribe();
    }
    ngOnInit() {
        this._setMode();
        this._initializeForm(this.product);

        if (this._mode === 'Create')
            this._registerAutocompleteSubscribers();
    }

    public displayCustomerWith(customer: Customer): string | undefined {
        return customer
            ? `${customer.customer} (${customer.name})`
            : undefined;
    }
    public displayOwnerWith(owner: Owner): string | undefined {
        return (owner)
            ? (owner.ownerId === undefined)
                ? 'Adding New Owner...'
                : `${owner.ownerId} (${owner.name})`
            : 'Please select or add an owner';
    }
    public displayPartWith(part: EligibleItem): string | undefined {
        return part
            ? `${part.part} (${part.description}) '${part.serial}'`
            : undefined;
    }

    private _initializeForm(product: ProductRegistration): void {
        if (this._mode === 'Create')
            this.form = this.formBuilder.group({
                certifyComplete: [null],
                comments: [null, Validators.maxLength(280)],
                deliveryDate: [null, Validators.required],
                howHeard: [null, Validators.required],
                inspectionDate: [null, this._conditionallyRequired.bind(this)],
                model: [{ value: null, disabled: true }, Validators.required],
                personCompleting: [null, this._conditionallyRequired.bind(this)],
                productLine: [{ value: null, disabled: true }, Validators.required],
                salesPerson: [null],
                selectedCustomer: [null, Validators.compose([Validators.required, this._autocompleteValidator.bind(this)])],
                selectedOwner: [{ value: null, disabled: true }, Validators.compose([Validators.required, this._autocompleteValidator.bind(this)])],
                selectedPart: [{ value: null, disabled: true }, Validators.compose([Validators.required, this._autocompleteValidator.bind(this)])],
                serialNumber: [{ value: null, disabled: true }, Validators.required],
                title: this.title,
                warrantyCode: [{ value: null, disabled: true }, Validators.maxLength(10)]
            });
        else
            this._fixForm();
    }
    private _fixForm(): void {
        this.loggingService.warn('productregistration detail:');
        this.loggingService.warn(this.product);

        var customer = new Customer().deserialize({
            customer: this.product.customerNumber,
            name: this.product.customerName
        });
        var owner = new Owner().deserialize({
            ownerId: this.product.ownerNumber,
            name: this.product.ownerName
        });
        var part = new EligibleItem().deserialize({
            part: this.product.part,
            description: this.product.description,
            serial: this.product.serialNumber
        });

        var splitComments = this.product.comments
            .replace('SALES PERSON:', '')
            .replace('SALESPERSON:', '')
            .replace('COMMENTS:', '||')
            .replace('Comments:', '||')
            .replace('HOW HEARD:', '||')
            .replace('How Did:', '||')
            .split('||');
        var salesPerson = splitComments.length === 3
            ? splitComments[0].trim()
            : '-none-';
        var comments = splitComments.length === 3
            ? splitComments[1].trim()
            : '-none-';
        var howHeard = splitComments.length === 3
            ? splitComments[2].trim()
            : '-none-';

        this.form = this.formBuilder.group({
            certifyComplete: [{ value: true, disabled: true }],
            comments: [{ value: comments, disabled: true }],
            deliveryDate: [{ value: this.product.deliveryDate.toString().split('T')[0], disabled: true }],
            howHeard: [{ value: howHeard, disabled: true }],
            inspectionDate: [{ value: null, disabled: true }],
            model: [{ value: this.product.model, disabled: true }],
            personCompleting: [{ value: 'Unavailable for this record', disabled: true }],
            productLine: [{ value: this.product.productLine, disabled: true }],
            salesPerson: [{ value: salesPerson, disabled: true }],
            selectedCustomer: [{ value: customer, disabled: true }],
            selectedOwner: [{ value: owner, disabled: true }],
            selectedPart: [{ value: part, disabled: true }],
            serialNumber: [{ value: this.product.serialNumber, disabled: true }],
            title: this.title,
            warrantyCode: [{ value: this.product.warrantyCode, disabled: true }]
        });

        setTimeout(() => {
            this.utilityService.showLoader();
        }, 100);
        

        this.productService.pdiForm$(this.product.serialNumber)
            .subscribe(pdiForm => {
                if (pdiForm) {
                    // header
                    this.selectedPdiHeader = pdiForm.header;
                    // fields
                    this.form.get('inspectionDate').setValue(pdiForm.inspectionDate.toString().split('T')[0]);
                    this.form.get('personCompleting').setValue(pdiForm.personCompleting ? pdiForm.personCompleting : 'Unavailable for this record');
                    // questions/answers
                    setTimeout(() => {
                        this.pdiQuestions.forEach((questionUiComponent: PdiQuestionUiComponent) => {
                            var answer = pdiForm.answers
                                .filter(a => a.pdiQuestionId == questionUiComponent.questionId)[0];
                            if (answer)
                                questionUiComponent.setReadonlyValue(answer.answerText);
                        });

                    }, 100);
                }

                this.utilityService.dismissLoader();
            });
    }
    private _registerAutocompleteSubscribers(): void {
        this._subSelectedCustomer = this.f.selectedCustomer.valueChanges
            .subscribe((val) => {
                if (this.f.selectedCustomer.valid) {
                    this.form.get('selectedOwner').enable();
                    this.form.get('selectedPart').enable();
                    this._loadOwners(val.customer, null);
                    this._loadParts(val.customer);
                }
                else {
                    this._owners = [];
                    this.filteredOwners = of(this._owners);
                    this.form.get('selectedOwner').disable();
                    this._parts = [];
                    this.form.get('selectedPart').disable();
                }
            });
        
        this._subSelectedOwner = this.f.selectedOwner.valueChanges
            .subscribe((val) => {
                if (val === 'ADD_NEW')
                    this.addOwner();
            });

        this._subSelectedPart = this.f.selectedPart.valueChanges
            .subscribe((val: EligibleItem) => {
                if (this.f.selectedPart.valid && val != null) {
                    this.form.get('model').setValue(val.model);
                    this.form.get('productLine').setValue(val.productLine);
                    this.form.get('serialNumber').setValue(val.serial);
                    this.form.get('warrantyCode').setValue(val.warranty);
                    this._setPdiHeader(val.productLine);
                }
                else {
                    this.form.get('model').setValue('');
                    this.form.get('productLine').setValue('');
                    this.form.get('serialNumber').setValue('');
                    this.form.get('warrantyCode').setValue('');
                    this._setPdiHeader(null);
                }
            });
    }
    private _registerAutoCompleteTriggers(): void {
        if (this.customerAutoTrigger)
            this._subCustomerAutoTrigger = this.customerAutoTrigger.panelClosingActions.subscribe(() => {
                if (this.customerAutoTrigger.activeOption)
                    this.f.selectedCustomer.setValue(this.customerAutoTrigger.activeOption.value);
            });

        this._subOwnerAutoTrigger = this.ownerAutoTrigger.panelClosingActions.subscribe(() => {
            if (this.ownerAutoTrigger.activeOption)
                this.f.selectedOwner.setValue(this.ownerAutoTrigger.activeOption.value);
        });

        this._subPartAutoTrigger = this.partAutoTrigger.panelClosingActions.subscribe(() => {
            if (this.partAutoTrigger.activeOption)
                this.f.selectedPart.setValue(this.partAutoTrigger.activeOption.value);
        });
    }

    private _autocompleteValidator(control: AbstractControl) {
        return control.value && typeof control.value === 'string'
            ? { autocomplete: true }
            : null;
    }
    private _conditionallyRequired(control: AbstractControl): { [key: string]: boolean } | null {
        return (this.displayPdiForm && !control.value)
            ? { 'required': true }
            : null;
    }
    public get certifyCompleteError(): boolean {
        return (this.displayPdiForm && !this.f.certifyComplete.value && this.f.certifyComplete.touched)
            ? true
            : false;
    }

    private _defaultCustomer(): Customer {
        if (this._customers.length === 0)
            return null;

        return this._customers[0];
    }
    
    private _filterCustomers(match: any): Customer[] {
        // ensure string
        if (match === null)
            match = '';

        // ignore until length 1
        if (match.length < 1)
            return this._customers;

        if (typeof match === 'object')
            return [];

        // initialize priority arrays
        let priority1Matches: Customer[] = [];
        let priority2Matches: Customer[] = [];
        let priority3Matches: Customer[] = [];
        let priority4Matches: Customer[] = [];

        // iterate
        if (this._customers && this._customers.length)
            for (var i = 0; i < this._customers.length; i++) {
                // priority 1: customer startsWith
                if (this._customers[i].customer.toLowerCase().startsWith(match.toLowerCase()))
                    priority1Matches.push(this._customers[i]);
                // priority 2: customer contains
                else if (this._customers[i].customer.toLowerCase().indexOf(match.toLowerCase()) > -1)
                    priority2Matches.push(this._customers[i]);
                // priority 3: name startsWith
                else if (this._customers[i].name.toLowerCase().startsWith(match.toLowerCase()))
                    priority3Matches.push(this._customers[i]);
                // priority 4: name containas
                else if (this._customers[i].name.toLowerCase().indexOf(match.toLowerCase()) > -1)
                    priority4Matches.push(this._customers[i]);
            }

        // concat, return
        return priority1Matches.concat(priority2Matches).concat(priority3Matches).concat(priority4Matches);
    }
    private _filterOwners(match: any): Owner[] {
        // prep return
        let retval: Owner[] = [];

        // ensure string
        if (match === null)
            match = '';

        if (match.length < 1 || match === 'ADD_NEW') {
            // return full set if no input
            retval = this._owners;
        }
        else if (typeof match === 'object') {
            retval = [];
        }
        else {
            // initialize priority arrays
            let priority1Matches: Owner[] = [];
            let priority2Matches: Owner[] = [];
            let priority3Matches: Owner[] = [];
            let priority4Matches: Owner[] = [];
    
            // iterate
            for (var i = 0; i < this._owners.length; i++) {
                // continue if no value
                if (!this._owners[i])
                    continue;
                // priority 1: ownerid startsWith
                if (this._owners[i].ownerId.toLowerCase().startsWith(match.toString().toLowerCase()))
                    priority1Matches.push(this._owners[i]);
                // priority 2: ownerid contains
                else if (this._owners[i].ownerId.toLowerCase().indexOf(match.toString().toLowerCase()) > -1)
                    priority2Matches.push(this._owners[i]);
                // priority 3: name startsWith
                else if (this._owners[i].name.toLowerCase().startsWith(match.toString().toLowerCase()))
                    priority3Matches.push(this._owners[i]);
                // priority 4: name containas
                else if (this._owners[i].name.toLowerCase().indexOf(match.toString().toLowerCase()) > -1)
                    priority4Matches.push(this._owners[i]);
            }
    
            // concat
            retval = priority1Matches.concat(priority2Matches).concat(priority3Matches).concat(priority4Matches);
        }

        // return
        return retval;

    }
    private _filterParts(match: any): EligibleItem[] {
        // prep return
        let retval: EligibleItem[] = [];

        // ensure string
        if (match === null)
            match = '';

        // qualify search & source
        if (match.length < 1) {
            retval = this._parts;
        }
        else if (typeof match === 'object') {
            retval = [];
        }
        else {
            // initialize priority arrays
            let priority1Matches: EligibleItem[] = [];
            let priority2Matches: EligibleItem[] = [];
            let priority3Matches: EligibleItem[] = [];
            let priority4Matches: EligibleItem[] = [];
            let priority5Matches: EligibleItem[] = [];
            let priority6Matches: EligibleItem[] = [];
    
            // iterate
            for (var i = 0; i < this._parts.length; i++) {
                // priority 1: part startsWith
                if (this._parts[i].part.toLowerCase().startsWith(match.toLowerCase()))
                    priority1Matches.push(this._parts[i]);
                // priority 2: part contains
                else if (this._parts[i].part.toLowerCase().indexOf(match.toLowerCase()) > -1)
                    priority2Matches.push(this._parts[i]);
                // priority 3: desc startsWith
                if (this._parts[i].description.toLowerCase().startsWith(match.toLowerCase()))
                    priority3Matches.push(this._parts[i]);
                // priority 4: desc contains
                else if (this._parts[i].description.toLowerCase().indexOf(match.toLowerCase()) > -1)
                    priority4Matches.push(this._parts[i]);
                // priority 5: serial startsWith
                if (this._parts[i].serial.toLowerCase().startsWith(match.toLowerCase()))
                    priority5Matches.push(this._parts[i]);
                // priority 6: serial contains
                else if (this._parts[i].serial.toLowerCase().indexOf(match.toLowerCase()) > -1)
                    priority6Matches.push(this._parts[i]);
            }
            // concat, return
            retval = priority1Matches.concat(priority2Matches).concat(priority3Matches).concat(priority4Matches).concat(priority5Matches).concat(priority6Matches);
        }

        // return
        return retval;
    }

    private _loadCustomers(): void {
        if (this.isDealer) {
            this._customers = this.identityService.getDealerCustomers()
                .filter(c => !Number.isNaN(parseInt(c.customer)));

            setTimeout(() => {
                this.filteredCustomers = of(this._customers);
                this.f.selectedCustomer.setValue(this._defaultCustomer());
            }, 100);
        }
        else {
            this.filteredCustomers = this.f.selectedCustomer.valueChanges
                .pipe(
                    map(val => this._filterCustomers(val)));

            setTimeout(() => {
                this.utilityService.showLoader();
            }, 100);

            this._customers = [];
            this.identityService.customers$()
                .subscribe(response => {

                    try {
                        this._customers = response.customers
                            .filter(c => !Number.isNaN(parseInt(c.customer)));

                        this.form.get('selectedCustomer').setValue(null);
                        this.utilityService.dismissLoader();
                        this.utilityService.dismissLoader();
                    }
                    catch(ex) {
                        this.utilityService.dismissLoader();
                        throw ex;
                    }

                });
        }
    }
    private _loadOwners(customer: string, owner: Owner): void {
        this.filteredOwners = this.f.selectedOwner.valueChanges
            .pipe(
                map(val => val != 'ADD_NEW'
                    ? this._filterOwners(val)
                    : this._owners
                ));

        this._owners = [];
        this.identityService.owners$(customer)
            .subscribe(response => {
                this._owners = response.owners;
                this.form.get('selectedOwner').setValue(owner);
            });
    }
    private _loadParts(customer: string): void {
        this.filteredParts = this.f.selectedPart.valueChanges
            .pipe(
                map(val => this._filterParts(val)));

        this._parts = [];
        this.productService.eligible$(new EligibleItemsRequest().deserialize({ customer: customer }))
            .subscribe(response => {
                this._parts = response.products;
                this.form.get('selectedPart').setValue(null);
            });
    }
    private async _loadPdiHeaders(): Promise<void> {
      this._pdiHeaders = [];
      this.productService.pdiHeaders$()
        .subscribe((response: PdiHeader[]) => {
          this._pdiHeaders = response;
        });
    }

    private _setMode(): void {
        this.loggingService.warn('mode: ');
        this.loggingService.warn(this.data.mode);

        this._mode = this.data.mode;
        switch (this._mode) {
            case 'Create':
                this.cancelText = 'Cancel';
                this.title = 'Create New Product Registration';
                break;
            case 'Detail':
                this.cancelText = 'Close';
                this.title = 'Product Registration Detail';
                break;
        }
    }
    private _setPdiHeader(productLine: string): void {
        this.selectedPdiHeader = null;

        if (!productLine)
            return;
            
        this._pdiHeaders.forEach(ph => {
            ph.productLines.forEach(pl => {
                if (pl === productLine)
                    this.selectedPdiHeader = ph;
            });
        });
        this.loggingService.warn('Selected PdiHeader');
        this.loggingService.warn(this.selectedPdiHeader);
    }

    private _validateForm(applyTouch: boolean): void {
        let _errorFound: boolean = false;

        // validate reactive form
        Object.keys(this.f).forEach(field => {
            const control = this.form.get(field);
            if (applyTouch && control instanceof FormControl)
                control.markAsTouched({ onlySelf: true });
        });
        if (!this.form.valid)
            _errorFound = true;

        // check for and validate pdi questions if present
        if(this.pdiQuestions && this.pdiQuestions.length && this.pdiQuestions.length > 0) {
            this.pdiQuestions.forEach((questionUiComponent: PdiQuestionUiComponent) => {
                if(!questionUiComponent.isValid(applyTouch))
                    _errorFound = true;
            });
        }
        // eSig certify validation
        if (this.certifyCompleteError)
            _errorFound = true;

        // set flag
        this._formIsValid = !_errorFound;
    }

    private _openOwnerModal(owner: Owner, mode: string): void {
        if (this._ownerDialogOpen)
            return;

        this._ownerDialogOpen = true;

        let ownerRef = this.dialog.open(
            OwnerDialog,
            {
                data: {
                    owner: owner,
                    mode: mode
                },
                panelClass: 'margin-dialog'
            });

            ownerRef.afterClosed()
                .subscribe((result: Owner) => {
                    this._ownerDialogOpen = false;
                    if (result)
                        this._loadOwners(this.f.selectedCustomer.value.customer, result);
                    else
                        this.form.get('selectedOwner').setValue(null);
                });
    }

    public getCommentsError(): string {
        return this.f.comments.hasError('maxLength')
            ? "Maximum of 280 characters exceeded"
            : FormValidators.none;
    }
    public getDealerNameError(): string {
        return this.f.dealerName.hasError('required')
            ? FormValidators.required
            : FormValidators.none;
    }
    public getDealerNumberError(): string {
        return this.f.dealerNumber.hasError('required')
            ? FormValidators.required
            : FormValidators.none;
    }
    public getDeliveryDateError(): string {
        return this.f.deliveryDate.hasError('required')
            ? FormValidators.required
            : FormValidators.none;
    }
    public getHowHeardError(): string {
        return this.f.howHeard.hasError('required')
            ? FormValidators.required
            : FormValidators.none;
    }
    public getModelError(): string {
        return this.f.model.hasError('required')
            ? FormValidators.required
            : FormValidators.none;
    }
    public getSelectedCustomerError(): string {
        return this.f.selectedCustomer.hasError('required')
            ? FormValidators.required
            : this.f.selectedCustomer.hasError('autocomplete')
                ? FormValidators.autocomplete
                : FormValidators.none;
    }
    public getSelectedOwnerError(): string {
        return this.f.selectedOwner.hasError('required')
            ? FormValidators.required
            : this.f.selectedOwner.hasError('autocomplete')
                ? FormValidators.autocomplete
                : FormValidators.none;
    }
    public getSelectedPartError(): string {
        return this.f.selectedPart.hasError('required')
            ? FormValidators.required
            : this.f.selectedPart.hasError('autocomplete')
                ? FormValidators.autocomplete
                : FormValidators.none;
    }
    public getSerialNumberError(): string {
        return this.f.serialNumber.hasError('required')
            ? FormValidators.required
            : FormValidators.none;
    }
    public getWarrantyCodeError(): string {
        return this.f.warrantyCode.hasError('maxLength')
            ? "Maximum of 10 characters exceeded"
            : FormValidators.none;
    }

    public addOwner(): void {
        if (this.f.selectedCustomer.valid) {
            let owner: Owner = new Owner();
            owner.customerId = this.f.selectedCustomer.value.customer;
            this._openOwnerModal(owner, 'Create');
        }
    }

    public async cancel(): Promise<void> {
        this.dialogRef.close(false);
    }
    public async submit(): Promise<void> {
        this._validateForm(true);
        this._firstSubmit = true;

        if (this._formIsSubmitted || !this._formIsValid) {
            this.loggingService.warn('form invalid');
            this.loggingService.warn(this.form);
            return;
        }

        this._formIsSubmitted = true;
        this.utilityService.showLoader();

        try {

            // prep concatenated comments
            var concatenatedComments = `SALES PERSON: ${this.f.salesPerson?.value ? this.f.salesPerson.value : '-none-'} || COMMENTS: ${this.f.comments?.value ? this.f.comments.value : '-none-'} || HOW HEARD: ${this.f.howHeard?.value ? this.f.howHeard.value : '-none-'}`;

            // create base pdi form for submission
            const request = new RegisterProductRequest().deserialize({
                comments: concatenatedComments,
                deliveryDate: this.f.deliveryDate.value,
                modelNumber: this.f.model.value,
                ownerId: this.f.selectedOwner.value.ownerId,
                part: this.f.selectedPart.value.part,
                serialNumber: this.f.serialNumber.value,
                warrantyCode: this.f.warrantyCode.value,

                pdiForm: new PdiForm().deserialize({
                    pdiHeaderId: this.selectedPdiHeader?.id
                        ? this.selectedPdiHeader.id
                        : null,
                    comments: concatenatedComments,
                    dealerAccount: this.f.selectedCustomer.value.customer,
                    deliveryDate: this.f.deliveryDate.value,
                    inspectionDate: this.selectedPdiHeader?.id
                        ? this.f.inspectionDate.value
                        : this.f.deliveryDate.value,
                    model: this.f.model.value,
                    ownerId: this.f.selectedOwner.value.ownerId,
                    part: this.f.selectedPart.value.part,
                    personCompleting: this.f.personCompleting.value,
                    productLine: this.f.productLine.value,
                    serialNumber: this.f.serialNumber.value,
                    warrantyCode: this.f.warrantyCode.value,

                    answers: []
                })
            });

            this.loggingService.warn('(base) RegisterProduct request');
            this.loggingService.warn(request);

            // iterate questions, add answers
            if (this.displayPdiForm)
                this.pdiQuestions.forEach((q: PdiQuestionUiComponent) => {
                    request.pdiForm.answers.push(new PdiAnswer().deserialize({
                        pdiQuestionId: q.questionId,

                        answerText: q.value
                    }));
                });

            this.loggingService.warn('(full) RegisterProduct request');
            this.loggingService.warn(request);

            // submit
            this.productService.registerProduct(request)
                .subscribe(response => {
                    if (response.status === ApiResponses.SUCCESS) {
                        Swal.fire('Success', AppAlerts.ProductRegistration.success, 'success');
                        this.dialogRef.close(true);
                    }
                    else
                        Swal.fire('Error', AppAlerts.ProductRegistration.failure, 'error');

                    this._formIsSubmitted = false;
                    this.utilityService.dismissLoader();
                });

        }
        catch (ex) {
            Swal.fire('Error', AppAlerts.ProductRegistration.failure, 'error');
            this._formIsSubmitted = false;
            this.utilityService.dismissLoader();
        }

    }
}
