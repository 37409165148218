import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';

import Litepicker from 'litepicker';
import { DateTime } from 'litepicker/dist/types/datetime';

import { ButtonRendererComponent } from 'src/app/components/cell-renderers';

import {
  Item,
  ProductRegistration
} from 'src/app/domain/models';

import {
  ProductRegistrationsRequest
} from 'src/app/domain/requestresponseobjects';

import {
  ProductDialog
} from 'src/app/components/dialogs'

import {
  ContentService,
  IdentityService,
  ProductService,
  UtilityService
} from 'src/app/services';
import { PdiQuestionUiComponent } from '../../inline';

@Component({
  selector: 'app-product-registration',
  templateUrl: './product-registration.component.html',
  styleUrls: ['./product-registration.component.scss']
})
export class ProductRegistrationComponent implements OnInit {
 
  private readonly _date: Date = new Date();
  
  public companyPhone: string;
  public companyEmail: string;

  private _datePickerStart: Litepicker;
  private _datePickerEnd: Litepicker;
  private _gridApi: any;
  private _isDealer: boolean = true;
  private _subIncludeDateRange: Subscription;

  public columnDefs = [];
  public endDate: Date = this._date;
  public filtersOpened: boolean = false;
  public filteredParts: Observable<Item[]>;
  public form: FormGroup = new FormGroup({});
  public frameworkComponents: any = { buttonRenderer: ButtonRendererComponent };
  public isDealer: boolean = true;
  public isDesktop: boolean = false;
  public products: ProductRegistration[] = [];
  public paginationPageSize: number = 25;
  public startDate: Date = new Date(this._date.getFullYear(), this._date.getMonth() - 2, 1);

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    private contentService: ContentService,
    private deviceDetectorService: DeviceDetectorService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private identityService: IdentityService,
    private productService: ProductService,
    private utilityService: UtilityService) {
      this.form = this.formBuilder.group({
        includeDateRange: false
      });
      this._isDealer = this.identityService.userIsInRole('Dealer');
  }

  ngOnDestroy(){;
    if (this._subIncludeDateRange)
      this._subIncludeDateRange.unsubscribe();
  }

  ngOnInit() {
    this.filtersOpened = this.isDesktop = this.deviceDetectorService.isDesktop();
    this.isDealer = this.identityService.userIsInRole('Dealer');
    this.contentService.texttokens$()
    .subscribe(tokens => {
      this.companyPhone = tokens.filter(t => t.name === 'CompanyPhoneNumber')[0].text;
      this.companyEmail = tokens.filter(t => t.name === 'CompanyEmail')[0].text;
    });
    this._setColumnDefs();
    this._setDatePickers();

    this._subIncludeDateRange = this.f.includeDateRange.valueChanges
      .subscribe((val) => {
        this._loadProducts();
      });
  }

  private _loadProducts(): void{
    this._gridApi.showLoadingOverlay();

    this.productService.productRegistrations$(new ProductRegistrationsRequest().deserialize({
      customerName: this._isDealer
        ? this.identityService.currentUser.customers[0].name
        : '',
      deliveryDateFrom: this.f.includeDateRange.value
        ? this._datePickerStart.getDate().toJSDate()
        : null,
      deliveryDateTo: this.f.includeDateRange.value
        ? this._datePickerEnd.getDate().toJSDate()
        : null,
    })
    ).subscribe(response => {
      this._gridApi.hideOverlay();
      this.products = response.productRegistrations.sort((a, b) => {
        if (a.deliveryDate < b.deliveryDate)
          return 1;
        else if (a.deliveryDate > b.deliveryDate)
          return -1;
        else
          return 0;
      })
    });
  }

  private _setColumnDefs(): void {
    if (this._isDealer)
      this.columnDefs = [
        this.utilityService.gridColumnDef('serialNumber', 'Serial #', this.utilityService.gridFormatterCapitalizeAll),
        this.utilityService.gridColumnDef('part', 'Part #', this.utilityService.gridFormatterCapitalizeAll),
        this.utilityService.gridColumnDef('description', 'Description', this.utilityService.gridFormatterCapitalizeAll),
        this.utilityService.gridColumnDef('deliveryDate', 'Date of Delivery', this.utilityService.gridFormatterDate, false),
        this.utilityService.gridColumnDef('warrantyEnd', 'Warranty End', this.utilityService.gridFormatterDate, false),
        this.utilityService.gridColumnDef('ownerName', 'Owner Name', this.utilityService.gridFormatterCapitalizeAll),
        //this.utilityService.gridColumnDef('claimNumber', 'Claim #', this.utilityService.gridFormatterCapitalizeAll),
        //add claims button,
        {
          cellRenderer: 'buttonRenderer',
          cellRendererParams: {
            onClick: this._rowDetail.bind(this),
            icon: 'search'
          },
          headerName: '', filter: false
        }
      ];
    else
      this.columnDefs = [
        this.utilityService.gridColumnDef('serialNumber', 'Serial #', this.utilityService.gridFormatterCapitalizeAll),
        this.utilityService.gridColumnDef('part', 'Part #', this.utilityService.gridFormatterCapitalizeAll),
        this.utilityService.gridColumnDef('description', 'Description', this.utilityService.gridFormatterCapitalizeAll),
        this.utilityService.gridColumnDef('deliveryDate', 'Date of Delivery', this.utilityService.gridFormatterDate, false),
        this.utilityService.gridColumnDef('warrantyEnd', 'Warranty End', this.utilityService.gridFormatterDate, false),
        this.utilityService.gridColumnDef('customerName', 'Customer Name', this.utilityService.gridFormatterCapitalizeAll),
        this.utilityService.gridColumnDef('ownerName', 'Owner Name', this.utilityService.gridFormatterCapitalizeAll),
        //this.utilityService.gridColumnDef('claimNumber', 'Claim #', this.utilityService.gridFormatterCapitalizeAll),
        //add claims button,
        {
          cellRenderer: 'buttonRenderer',
          cellRendererParams: {
            onClick: this._rowDetail.bind(this),
            class: 'grid-button-g',
            icon: 'search'
          },
          headerName: '', filter: false
        }
      ];
  }
  public async addNew(): Promise<void> {
    this._openRegisterModal(null, 'Create');
  }

  private _openRegisterModal(product: ProductRegistration, mode: string): void {
    let ref = this.dialog.open(
      ProductDialog,
      {
        closeOnNavigation: true,
        data: {
          product: product,
          mode: mode
        },
        panelClass: 'margin-dialog'
      });

    ref.afterClosed()
      .subscribe((result: ProductRegistration) => {
        if (result)
          this._loadProducts();
      });
  } 
  private _rowDetail(params: any): void {
    // console.warn('row detail: ', params.data);
    this._openRegisterModal(params.data, 'Detail');
  }

  private _setDatePickers(): void {
    this._datePickerStart = new Litepicker({
      dropdowns: {
        maxYear: null,
        minYear: 1990,
        months: true,
        years: true
      },
      element: document.getElementById('deliveryStartDate'),
      format: 'MM/DD/YYYY',
      maxDate: new Date(),
      setup: (picker) => {
        picker.on('show', () => {
          if (!this.f.includeDateRange.value)
            picker.hide();
        }),
        picker.on('selected', (d: DateTime) => {
          if (d.isAfter(this._datePickerEnd.getDate()))
            picker.setDate(this._datePickerEnd.getDate());
          this._loadProducts();
        })
      },
      singleMode: true,
      startDate: this.startDate
    });
    this._datePickerEnd = new Litepicker({
      dropdowns: {
        maxYear: null,
        minYear: 1990,
        months: true,
        years: true
      },
      element: document.getElementById('deliveryEndDate'),
      format: 'MM/DD/YYYY',
      maxDate: new Date(),
      setup: (picker) => {
        picker.on('show', () => {
          if (!this.f.includeDateRange.value)
            picker.hide();
        }),
        picker.on('selected', (d: DateTime) => {
          if (d.isBefore(this._datePickerStart.getDate()))
            picker.setDate(this._datePickerStart.getDate());
          this._loadProducts();
        })
      },
      singleMode: true,
      startDate: this.endDate
    });
  }

  public async filtersToggle(): Promise<void> {
    this.filtersOpened = !this.filtersOpened;
  }

  public onGridReady(ev: any): void {
    this._gridApi = ev.api;

    this._loadProducts();
  }

  public async onPaginationPageSizeChange(): Promise<void> {
    this._gridApi.paginationSetPageSize(this.paginationPageSize);
  }

  public paginationNumberFormatter(params: any): string {
    return `[${Number(params.value).toLocaleString('en-US')}]`;
  }

}
