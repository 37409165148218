import { _deserializable } from '../_deserializable';
import { PdiQuestion } from '.';

export class PdiHeader extends _deserializable {

    public id: string;

    public formTitle: string;
    public formSubtitle: string;

    public productLines: string[];
    public questions: PdiQuestion[] = [];

}
