
<mat-card class="content">

  <mat-card-title>
    Welcome to the New Art’s Way Dealer Portal
  </mat-card-title>

  <mat-card-content [innerHTML]="text">
  </mat-card-content>

</mat-card>
