import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
  FormValidators
} from 'src/app/constants';

import {
  Country,
  Customer,
  State
} from 'src/app/domain/models';

import {
  OrderService
} from 'src/app/services';

@Component({
  selector: 'app-create-shipto',
  templateUrl: './create-shipto.dialog.html',
  styleUrls: ['./create-shipto.dialog.scss']
})
export class CreateShiptoDialog implements AfterViewInit, OnDestroy, OnInit {

  private _countries: Country[];
  private _states: State[];
  private _subCountryAutoTrigger: Subscription;
  private _subStateAutoTrigger: Subscription;

  public filteredCountries: Observable<Country[]>;
  public filteredStates: Observable<State[]>;
  public form: FormGroup;

  @ViewChild('countryAutoInput', { read: MatAutocompleteTrigger }) countryAutoTrigger: MatAutocompleteTrigger;
  @ViewChild('stateAutoInput', { read: MatAutocompleteTrigger }) stateAutoTrigger: MatAutocompleteTrigger;

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private customer: Customer,
    public dialogRef: MatDialogRef<CreateShiptoDialog>,
    private formBuilder: FormBuilder,
    private orderService: OrderService) {
      this.form = this.formBuilder.group({
        attn: null,
        city: [null, Validators.required],
        country: ['USA', Validators.compose([ Validators.required, this._autocompleteValidator.bind(this) ])],
        line1: [null, Validators.required],
        line2: null,
        line3: null,
        name: [null, Validators.required],
        phone: [null, Validators.compose([ Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$') ])],
        state: [null, Validators.compose([ Validators.required, this._autocompleteValidator.bind(this) ])],
        zip: [null, Validators.required]
      });
    }

  ngAfterViewInit() {
    this._subCountryAutoTrigger = this.countryAutoTrigger.panelClosingActions.subscribe(() => {
      if (this.countryAutoTrigger.activeOption)
        this.f.country.setValue(this.countryAutoTrigger.activeOption.value);
    });
    this._subStateAutoTrigger = this.stateAutoTrigger.panelClosingActions.subscribe(() => {
      if (this.stateAutoTrigger.activeOption)
        this.f.state.setValue(this.stateAutoTrigger.activeOption.value);
    });
  }
  ngOnDestroy() {
    if (this._subCountryAutoTrigger)
      this._subCountryAutoTrigger.unsubscribe();
    if (this._subStateAutoTrigger)
      this._subStateAutoTrigger.unsubscribe();
  }
  ngOnInit() {
    this._loadCountries();
    this._loadStates();
  }

  private _autocompleteValidator(control: AbstractControl) {
    return control.value && typeof control.value === 'string'
      ? { autocomplete: true }
      : null;
  }

  private _filterCountries(match: any) : Country[] {
    // ensure string
    match += '';

    // initialize priority array
    let priority1Matches: Country[] = [];
    let priority2Matches: Country[] = [];
    let priority3Matches: Country[] = [];

    // iterate
    for (var i = 0; i < this._countries.length; i++) {
      // priority 1: description startsWith
      if (this._countries[i].description.toLowerCase().startsWith(match.toLowerCase()))
        priority1Matches.push(this._countries[i]);
      // priority 3: country startsWith
      else if (this._countries[i].country.toLowerCase().startsWith(match.toLowerCase()))
        priority3Matches.push(this._countries[i]);
      // priority 2: description contains
      else if (this._countries[i].description.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority2Matches.push(this._countries[i]);
    }

    // concat, return
    return priority1Matches.concat(priority2Matches).concat(priority3Matches);
  }
  private _filterStates(match: any) : State[] {
    // ensure string
    match += '';

    // initialize priority array
    let priority1Matches: State[] = [];
    let priority2Matches: State[] = [];
    let priority3Matches: State[] = [];

    // iterate
    for (var i = 0; i < this._states.length; i++) {
      // priority 1: description startsWith
      if (this._states[i].description.toLowerCase().startsWith(match.toLowerCase()))
        priority1Matches.push(this._states[i]);
      // priority 3: state startsWith
      else if (this._states[i].state.toLowerCase().startsWith(match.toLowerCase()))
        priority3Matches.push(this._states[i]);
      // priority 2: description contains
      else if (this._states[i].description.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority2Matches.push(this._states[i]);
    }

    // concat, return
    return priority1Matches.concat(priority2Matches).concat(priority3Matches);
  }

  private _loadCountries(): void {
    this.filteredCountries = this.f.country.valueChanges
      .pipe(
        map(val => this._filterCountries(val)));

    this.orderService.countries$()
      .subscribe(response => {
        this._countries = response.countries;
        this.f.country.setValue(this._countries.filter(c => c.country === 'USA')[0])
      });
  }
  private _loadStates(): void {
    this.filteredStates = this.f.state.valueChanges
      .pipe(
        map(val => this._filterStates(val)));

    this.orderService.states$()
      .subscribe(response => {
        this._states = response.states;
      });
  }

  private _validateForm(): void {
    Object.keys(this.f).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl)
        control.markAsTouched({ onlySelf: true });
    });
  }

  public async cancel(): Promise<void> {
    this.dialogRef.close();
  }

  public displayCountryWith(country: Country): string | undefined {
    return country
      ? country.description
      : undefined;
  }
  public displayStateWith(state: State): string | undefined {
    return state
      ? state.description
      : undefined;
  }

  public getCityError(): string {
    return this.f.city.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getCountryError(): string {
    return this.f.country.hasError('autocomplete')
      ? FormValidators.autocomplete
      : this.f.country.hasError('required')
        ? FormValidators.required
        : FormValidators.none;
  }
  public getLine1Error(): string {
    return this.f.line1.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getNameError(): string {
    return this.f.name.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getPhoneError(): string {
    return this.f.phone.hasError('minlength') || this.f.phone.hasError('maxlength') || this.f.phone.hasError('pattern')
      ? FormValidators.phone
      : FormValidators.none;
  }
  public getStateError(): string {
    return this.f.state.hasError('autocomplete')
      ? FormValidators.autocomplete
      : this.f.state.hasError('required')
        ? FormValidators.required
        : FormValidators.none;
  }
  public getZipError(): string {
    return this.f.zip.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }

  public async submit(): Promise<void> {
    this._validateForm();

    if (!this.form.valid)
      return;
    
    this.dialogRef.close(this.form.value);
  }

}
