import { _deserializable } from '../_deserializable';

export class PdiAnswer extends _deserializable {

    public id: string;
    public pdiQuestionId: string;

    public answerText: string;

}
