import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ApiResponses,
  ApiRoutes
} from 'src/app/constants';

import {
  BaseResponse
} from 'src/app/domain/requestresponseobjects';

import { AjaxService } from './ajax.service';
import { LoggingService } from './logging.service';

@Injectable()
export class SystemService {

  constructor(
    private ajaxService: AjaxService,
    private loggingService: LoggingService) {
      this.loggingService.warn('SystemService initialized.')
  }

  public apiEnv$(): Observable<string> {
    return this.ajaxService.GET<string>(
      ApiRoutes.apienv,
      'Unknown');
  }

  public sendWelcomeEmails$(): Observable<BaseResponse> {
    return this.ajaxService.GET<BaseResponse>(
      ApiRoutes.sendWelcomeEmails,
      new BaseResponse().deserialize({
        status: ApiResponses.FAILURE
      }));
  }

  public ping$(): Observable<string> {
    return this.ajaxService.GET<string>(
      ApiRoutes.ping,
      'No Response');
  }

  public qadroot$(): Observable<string> {
    return this.ajaxService.GET<string>(
      ApiRoutes.qadroot,
      'Unknown');
  }

}
