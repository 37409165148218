import { _deserializable } from '../_deserializable';

export class AddShipToRequest extends _deserializable {

  public attn: string;
  public city: string;
  public country: string;
  public customer: string;
  public line1: string;
  public line2: string;
  public line3: string;
  public name: string;
  public phone: string;
  public state: string;
  public zip: string;

}
