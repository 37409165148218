import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

import { MatDialog } from '@angular/material/dialog';
import { ButtonRendererComponent } from 'src/app/components/cell-renderers';

import {
  ApiResponses,
  AppAlerts,
  FormValidators
} from 'src/app/constants';

import {
  PdiHeader, ProductLine
} from 'src/app/domain/models';

import {
} from 'src/app/domain/requestresponseobjects';

import {
  PdiDialog
} from 'src/app/components/dialogs';

import {
  ProductService,
  UtilityService
} from 'src/app/services';

@Component({
  selector: 'app-pdi-management',
  templateUrl: './pdi-management.component.html',
  styleUrls: ['./pdi-management.component.scss']
})
export class PdiManagementComponent implements OnDestroy, OnInit {

  //private _gridApi: any;

  public columnDefs = [];
  public form: FormGroup;
  public frameworkComponents: any = { buttonRenderer: ButtonRendererComponent };
  public pdiHeaders: PdiHeader[];

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    private dialog: MatDialog,
    private productService: ProductService,
    private utilityService: UtilityService) {
    }

  ngOnDestroy() {
  }
  ngOnInit() {
    this._setColumnDefs();
    this._loadPdiHeaders();
  }

  private async _loadPdiHeaders(): Promise<void> {
    this.pdiHeaders = [];
    this.productService.pdiHeaders$()
      .subscribe((response: PdiHeader[]) => {
        this.pdiHeaders = response.sort((a: PdiHeader, b: PdiHeader) => {
          if (a.formTitle < b.formTitle)
            return -1;
          if (a.formTitle > b.formTitle)
            return 1;
          return 0;
        });
      });
  }
  private _setColumnDefs(): void {
    this.columnDefs = [{
      // form title
      field: 'formTitle',
      headerName: 'PDI Form Title',
      filter: true,
      flex: 3
    }, {
        // form subtitle
        field: 'formSubtitle',
        headerName: 'PDI Form Subtitle',
        filter: true,
        flex: 3
      }, {
      // product lines
      valueGetter: params => {
        return params.data.productLines
        .join(', ');
      },
      //field: 'productLineDisplay',
      headerName: 'Product Line(s)',
      filter: true,
      flex: 6
    }, {
      // question count
      valueGetter: params => {
        return params.data.questions.length;
      },
      //field: 'questionCount',
      headerName: 'Question Count',
      filter: false,
      flex: 2
    }, {
      // edit btn
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.rowEdit.bind(this),
        icon: 'edit'
      },
      headerName: '',
      filter: false,
      flex: 1
    }, {
      // delete btn
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.rowDelete.bind(this),
        icon: 'clear'
      },
      headerName: '',
      filter: false,
      flex: 1
    }];
  }

  public async addPdi(): Promise<void> {
    await this._pdiDialog('Add New', new PdiHeader());
  }
  private async _pdiDialog(action: string, header: PdiHeader): Promise<void> {
    let ref = this.dialog.open(
      PdiDialog,
      {
        width: '100vw',
        data: {
          action: action,
          header: header
        }
      });

    ref.afterClosed()
      .subscribe(result => {
        if (result)
          this._loadPdiHeaders();

      });
  }

  public onGridReady(ev: any): void {
    //this._gridApi = ev.api;
  }
  public rowDelete(params: any): void {
    //this.pdiHeaders = this.orderItems.filter(i => i.part !== params.data.part);
    alert('delete');
  }
  public rowEdit(params: any): void {
    //this.pdiHeaders = this.orderItems.filter(i => i.part !== params.data.part);
    this._pdiDialog('Edit', params.data);
  }

}
