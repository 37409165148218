
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable()
export class LoggingService {

  private _consoleEnabled: boolean;

  constructor() {
    this._consoleEnabled = !environment.production;
    this.warn('LoggingService initialized.');
  }

  public _disableOverride(): void {
    this._consoleEnabled = false;
    this.warn('Logging override disabled.');
  }
  public _enableOverride(): void {
    this._consoleEnabled = true;
    this.warn('Logging override enabled.');
  }

  public error(s: any): void {
    // TODO: errors should, in production, be sent to the win svc for either persistence or admin notification
    if (this._consoleEnabled) {
      console.error(s);
    }
  }
  public info(s: any): void {
    if (this._consoleEnabled) {
      console.info(s);
    }
  }
  public log(s: any): void {
    if (this._consoleEnabled) {
      console.log(s);
    }
  }
  public warn(s: any): void {
    if (this._consoleEnabled) {
      console.warn(s);
    }
  }

}
