import { _deserializable } from '../_deserializable';

export class OrderSummariesRequest extends _deserializable {

  public confirmed: number;
  public customer: string;
  public invoice: string;
  public endDate: Date;
  //public name: string;
  //public orderType: number;
  public part: string;
  public po: string;
  public so: string;
  public startDate: Date;
  public shipped: number;
}
