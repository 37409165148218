import { _deserializable } from '../_deserializable';
import { IKeyValuePair } from '../kvp';

export class TextToken extends _deserializable {

  public id: string;

  public description: string;
  public name: string;
  public text: string;

  public createdOn: Date;
  public updatedOn: Date;
  public isDeleted: boolean;
  public deletedOn: Date;

}
