
<mat-card>

  <mat-card-title>
    System Admin
  </mat-card-title>

  <mat-card-content>
    <mat-tab-group (selectedTabChange)="selectedTabChange($event)">

    <!-- Base Info -->
      <mat-tab label="System Information">
        <section>
          <hr/>
          <u>Application</u><br/>
          Host: <i>{{appHost}}</i><br/>
          Environment: <i>{{appEnv}}</i><br/>
          <br/>
          <u>Api</u><br/>
          Base Url: <i>{{apiUrl}}</i><br/>
          Ping Status: <i>{{pingStatus}}</i><br/>
          Environment: <i>{{apiEnv}}</i><br/>
          <br/>
          <u>QAD</u><br/>
          Base Url: <i>{{qadroot}}</i><br/>
          <br/>
          <u>Portal</u><br/>
          <i>(Active User Count: {{userCount}})</i><br/>
        </section>
      </mat-tab>
    
      <!-- Text Tokens -->
      <!-- <mat-tab label="Text Tokens"> -->
      <mat-tab label="Home Page and Company Info Text">
        <section>
          <hr/>
          <h4>Home Page Text</h4>
          <div
            id="editor">
          </div>
          <textarea
            [(ngModel)] = 'homeText.text'
            cols="100"
            rows="10"
            multiline></textarea><br/>
            <button mat-flat-button color="primary" (click)="postHomeText()">Save</button>
            <br/><br/><br/>

            <h4>Company Phone Number Text</h4>
            <input [(ngModel)] = "phoneText.text"><br/>
            <button mat-flat-button color="primary" (click)="postPhoneText()">Save</button>
            <br/><br/><br/>
            
            <h4>Company Email Text</h4>
            <input [(ngModel)] = "emailText.text"><br/>
          <button mat-flat-button color="primary" (click)="postEmailText()">Save</button>
        </section>
      </mat-tab>

      <!-- Links -->
      <mat-tab label="Link Management">
        <section>
          <hr/>
          <u>Links</u><br/>
          <span style="font-style: italic;">(Link Location: 0 - Footer, 1 = Header)</span>
          <ag-grid-angular
            (cellValueChanged)="onCellValueChanged($event)"
            class="ag-theme-alpine"
            [columnDefs]="columnDefs"
            domLayout="autoHeight"
            [editType]="'cell'"
            [frameworkComponents]="frameworkComponents"
            (gridReady)="onGridReady($event)"
            [rowData]="links"
            style="width: 100%;"></ag-grid-angular>
          <span style="font-style: italic;">(double-click a cell to edit)</span>
        </section>
      </mat-tab>

    </mat-tab-group>
  </mat-card-content>
  
</mat-card>
