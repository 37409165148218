import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

import {
  ApiResponses,
  ApiRoutes,
  SessionVariables
} from 'src/app/constants';

import {
  Customer,
  Role,
  User
} from 'src/app/domain/models';

import {
  BaseResponse,
  CustomerResponse,
  OwnerResponse,
  OwnerUpsertRequest,
  RegisterRequest,
  ResetPasswordRequest,
  SigninRequest,
  SignInResponse,
  UsersRequest,
  UsersResponse
} from 'src/app/domain/requestresponseobjects';

import { AjaxService } from './ajax.service';
import { LoggingService } from './logging.service';
import { UtilityService } from './utility.service';

@Injectable()
export class IdentityService {

  public currentUser$: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;

  public get currentUser(): User {
    return this.currentUserSubject.value;
  }
  public get userFullName(): string {
    return this.currentUserSubject.value
      ? `${this.currentUserSubject.value.firstName} ${this.currentUserSubject.value.lastName}`
      : '';
  }
  public get userToken(): string {
    return sessionStorage.getItem(
      SessionVariables.token);
  }

  constructor(
    private ajaxService: AjaxService,
    private loggingService: LoggingService,
    private utilityService: UtilityService) {
    this.loggingService.warn('IdentityService initialized');

    this.currentUserSubject = new BehaviorSubject<User>(null);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.loadUserFromStorage();
  }

  public customers$(): Observable<CustomerResponse> {
    this.loggingService.info('Fetching customers...');

    return this.ajaxService
      .GET<CustomerResponse>(
        ApiRoutes.customers,
        new CustomerResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ApiResponses.COMMON_ERROR_MESSAGE],
          customers: []
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('CustomerResponse');
          this.loggingService.warn(response);
          if (response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('customer fetch successful.');
          }
          else {
            // failure
            this.loggingService.error('customer fetch failed.');
          }

          // return
          return response;
        }));
  }
  public owners$(customer: string): Observable<OwnerResponse> {
    this.loggingService.info('Fetching owners...');

    return this.ajaxService
      .GET<OwnerResponse>(
        `${ApiRoutes.customers}/${customer}/owners`,
        new OwnerResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ApiResponses.COMMON_ERROR_MESSAGE],
          owners: []
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('OwnersResponse');
          this.loggingService.warn(response);
          if (response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('owner fetch successful.');
          }
          else {
            // failure
            this.loggingService.error('owner fetch failed.');
          }

          // return
          return response;
        }));
  }
  public roles$(): Observable<Role[]> {
    this.loggingService.info('Fetching roles...');

    return this.ajaxService
      .GET<Role[]>(
        ApiRoutes.roles,
        [])
      .pipe(
        map(response => {
          this.loggingService.warn('RoleResponse');
          this.loggingService.warn(response);
          this.loggingService.info('role fetch successful.');

          // return
          return response;
        }));
  }

  public changeStatus(userId: string, status: string): Observable<User> {
    return this.ajaxService
      .POST(ApiRoutes.changestatus,
        {
          userId: userId,
          status: status
        },
        null);
  }

  private extractUserFromToken(shouldPersist: boolean = false, token: string): void {
    // persist token
    if (shouldPersist)
      sessionStorage.setItem(SessionVariables.token, token);
    // decode token
    var decodedToken = token
      ? jwt_decode(token)
      : null;
    // extract user
    const user = decodedToken
      ? new User().deserialize({
        id: decodedToken['id'],

        currentIpAddress: decodedToken['currentIpAddress'],
        customers: JSON.parse(decodedToken['customers']),
        email: decodedToken['email'],
        firstName: decodedToken['firstName'],
        lastLoginIpAddress: decodedToken['lastLoginIpAddress'],
        lastLoginTimestamp: decodedToken['lastLoginTimestamp'],
        lastName: decodedToken['lastName'],
        phoneNumber: decodedToken['phoneNumber'],
        pricelist: decodedToken['pricelist'],
        role: decodedToken['role'],

        createdOn: decodedToken['createdOn'],
        updatedOn: decodedToken['updatedOn'],
        isDeleted: decodedToken['isDeleted'],
        deletedOn: decodedToken['deletedOn']

      })
      : null;

    this.loggingService.warn(user);

    // broadcast user
    this.currentUserSubject.next(user);
    // check role/set logging
    if (user && this.userIsInRole('Admin') && this.utilityService.currentEnvironment === 'Production')
      this.loggingService._enableOverride();
  }

  public getDealerCustomers(): Customer[] {
    return this.currentUserSubject.value.customers;
  }

  public upsertOwner$(request: OwnerUpsertRequest): Observable<BaseResponse> {
    this.loggingService.info("Adding/Updating Owner...");

    return this.ajaxService
      .POST<BaseResponse>(
        ApiRoutes.owner,
        request,
        new BaseResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ApiResponses.COMMON_ERROR_MESSAGE]
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('BaseResponse');
          this.loggingService.warn(response);
          if (response.status === ApiResponses.SUCCESS) {
            this.loggingService.info("Add/Update Owner successful.");
          } else {
            this.loggingService.error('Add/Update Owner failed.');
          }
          return response;
        }));
  }


  public getPricelist(): string {
    return this.userIsInRole('Dealer')
      ? this.currentUserSubject.value.pricelist
      : 'AWL';
  }

  public importUsers(data: FormData): Observable<BaseResponse> {
    return this.ajaxService.POST<BaseResponse>(
      ApiRoutes.importUsers,
      data,
      null,
      new HttpHeaders());
  }

  private loadUserFromStorage(): void {
    this.extractUserFromToken(
      false,
      sessionStorage.getItem(
        SessionVariables.token));
  }

  public register(request: RegisterRequest): Observable<BaseResponse> {
    this.loggingService.info('Submitting registration request...');

    return this.ajaxService.POST<BaseResponse>(
      ApiRoutes.register,
      request,
      new BaseResponse().deserialize({
        status: ApiResponses.FAILURE,
        messages: [ApiResponses.COMMON_ERROR_MESSAGE]
      }))
      .pipe(
        map(response => {
          this.loggingService.warn(response);
          if (response.status === ApiResponses.SUCCESS)
            this.loggingService.info('Registration successful.');
          else
            this.loggingService.info('Registration failed.');

          return response;
        }));
  }

  public update(request: RegisterRequest): Observable<BaseResponse> {
    this.loggingService.info('Submitting update request...');

    return this.ajaxService.POST<BaseResponse>(
      ApiRoutes.update,
      request,
      new BaseResponse().deserialize({
        status: ApiResponses.FAILURE,
        messages: [ApiResponses.COMMON_ERROR_MESSAGE]
      }))
      .pipe(
        map(response => {
          this.loggingService.warn(response);
          if (response.status === ApiResponses.SUCCESS)
            this.loggingService.info('Update successful.');
          else
            this.loggingService.info('Update failed.');

          return response;
        }));
  }

  public getPasswordResetToken(): Observable<string> {
    this.loggingService.info('Submitting password token request...');
    return this.ajaxService.GET<string>(
      `${ApiRoutes.passwordResetToken}`, "token");
  }

  public ValidatePasswordResetToken(token: string): Observable<BaseResponse> {
    this.loggingService.info('Validating password token...');
    return this.ajaxService
      .GET<BaseResponse>(
        `${ApiRoutes.passwordResetToken}/${token}`,
        new BaseResponse().deserialize({ status: ApiResponses.INVALID_PASSWORD_RESET_TOKEN }))
      .pipe(
        map(response => {
          this.loggingService.warn(response);
          if (response.status === ApiResponses.SUCCESS) {
            // success
            this.loggingService.info('Password token is invalid.');
          }
          else {
            // failure
            this.loggingService.info('Password token is valid');
          }
          // return
          return response;
        }));
  }

  public resetPassword(request: ResetPasswordRequest): Observable<BaseResponse> {
    this.loggingService.info('Submitting password reset request...');

    return this.ajaxService.POST<BaseResponse>(
      ApiRoutes.resetPassword,
      request,
      new BaseResponse().deserialize({ status: ApiResponses.FAILURE }))
      .pipe(
        map(response => {
          this.loggingService.warn(response);
          if (response.status === ApiResponses.SUCCESS) {
            // success
            this.loggingService.info('Password reset request successful.');
          }
          else {
            // failure
            this.loggingService.info('Password reset request failed.');
          }

          // return
          return response;
        }));
  }

  public sendReset(email: string): Observable<BaseResponse> {
    this.loggingService.info('Submitting send reset request...');

    return this.ajaxService.GET<BaseResponse>(
      `${ApiRoutes.sendReset}/${email}`,
      new BaseResponse().deserialize({ status: ApiResponses.FAILURE }))
      .pipe(
        map(response => {
          this.loggingService.warn(response);
          if (response.status === ApiResponses.SUCCESS) {
            // success
            this.loggingService.info('Send reset request successful.');
          }
          else {
            // failure
            this.loggingService.info('Send reset request failed.');
          }

          // return
          return response;
        }));
  }

  public signin(request: SigninRequest): Observable<SignInResponse> {
    this.loggingService.info('Submitting signin request...');

    // pass-through call to IS
    return this.ajaxService
      .POST<SignInResponse>(
        ApiRoutes.signin,
        request,
        new SignInResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: ['An unknown error occurred. Please try again, and contact support if the problem persists.']
        }))
      .pipe(
        map(response => {
          this.loggingService.warn(response);
          if (response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('Signin successful.');
            this.extractUserFromToken(true, response.token)
          }
          else {
            // failure
            this.loggingService.error('Signin failed.');
          }

          // return
          return response;
        }));
  }

  public signOut(): void {
    this.loggingService.info('Signing out...');

    // clear necessary items/overrides
    sessionStorage.removeItem(SessionVariables.token);
    this.currentUserSubject.next(null);
    if (this.utilityService.currentEnvironment === 'Production')
      this.loggingService._disableOverride();
  }

  public users$(request: UsersRequest): Observable<UsersResponse> {
    this.loggingService.info('Fetching users...');

    return this.ajaxService
      .POST<UsersResponse>(
        ApiRoutes.users,
        request,
        new UsersResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ApiResponses.COMMON_ERROR_MESSAGE],
          users: []
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('UsersResponse');
          this.loggingService.warn(response);
          if (response.status === ApiResponses.SUCCESS) {
            // success; extract & persist
            this.loggingService.info('User fetch successful.');
          }
          else {
            // failure
            this.loggingService.error('User fetch failed.');
          }

          // return
          return response;
        }));
  }

  public userIsInRole(roleName: string): boolean {
    return this.currentUserSubject.value
      ? this.currentUserSubject.value.role === roleName
      : false;
  }

  public validateToken(forceSignout: boolean): boolean {
    let tokenIsValid: boolean = false;

    // fetch persisted token
    let token = sessionStorage.getItem(SessionVariables.token);

    if (token) {
      // decode, check expiration
      let decodedToken = jwt_decode(token);

      const exp = new Date(0);
      exp.setUTCSeconds(decodedToken['exp']);
      const now = new Date();

      tokenIsValid = exp > now;
    }

    if (forceSignout && !tokenIsValid)
      this.signOut();

    return tokenIsValid;
  }

}
