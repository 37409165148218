import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import {
    Country,
    Owner,
    State
} from 'src/app/domain/models';

import {
    ApiResponses,
    AppAlerts,
    FormValidators
} from 'src/app/constants';

import {
    IdentityService,
    OrderService,
    UtilityService
} from 'src/app/services';
import { OwnerUpsertRequest } from 'src/app/domain/requestresponseobjects';

@Component({
    selector: 'app-owner',
    templateUrl: './owner.dialog.html',
    styleUrls: ['./owner.dialog.scss']
})

export class OwnerDialog implements AfterViewInit, OnDestroy, OnInit {

    private _countries: Country[];
    private _mode: string;
    private _states: State[];
    private _subCountryAutoTrigger: Subscription;
    private _subStateAutoTrigger: Subscription;

    public filteredCountries: Observable<Country[]>;
    public filteredStates: Observable<State[]>;
    public form: FormGroup;
    public formSubmitted: boolean = false;
    public owner: Owner;
    public submitText: string;
    public title: string;

    public get f(): any {
        return this.form.controls;
    }

    @ViewChild('countryAutoInput', { read: MatAutocompleteTrigger }) countryAutoTrigger: MatAutocompleteTrigger;
    @ViewChild('stateAutoInput', { read: MatAutocompleteTrigger }) stateAutoTrigger: MatAutocompleteTrigger;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        public ownerDialogRef: MatDialogRef<OwnerDialog>,
        private formBuilder: FormBuilder,
        private identityService: IdentityService,
        private orderService: OrderService,
        private utilityService: UtilityService) {
        this.owner = <Owner>this.data.owner;
    }

    ngAfterViewInit(): void {
        this._subCountryAutoTrigger = this.countryAutoTrigger.panelClosingActions.subscribe(() => {
            if (this.countryAutoTrigger.activeOption)
                this.f.country.setValue(this.countryAutoTrigger.activeOption.value);
        });
        this._subStateAutoTrigger = this.stateAutoTrigger.panelClosingActions.subscribe(() => {
            if (this.stateAutoTrigger.activeOption)
                this.f.state.setValue(this.stateAutoTrigger.activeOption.value);
        });
    }
    ngOnDestroy(): void {
        if (this._subCountryAutoTrigger)
            this._subCountryAutoTrigger.unsubscribe();
        if (this._subStateAutoTrigger)
            this._subStateAutoTrigger.unsubscribe();
    }
    ngOnInit(): void {
        console.warn(this.owner);
        this._setMode();
        this._initializeForm(this.owner);
        this._loadCountries();
        this._loadStates();
    }

    private _filterCountries(match: any): Country[] {
        // ensure countries
        if (!this._countries)
            return [];

        // ensure string
        match += '';

        // initialize priority array
        let priority1Matches: Country[] = [];
        let priority2Matches: Country[] = [];
        let priority3Matches: Country[] = [];

        // iterate
        for (var i = 0; i < this._countries.length; i++) {
            // priority 1: description startsWith
            if (this._countries[i].description.toLowerCase().startsWith(match.toLowerCase()))
                priority1Matches.push(this._countries[i]);
            // priority 3: country startsWith
            else if (this._countries[i].country.toLowerCase().startsWith(match.toLowerCase()))
                priority3Matches.push(this._countries[i]);
            // priority 2: description contains
            else if (this._countries[i].description.toLowerCase().indexOf(match.toLowerCase()) > -1)
                priority2Matches.push(this._countries[i]);
        }

        // concat, return
        return priority1Matches.concat(priority2Matches).concat(priority3Matches);
    }
    private _filterStates(match: any): State[] {
        // ensure states
        if (!this._states)
            return [];

        // ensure string
        match += '';

        // initialize priority array
        let priority1Matches: State[] = [];
        let priority2Matches: State[] = [];
        let priority3Matches: State[] = [];

        // iterate
        for (var i = 0; i < this._states.length; i++) {
            // priority 1: description startsWith
            if (this._states[i].description.toLowerCase().startsWith(match.toLowerCase()))
                priority1Matches.push(this._states[i]);
            // priority 3: state startsWith
            else if (this._states[i].state.toLowerCase().startsWith(match.toLowerCase()))
                priority3Matches.push(this._states[i]);
            // priority 2: description contains
            else if (this._states[i].description.toLowerCase().indexOf(match.toLowerCase()) > -1)
                priority2Matches.push(this._states[i]);
        }

        // concat, return
        return priority1Matches.concat(priority2Matches).concat(priority3Matches);
    }

    private _initializeForm(owner: Owner): void {
        this.form = this.formBuilder.group({
            title: this.title,
            address: [owner.address, Validators.required],
            address2: owner.address2,
            city: [owner.city, Validators.required],
            country: [owner.country, Validators.compose([Validators.required, this._autocompleteValidator.bind(this)])],
            customerId: [owner.customerId, Validators.required],
            email: [owner.email, Validators.compose([Validators.required, Validators.email])],
            name: [owner.name, Validators.required],
            ownerId: owner.ownerId,
            phone: [owner.phone, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')])],
            state: [owner.state, Validators.compose([Validators.required, this._autocompleteValidator.bind(this)])],
            zip: [owner.zip, Validators.required]
        });
    }

    private _autocompleteValidator(control: AbstractControl) {
        return control.value && typeof control.value === 'string'
            ? { autocomplete: true }
            : null;
    }

    private _loadCountries(): void {
        this.filteredCountries = this.f.country.valueChanges
            .pipe(
                map(val => this._filterCountries(val)));

        this.orderService.countries$()
            .subscribe(response => {
                this._countries = response.countries;
                this.f.country.setValue(this._countries.filter(c => c.country === 'USA')[0])
            });
    }
    private _loadStates(): void {
        this.filteredStates = this.f.state.valueChanges
            .pipe(
                map(val => this._filterStates(val)));

        this.orderService.states$()
            .subscribe(response => {
                this._states = response.states;

                const assignedState = this._mode === 'Edit'
                    ? this._states.filter(s => s.state === this.owner.state)[0]
                    : null;
                if (assignedState)
                    this.form.patchValue({
                        state: assignedState
                    });
            });
    }

    private _setMode(): void {
        this._mode = this.data.mode;
        switch (this._mode) {
            case 'Create':
                this.submitText = 'Create';
                this.title = 'Create New Owner';
                break;
            case 'Edit':
                this.submitText = 'Save';
                this.title = 'Edit Owner';
                break;
        }
    }

    private _validateForm(): void {
        Object.keys(this.f).forEach(field => {
            const control = this.form.get(field);
            if (control instanceof FormControl)
                control.markAsTouched({ onlySelf: true });
        });
    }

    public displayCountryWith(country: Country): string | undefined {
        return country
            ? country.description
            : undefined;
    }
    public displayStateWith(state: State): string | undefined {
        return state
            ? state.description
            : undefined;
    }

    public getAddressError(): string {
        return this.f.address.hasError('required')
            ? FormValidators.required
            : FormValidators.none;
    }
    public getCountryError(): string {
        return this.f.country.hasError('autocomplete')
            ? FormValidators.autocomplete
            : this.f.country.hasError('required')
                ? FormValidators.required
                : FormValidators.none;
    }
    public getCityError(): string {
        return this.f.city.hasError('required')
            ? FormValidators.required
            : FormValidators.none;
    }
    public getEmailError(): string {
        return this.f.email.hasError('required')
            ? FormValidators.required
            : this.f.email.hasError('email')
                ? FormValidators.email
                : FormValidators.none;
    }
    public getNameError(): string {
        return this.f.name.hasError('required')
            ? FormValidators.required
            : FormValidators.none;
    }
    public getPhoneError(): string {
        return this.f.phone.hasError('minlength') || this.f.phone.hasError('maxlength') || this.f.phone.hasError('pattern')
            ? FormValidators.phone
            : this.f.phone.hasError('required')
                ? FormValidators.required
                : FormValidators.none;
    }
    public getStateError(): string {
        return this.f.state.hasError('autocomplete')
            ? FormValidators.autocomplete
            : this.f.state.hasError('required')
                ? FormValidators.required
                : FormValidators.none;
    }
    public getZipError(): string {
        return this.f.zip.hasError('required')
            ? FormValidators.required
            : FormValidators.none;
    }

    public async cancel(): Promise<void> {
        this.ownerDialogRef.close();
    }
    public async submit(): Promise<void> {
        this._validateForm();

        if (this.formSubmitted || !this.form.valid)
            return;

        this.utilityService.showLoader();
        this.formSubmitted = true;

        const request = new OwnerUpsertRequest().deserialize({
            address: this.f.address.value,
            address2: this.f.address2.value,
            city: this.f.city.value,
            country: this.f.country.value.country,
            customerId: this.f.customerId.value,
            email: this.f.email.value,
            phone: this.f.phone.value,
            ownerName: this.f.name.value,
            ownerId: this.f.ownerId.value,
            state: this.f.state.value.state,
            zip: this.f.zip.value
        });

        this.identityService.upsertOwner$(request)
            .subscribe(response => {
                if (response.status === ApiResponses.SUCCESS) {
                    Swal.fire('Success', AppAlerts.Owner.success, 'success');
                    this.utilityService.dismissLoader();
                    //request.ownerId = response.messages[0];
                    var owner = new Owner().deserialize({
                        address: this.f.address.value,
                        address2: this.f.address2.value,
                        city: this.f.city.value,
                        country: this.f.country.value.country,
                        customerId: this.f.customerId.value,
                        email: this.f.email.value,
                        name: this.f.name.value,
                        ownerId: response.messages[0],
                        phone: this.f.phone.value,
                        state: this.f.state.value.state,
                        zip: this.f.zip.value
                    });
                    this.ownerDialogRef.close(owner);
                } else
                    Swal.fire('Error', AppAlerts.Owner.failure, 'error');
                
                this.utilityService.dismissLoader();
                this.formSubmitted = false;
            });
        //this.utilityService.dismissLoader();
    }
}
