import { _deserializable } from '../_deserializable';
import { PdiAnswer, PdiHeader } from '.';

export class PdiForm extends _deserializable {

  public id: string;
  public pdiHeaderId: string;
  public header: PdiHeader;

  public comments: string;
  public dealerAccount: string;
  public model: string;
  public personCompleting: string;
  public productLine: string;
  public serialNumber: string;
  public warrantyCode: string;

  public deliveryDate: Date;
  public inspectionDate: Date;

  public answers: PdiAnswer[];

}
