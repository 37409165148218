import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  LocalRoutes
} from './constants';

import {
  AnonGuard,
  AuthGuard
} from './route-guards';

import {
  AdminComponent,
  DashboardComponent,
  HomeComponent,
  OrderEntryComponent,
  OrderStatusComponent,
  PdiManagementComponent,
  ProductAvailabilityComponent,
  ProductRegistrationComponent,
  RoleManagementComponent,
  SignOutComponent,
  UserManagementComponent,
  WarrantyManagementComponent
} from './components/pages';

const routes: Routes = [
  { path: LocalRoutes.admin, component: AdminComponent, canActivate: [ AuthGuard ], data: { requiredRoles: [ 'Admin' ]} },
  { path: LocalRoutes.dashboard, component: DashboardComponent, canActivate: [ AuthGuard ] },
  { path: LocalRoutes.home, component: HomeComponent, canActivate: [ AnonGuard ] },
  { path: LocalRoutes.orderEntry, component: OrderEntryComponent, canActivate: [ AuthGuard ] },
  { path: LocalRoutes.orderStatus, component: OrderStatusComponent, canActivate: [ AuthGuard ] },
  { path: LocalRoutes.pdiManagement, component: PdiManagementComponent, canActivate: [ AuthGuard ], data: { requiredRoles: [ 'Admin' ]} },
  { path: LocalRoutes.productAvailability, component: ProductAvailabilityComponent, canActivate: [ AuthGuard ] },
  { path: LocalRoutes.productRegistration, component: ProductRegistrationComponent, canActivate: [ AuthGuard ] },
  { path: LocalRoutes.roleManagement, component: RoleManagementComponent, canActivate: [ AuthGuard ], data: { requiredRoles: [ 'Admin' ]} },
  { path: LocalRoutes.signOut, component: SignOutComponent, canActivate: [ AuthGuard ] },
  { path: LocalRoutes.userManagement, component: UserManagementComponent, canActivate: [ AuthGuard ], data: { requiredRoles: [ 'Admin' ]} },
  { path: LocalRoutes.warrantyManagement, component: WarrantyManagementComponent, canActivate: [ AuthGuard ] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
