import { _deserializable } from '../_deserializable';

export class ProductRegistrationsRequest extends _deserializable {
    
    public customerName: string;
    public customerNumber: string;
    public deliveryDateFrom: Date;
    public deliveryDateTo: Date;
    public description: string;
    public ownerName: string;
    public part: string;
    public serialNumber: string;

}
