
export const ApiRoutes = {

  // Content endpoints
  links: 'content/links',
  texttokens: 'content/texttokens',

  // Identity endpoints
  changestatus: 'identity/changestatus',
  customers: 'identity/customers',
  importUsers: 'identity/importusers',
  register: 'identity/register',
  resetPassword: 'identity/resetpassword',
  roles: 'identity/roles',
  sendReset: 'identity/sendreset',
  signin: 'identity/signin',
  signout: 'identity/signout',
  update: 'identity/update',
  users: 'identity/users',
  passwordResetToken: 'identity/passwordresettoken',
  owner: 'identity/owner',
  

  // Order endpoints
  addshipto: 'order/addshipto',
  countries: 'order/countries',
  itemavailability: 'order/itemavailability',
  items: 'order/items',
  minreqdate: 'order/minreqdate',
  ordersummaries: 'order/ordersummaries',
  pricelists: 'order/pricelists',
  pdfinvoice: 'order/pdfinvoice',
  pdfsalesorder: 'order/pdfsalesorder',
  shipto: 'order/shipto',
  shipvia: 'order/shipvia',
  states: 'order/states',
  submitorder: 'order/submitorder',

  //Product endpoints
  eligible: 'product/eligible',
  pdiform: 'product/pdiform/',
  pdiheaders: 'product/pdiheaders',
  productlines: 'product/productlines',
  productregistrations: 'product/registrations',
  registerproduct: 'product/register',

  // System endpoints
  apienv: 'system/apienv',
  sendWelcomeEmails: 'system/sendwelcomeemails',
  ping: 'system/ping',
  qadroot: 'system/qadroot'
  
}
