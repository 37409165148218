import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ApiResponses,
  ApiRoutes
} from 'src/app/constants';

import {
  AjaxService,
  LoggingService
} from '.';

import {
  PdiForm,
  PdiHeader
} from 'src/app/domain/models';

import {
  BaseResponse,
  EligibleItemsRequest,
  EligibleItemsResponse,
  ProductLineResponse,
  ProductRegistrationsRequest,
  ProductRegistrationsResponse,
  RegisterProductRequest
} from 'src/app/domain/requestresponseobjects';

@Injectable()
export class ProductService {

  constructor(
    private ajaxService: AjaxService,
    private loggingService: LoggingService) {
    this.loggingService.warn('ProductService initialized.')
  }

  public eligible$(request: EligibleItemsRequest): Observable<EligibleItemsResponse> {
    this.loggingService.info('Fetching Items...');

    return this.ajaxService
      .POST<EligibleItemsResponse>(
        ApiRoutes.eligible,
        request,
        null)
      .pipe(
        map(response => {
          this.loggingService.warn('EligibleItemsResponse');
          this.loggingService.warn(response);
          this.loggingService.info('Item fetch successful.');

          // return
          return response;
        }));
  }

  public pdiForm$(serialNumber: string): Observable<PdiForm> {
    this.loggingService.info(`Fetching PdiForm (Serial ${serialNumber})...`);

    return this.ajaxService
      .GET<PdiForm>(
        `${ApiRoutes.pdiform}${serialNumber}`,
        null
      )
      .pipe(
        map(response => {
          this.loggingService.warn('PdiForm');
          this.loggingService.warn(response);
          this.loggingService.warn('PdiForm fetch successfull');

          // return
          return response;
        }));
  }

  public pdiHeaders$(): Observable<PdiHeader[]> {
    this.loggingService.info('Fetching PdiHeaders...');

    return this.ajaxService
      .GET<PdiHeader[]>(
        ApiRoutes.pdiheaders,
        [])
      .pipe(
        map(response => {
          this.loggingService.warn('PdiHeaders');
          this.loggingService.warn(response);
          this.loggingService.info('PdiHeaders fetch successful.');

          // return
          return response;
      }));
  }

  public productLines$(): Observable<ProductLineResponse> {
    this.loggingService.info('Fetching ProductLines...');

    return this.ajaxService
      .GET<ProductLineResponse>(
        ApiRoutes.productlines,
        null)
      .pipe(
        map(response => {
          this.loggingService.warn('ProductLineResponse');
          this.loggingService.warn(response);
          this.loggingService.info('ProductLine fetch successful.');

          // return
          return response;
      }))
  }

  public productRegistrations$(request: ProductRegistrationsRequest): Observable<ProductRegistrationsResponse> {
    this.loggingService.info('Fetching Product Registrations...');

    return this.ajaxService
      .POST<ProductRegistrationsResponse>(
        ApiRoutes.productregistrations,
        request,
        <ProductRegistrationsResponse>{
          status: ApiResponses.FAILURE,
          messages: [ApiResponses.COMMON_ERROR_MESSAGE],
          productRegistrations: []
        })
      .pipe(
        map(response => {
          this.loggingService.warn('ProductRegistrationResponse');
          this.loggingService.warn(response);

          if (response.status === ApiResponses.SUCCESS)
            this.loggingService.info('Product Registrations fetch successful.');
          else
            this.loggingService.error('Product Registration fetch failed.');
          
          return response;
        }));
  }

  public registerProduct(request: RegisterProductRequest): Observable<BaseResponse> {
    this.loggingService.info('Registering Product...');

    return this.ajaxService
      .POST<BaseResponse>(
        ApiRoutes.registerproduct,
        request,
        new BaseResponse().deserialize({
          status: ApiResponses.FAILURE,
          messages: [ApiResponses.COMMON_ERROR_MESSAGE]
        }))
      .pipe(
        map(response => {
          this.loggingService.warn('Product Registration Response');
          this.loggingService.warn(response);
          if (response.status === ApiResponses.SUCCESS) {
            this.loggingService.info("Product Registration successful.");
          } else {
            this.loggingService.error('Product Registration failed.');
          }
          return response;
        }));
  }

}
