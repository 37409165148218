
<mat-card>

  <mat-card-title>
    {{ title }}
  </mat-card-title>

  <mat-card-content>
    <div *ngIf="displayRegistrationMessage" class="aw-message">
      <div>Fill out the form below and submit; our staff will review the account for approval.</div>
      <div>You will be notified when this process is complete.</div>
    </div>

    <ng-container *ngIf="!displayRegistrationMessage">
      <hr/>
      <div class="content-right">
        Current Status: <strong class="mar-r">{{currentStatus}}</strong>
        <button mat-flat-button color="primary" (click)="editStatus(statusText)">{{ statusText }}</button>
        <button *ngIf="statusText2" mat-flat-button color="primary" (click)="editStatus(statusText2)">{{ statusText2 }}</button>
      </div>
    </ng-container>
  
    <form [formGroup]="form">

      <hr />
      <h3 class="section-title">Personal Information:</h3>

      <mat-form-field appearance="fill" class="col-2-2-1">
        <mat-label>First Name:</mat-label>
        <input matInput formControlName="firstName" type="text" />
        <mat-error *ngIf="f.firstName.invalid">{{getFirstNameError()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-2-2-1">
        <mat-label>Last Name:</mat-label>
        <input matInput formControlName="lastName" type="text" />
        <mat-error *ngIf="f.lastName.invalid">{{getLastNameError()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-2-2-1">
        <mat-label>Email Address:</mat-label>
        <input matInput formControlName="email" type="email" />
        <mat-error *ngIf="f.email.invalid">{{getEmailError()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-2-2-1">
        <mat-label>Phone:</mat-label>
        <input matInput formControlName="phone" type="text" autocomplete="new-password" />
        <mat-error *ngIf="f.phone.invalid">{{getPhoneError()}}</mat-error>
        <mat-hint>10-digits only, no characters or spaces</mat-hint>
      </mat-form-field>
  
      <ng-container *ngIf="isNewUser">
        <mat-form-field appearance="fill" class="col-2-2-1">
          <mat-label>Create a Password:</mat-label>
          <input matInput formControlName="password" type="password" autocomplete="new-password" />
          <mat-error *ngIf="f.password.invalid">{{getPasswordError()}}</mat-error>
        </mat-form-field>
      </ng-container>
  
      <ng-container *ngIf="isNewUser">
        <mat-form-field appearance="fill" class="col-2-2-1">
          <mat-label>Re-type New Password:</mat-label>
          <input matInput formControlName="confirmPassword" type="password" autocomplete="new-password" />
          <mat-error *ngIf="f.confirmPassword.invalid">{{getConfirmPasswordError()}}</mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="isNewUser">
         <div class="mat-small">*Password should be a minimum 10 characters, with one uppercase and one lowercase letter, one number, and one special character.</div>
      </ng-container>
      

      <hr />
      <h3 class="section-title">Company Information:</h3>

      <mat-form-field appearance="fill" class="col-2-2-1">
        <mat-label>Company:</mat-label>
        <input matInput formControlName="company" type="text" />
        <mat-error *ngIf="f.company.invalid">{{getCompanyError()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-2-2-1">
        <mat-label>Job Title:</mat-label>
        <input matInput formControlName="title" type="text" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>Address Line 1:</mat-label>
        <input matInput formControlName="line1" type="text" />
        <mat-error *ngIf="f.line1.invalid">{{getLine1Error()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>Address Line 2:</mat-label>
        <input matInput formControlName="line2" type="text" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>Address Line 3:</mat-label>
        <input matInput formControlName="line3" type="text" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4-2-1">
        <mat-label>City:</mat-label>
        <input matInput formControlName="city" type="text" />
        <mat-error *ngIf="f.city.invalid">{{getCityError()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4-2-1">
        <mat-label>State/Province:</mat-label>
        <input #stateAutoInput matInput placeholder="Select a State/Province..." formControlName="state" [matAutocomplete]="stateAuto" type="text" />
        <mat-error *ngIf="f.state.invalid">{{getStateError()}}</mat-error>
        <mat-autocomplete #stateAuto="matAutocomplete" [displayWith]="displayStateWith" autoActiveFirstOption>
          <mat-option *ngFor="let s of filteredStates | async" [value]="s">{{s.description}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4-2-1">
        <mat-label>Zip/Postal:</mat-label>
        <input matInput formControlName="zip" type="text" />
        <mat-error *ngIf="f.zip.invalid">{{getZipError()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4-2-1">
        <mat-label>Country:</mat-label>
        <input #countryAutoInput matInput placeholder="Select a Country..." formControlName="country" [matAutocomplete]="countryAuto" type="text" />
        <mat-error *ngIf="f.country.invalid">{{getCountryError()}}</mat-error>
        <mat-autocomplete #countryAuto="matAutocomplete" [displayWith]="displayCountryWith" autoActiveFirstOption>
          <mat-option *ngFor="let c of filteredCountries | async" [value]="c">{{c.description}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <hr />
      <h3 class="section-title">Art's Way Account:</h3>

      <ng-container *ngIf="isRegistration">
        <mat-form-field appearance="fill" class="infix-sm  col-4-2-1">
          <mat-label>I have existing Art's Way account(s):</mat-label>
          <input matInput formControlName="existingAccount" type="text" [hidden]="true" />
          <mat-checkbox formControlName="existingAccount">Yes</mat-checkbox>
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="col-43-1-1" *ngIf="f.existingAccount.value">
          <mat-label>Existing Account Number(s):</mat-label>
          <input matInput formControlName="accountNumbers" type="text" />
          <mat-hint>Separate multiple account numbers with commas</mat-hint>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="!isRegistration">

        <mat-form-field class="col-4-2-1">
          <mat-label>Role:</mat-label>
          <mat-select (selectionChange)="onRoleChange($event)" formControlName="role">
            <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.role.invalid">{{getRoleError()}}</mat-error>
        </mat-form-field>

        <ng-container *ngIf="isDealer">

          <mat-form-field appearance="fill" class="col-4-2-1">
            <mat-label>Assign User to Customer Account(s)</mat-label>
            <input #customerAutoInput matInput placeholder="Type to Search..." formControlName="selectedCustomer" [matAutocomplete]="customerAuto" type="text" />
            <mat-autocomplete #customerAuto="matAutocomplete" [displayWith]="displayCustomerWith">
              <mat-option disabled><div class="ac-span ac-span-header ac-span-20">CUST #</div><div class="ac-span ac-span-header ac-span-50">NAME</div><div class="ac-span ac-span-header ac-span-30">LOCATION</div></mat-option>
              <mat-option *ngFor="let c of filteredCustomers | async" [value]="c"><div class="ac-span ac-span-20">{{c.customer}}</div><div class="ac-span ac-span-50">{{c.name}}</div><div class="ac-span ac-span-30">{{c.line1}} {{c.city}}, {{c.state}}</div></mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="fill" class="col-2-1-1">
            <mat-label>User-Entered Account Number(s):</mat-label>
            <input matInput formControlName="accountNumbers" type="text" />
            <mat-hint>Separate multiple account numbers with commas</mat-hint>
          </mat-form-field>

          <ag-grid-angular
            class="ag-theme-alpine"
            [columnDefs]="columnDefs"
            domLayout="autoHeight"
            [frameworkComponents]="frameworkComponents"
            (gridReady)="onGridReady($event)"
            [rowData]="gridCustomers"
            style="width: 100%;"></ag-grid-angular>

        </ng-container>

      </ng-container>

      <hr />
  
      <div class="content-right">
        <button mat-flat-button color="primary" (click)="submit()">{{ submitText }}</button>
      </div>
  
    </form>
    
    <span class="aw-underlink">
      <a (click)="cancel()">Cancel</a>
    </span>
    
  </mat-card-content>
  
</mat-card>

<!-- <form [formGroup]="registerForm">

  <div>
    <label>Domain:</label>
    <select formControlName="domain">
      <option value="Art's Way Manufacturing">Art's Way Manufacturing</option>
      <option value="American Carbide Tool">American Carbide Tool</option>
    </select>
  </div>

</form> -->
