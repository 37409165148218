import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { LoggingService } from './logging.service';
import { UtilityService } from './utility.service';

@Injectable()
export class AjaxService {

  private readonly _apiBaseUri: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private utilityService: UtilityService) {
      this.loggingService.warn('AjaxService initialized.');
      this._apiBaseUri = this.utilityService.currentEnvironment === 'Development'
        ? environment.apiDevUri
        : environment.apiBaseUri;
    }

  public DELETE<T>(url: string, defaultResponse: T): Observable<T> {
    return this.http
      .delete<T>(url);
  }

  public GET<T>(url: string, defaultResponse: T, headers?: HttpHeaders): Observable<T> {
    this.loggingService.info({
      ajaxServiceMethod: 'GET',
      endpoint: url
    });

    return this.GETwithoutBase<T>(this._apiBaseUri + url, defaultResponse, headers);
  }

  public GETwithoutBase<T>(url: string, defaultResponse: T, headers?: HttpHeaders): Observable<T> {
    // prep headers
    const headersToSend = headers
    ? headers
    : new HttpHeaders({
        'Content-Type': 'application/json'
      });

    // call external resource
    return this.http
      .get<T>(
        url,
        { headers: headersToSend }
      )
      .pipe(
        // handle error(s) - return default
        catchError((e) => {
          this.loggingService.error(e);
          return of(defaultResponse)
        }),
        finalize(() => {
        })
      );
  }

  public POST<T>(url: string, payload: any, defaultResponse: T, headers?: HttpHeaders): Observable<T> {
    this.loggingService.info({
      ajaxServiceMethod: 'POST',
      endpoint: url,
      payload: payload
    });
    
    return this.POSTwithoutBase<T>(this._apiBaseUri + url, payload, defaultResponse, headers);
  }

  public POSTwithoutBase<T>(url: string, payload: any, defaultResponse: T, headers?: HttpHeaders): Observable<T> {
    // prep headers
    const headersToSend = headers
    ? headers
    : new HttpHeaders({
        'Content-Type': 'application/json'
      });

    // call external resource
    return this.http
      .post<T>(
        url,
        payload,
        { headers: headersToSend }
      )
      .pipe(
        // handle error(s) - return default
        catchError((e) => {
          this.loggingService.error(e);
          return of(defaultResponse)
        }),
        finalize(() => {
        })
      );
  }

}
