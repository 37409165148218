
export const AppAlerts = {

  Home: {

    failure: 'There was a problem. Ensure you are using a valid email and password. Please try again, and contact support if the problem persists.'

  },

  OrderEntry: {

    failure: 'There was a problem submitting the order.<br/>Please try again, and contact support if the problem persists.',
    success: (SoNumber) => { return `Your order has been submitted.<br/>The Sales Order number for this order is:<br/><br/><b>${SoNumber}</b><br/><br/>Please retain this for your records.<br/><br/>Thank you.`; }

  },

  Owner: {
    failure: 'There was a problem creating the owner record.<br/>Please try again, and contact support if the problem persists.',
    success: 'Owner record successfully created.'
  },

  ProductRegistration: {
    failure: 'There was a problem registering the product.<br/>Please try again, and contact support if the problem persists.',
    success: 'Product successfully registered.'
  },
  
  Register: {

    failure: 'There was a problem with the request. Please try again, and make sure your new password meets the following security requirements:<br/><ul><li>Must contain at least one uppercase letter</li><li>Must contain at least one lowercase letter</li><li>Must contain at least one number</li><li>Must contain at least one non-alphanumeric character</li><li>Must contain at least 5 unique characters</li><li>Must contain at least 10 total characters</li></ul><br/>Please contact support if the problem persists.',
    success: 'Thank you. Your request has been submitted.<br/><br/>Please check your email for further information.'

  },

  ResetPassword: {

    failure: 'There was a problem with the request. Please try again, and make sure your new password meets the following security requirements:<br/><ul><li>Must contain at least one uppercase letter</li><li>Must contain at least one lowercase letter</li><li>Must contain at least one number</li><li>Must contain at least one non-alphanumeric character</li><li>Must contain at least 5 unique characters</li><li>Must contain at least 10 total characters</li></ul><br/>Please contact support if the problem persists.',
    success: 'Your password has been successfully reset.'

  },
  ResetPasswordToken: {

    failure: 'There was a problem with the request. Please request a new password reset from the login page to try again.<br/><br/>Please contact support if the problem persists.',
  },

  SendReset: {

    success: 'Your request has been processed. If the submitted email address is in our system, it will receive an email with instructions on resetting the password.'

  },

  Save:{
    failure: 'There was a problem with the request. Please review the submitted changes and try again. <br/>Please contact support if the problem persists.',
    success: 'Your changes have been saved.'
  }
  
}
