import { BaseResponse } from './base.response';
import { ShipTo } from '../models';

export class ShipToResponse extends BaseResponse {

  public shipTos: ShipTo[];

}

// TODO: needs to bring in base response
