import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
  FormValidators
} from 'src/app/constants';

import {
  PdiHeader,
  PdiQuestion,
  ProductLine
} from 'src/app/domain/models';

import {
  ProductLineResponse
} from 'src/app/domain/requestresponseobjects';

import {
  IdentityService,
  ProductService,
  UtilityService
} from 'src/app/services';

@Component({
  selector: 'app-pdi',
  templateUrl: './pdi.dialog.html',
  styleUrls: ['./pdi.dialog.scss']
})
export class PdiDialog implements AfterViewInit, OnDestroy, OnInit {

  public action: string;
  public form: FormGroup;
  public pdiHeader: PdiHeader;
  public productLines: ProductLine[];

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<PdiDialog>,
    private formBuilder: FormBuilder,
    private productService: ProductService) {
      this.action = data.action;
      this.pdiHeader = data.header;

      this.form = this.formBuilder.group({
        formTitle: [ this.pdiHeader.formTitle, Validators.required ]
      });
    }

  ngAfterViewInit() {
  }
  ngOnDestroy() {
  }
  ngOnInit() {
    this._loadProductLines();
  }

  private async _loadProductLines(): Promise<void> {
    this.productService.productLines$()
      .subscribe((response: ProductLineResponse) => {
        this.productLines = response.productLines.sort((a: ProductLine, b: ProductLine) => {
          if (a.productLine < b.productLine)
            return -1;
          if (a.productLine > b.productLine)
            return 1;
          return 0;
        });
      })
  }

  private _validateForm(): void {
    Object.keys(this.f).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl)
        control.markAsTouched({ onlySelf: true });
    });
  }

  public async cancel(): Promise<void> {
    this.dialogRef.close();
  }

  public getFormTitleError(): string {
    return this.f.formTitle.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }

  public async submit(): Promise<void> {
    this._validateForm();

    if (!this.form.valid)
      return;

    console.log(this.form.value);
    
    this.dialogRef.close(this.form.value);
  }

}
