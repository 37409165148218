import { _deserializable } from '../_deserializable';

export class OwnerUpsertRequest extends _deserializable {

    public address: string;
    public address2: string;
    public city: string;
    public country: string;
    public customerId: string;
    public email: string;
    public phone: string;
    public ownerName: string;
    public ownerId: string;
    public state: string;
    public zip: string;
}