
<header>

	<div>
 	  <img class="aw-headerlogo" src="../../../../assets/images/Logo.png"/>
  </div>

  <div class="aw-header-right">

    <a *ngIf="isMobile"><mat-icon class="toggle" (click)="toggleLinkMenu()">menu</mat-icon></a>
    <a *ngIf="isMobile"><mat-icon class="toggle" (click)="toggleUserMenu()">person</mat-icon></a>

    <ul [hidden]="!showUserMenu">
      <li>
        <span>Welcome {{currentUser?.firstName}} {{currentUser?.lastName}}</span>
      </li>
      <li>
        <a (click)="openResetPasswordModel()">Change Password</a>
      </li>
      <li>
        <a [routerLink]="['/','signout']">Sign Out</a>
      </li>
    </ul>

    <ul [hidden]="!showLinkMenu">
      <li *ngFor="let link of links">
        <a target="_blank" [href]="link.href">{{ link.text }}</a>
      </li>
    </ul>

  </div>

</header>
