import { _deserializable } from '../_deserializable';

export class EligibleItem extends _deserializable {

    public description: string;
    public model: string;
    public part: string;
    public productLine: string;
    public serial: string;
    public warranty: string;

}
