import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import {
  ApiResponses,
  AppAlerts,
  FormValidators
} from 'src/app/constants';

import { User } from 'src/app/domain/models';

import {
  BaseResponse,
  SigninRequest,
  SignInResponse
} from 'src/app/domain/requestresponseobjects';

import {
  RegisterDialog,
  ResetPasswordDialog,
  SendResetDialog
} from 'src/app/components/dialogs';

import {
  ContentService,
  IdentityService,
  UtilityService
} from 'src/app/services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public companyPhone: string;
  public formSubmitted: boolean = false;
  public form: FormGroup;
  public token: string;

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    private contentService: ContentService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private identityService: IdentityService,
    private route: ActivatedRoute,
    private utilityService: UtilityService) {
      this.form = this.formBuilder.group({
        email: ['', Validators.compose([Validators.required, Validators.email])],
        password: ['', Validators.compose([Validators.required])]
      });
      
      
    this.contentService.texttokens$()
      .subscribe(tokens => {
        console.log(tokens);
        this.companyPhone = tokens.filter(t => t.name === 'CompanyPhoneNumber')[0].text;
      });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['token'] != undefined){
        this.token = params['token'];
        this.identityService.ValidatePasswordResetToken(this.token)
          .subscribe(async (response: BaseResponse) => {
            if (response.status === ApiResponses.SUCCESS)
              this.openResetPasswordDialog(this.token);
            else
            Swal.fire('Error', AppAlerts.ResetPasswordToken.failure, 'error');
        });     
      }
    });
  }

  private _validateForm(): void {
    Object.keys(this.f).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl)
        control.markAsTouched({ onlySelf: true });
    });
  }

  public getEmailError(): string {
    return this.f.email.hasError('required')
      ? FormValidators.required
      : this.f.email.hasError('email')
        ? FormValidators.email
        : FormValidators.none;
  }
  public getPasswordError(): string {
    return this.f.password.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }

  public async openResetPasswordDialog(token: string) {
    this.dialog.open(
      ResetPasswordDialog,
      {
        data: {
          token: token
        }
      });
  }

  public async openResetModal(): Promise<void> {
    let ref = this.dialog.open(
      SendResetDialog,
      {
        //width: '100vw'
      });
  }
  public async openRegisterModal(): Promise<void> {
    let ref = this.dialog.open(
      RegisterDialog,
      {
        data: {
          user: new User(),
          mode: 'Register'
        }
      });

    ref.afterClosed()
      .subscribe(result => {
      });
  }

  public async submit(): Promise<void> {
    this._validateForm();
    
    if (this.formSubmitted || !this.form.valid)
      return;

    this.utilityService.showLoader();
    this.formSubmitted = true;

    const request = new SigninRequest()
      .deserialize({
        email: this.form.value.email,
        password: this.form.value.password
      });

    this.identityService.signin(request)
      .subscribe(async (response: SignInResponse) => {
        if (response.status === ApiResponses.SUCCESS) {
          this.utilityService.dismissLoader();
          this.form.reset({ userId: '', password: '' });
          this.utilityService.redirectToDashboard();
        }
        else {
          this.utilityService.dismissLoader();
          Swal.fire('Error', AppAlerts.Home.failure, 'error');
        }

        this.formSubmitted = false;
      });
  }

}
