import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';

import {
  ApiResponses
} from 'src/app/constants';

import {
  IKeyValuePair,
  UserStatus
} from 'src/app/domain/kvp';

import {
  User
} from 'src/app/domain/models';

import {
  UsersRequest,
  UsersResponse
} from 'src/app/domain/requestresponseobjects';

import {
  RegisterDialog
} from 'src/app/components/dialogs';

import {
  IdentityService,
  UtilityService
} from 'src/app/services';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnDestroy, OnInit {

  private _gridApi: any;
  private _subSelectedStatus: Subscription;

  public columnDefs = [];
  public filtersOpened: boolean = false;
  public form: FormGroup;
  public isDesktop: boolean = false;
  public paginationPageSize: number = 25;
  public users: User[];
  public userStatuses: IKeyValuePair<number>[] = UserStatus.collection;

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private identityService: IdentityService,
    private utilityService: UtilityService) {
      this.form = this.formBuilder.group({
        selectedStatus: 1
      });
    }

  ngOnDestroy() {
    if (this._subSelectedStatus)
      this._subSelectedStatus.unsubscribe();
  }
  ngOnInit() {
    this.filtersOpened = this.isDesktop = this.deviceDetectorService.isDesktop();
    this._setColumnDefs();

    this._subSelectedStatus = this.f.selectedStatus.valueChanges
      .subscribe((val) => {
        this._loadUsers();
      });
  }

  private async _loadUsers(): Promise<void> {
    this._gridApi.showLoadingOverlay();

    this.identityService.users$(new UsersRequest().deserialize({
      status: Number(this.f.selectedStatus.value)
    }))
      .subscribe(async (response: UsersResponse) => {
        this._gridApi.hideOverlay();
        this.users = response.users;
      });

  }

  private _openRegisterModal(user: User, mode: string): void {
    let ref = this.dialog.open(
      RegisterDialog,
      {
        data: {
          user: user,
          mode: mode
        },
        panelClass: 'margin-dialog'
      });

    ref.afterClosed()
      .subscribe((result: User) => {
        //if (result)
          this._loadUsers();
      });
  }

  private _setColumnDefs(): void {
    this.columnDefs = [
      this.utilityService.gridColumnDef('name', 'Name', null, true, 'cell-link'),
      this.utilityService.gridColumnDef('email', 'Email Address', null),
      this.utilityService.gridColumnDef('company', 'Company', null),
      this.utilityService.gridColumnDef('city', 'City', null),
      this.utilityService.gridColumnDef('state', 'State / Province', null),
      this.utilityService.gridColumnDef('zip', 'Zip / Postal Code', null)
    ];
  }

  public async addNew(): Promise<void> {
    this._openRegisterModal(new User(), 'Add');
  }

  public async export(): Promise<void> {
    alert('export to excel');
  }

  public async filtersToggle(): Promise<void> {
    this.filtersOpened = !this.filtersOpened;
  }

  public async onCellClicked(ev: any): Promise<void> {
    if (ev.colDef.field === 'name' && ev.value)
      this._openRegisterModal(<User>ev.data, 'Edit');
  }
  public onGridReady(ev: any): void {
    this._gridApi = ev.api;

    this._loadUsers();
  }
  public async onPaginationPageSizeChange(): Promise<void> {
    this._gridApi.paginationSetPageSize(this.paginationPageSize);
  }

  public paginationNumberFormatter(params: any): string {
    return `[${params.value}]`;
  }

}
