
<mat-card class="content">

  <mat-card-title>
    Warranty Management
  </mat-card-title>

  <mat-card-content>
    <p>Due to unexpected vendor changes this portal is running with limited functionality. Additional features will be rolled out as they become available over the coming weeks.</p>
    <p>The Warranty Management functionality is expected to be implemented in the coming weeks.</p>
    <p>Please contact our sales and support staff for any information at <span class="phone">{{companyPhone}}</span> or <a href="mailto:{{companyEmail}}">{{companyEmail}}</a>.</p>
  </mat-card-content>

</mat-card>
