
export const LocalRoutes = {

  admin: 'admin',
  dashboard: 'dashboard',
  home: '',
  orderEntry: 'orderentry',
  orderStatus: 'orderstatus',
  pdiManagement: 'pdimanagement',
  productAvailability: 'productavailability',
  productRegistration: 'productregistration',
  roleManagement: 'rolemanagement',
  signOut: 'signout',
  userManagement: 'usermanagement',
  warrantyManagement: 'warrantymanagement'
  
}
