import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

import {
  LinkLocation
} from 'src/app/domain/kvp';

import {
  Link,
  User
} from 'src/app/domain/models';

import {
  ContentService,
  IdentityService,
  UtilityService
} from 'src/app/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {

  private _routerSub: Subscription;
  private _userSub: Subscription;

  public currentUser: User;
  public deviceIsMobile: boolean = false;
  public isAdmin: boolean = false;
  public linksFooter: Link[] = [];
  public linksHeader: Link[] = [];
  public navLocked: boolean = true;
  public navMode = 'side';
  public navOpened: boolean = true;
  public title = 'Arts Way Dealer Portal';

  constructor(
    private contentService: ContentService,
    private deviceDetectorService: DeviceDetectorService,
    private identityService: IdentityService,
    private router: Router,
    private utilityService: UtilityService) { }

    ngOnDestroy() {
      if (this._routerSub)
        this._routerSub.unsubscribe();
      if (this._userSub)
        this._userSub.unsubscribe();
    }
  
    ngOnInit() {
      console.log('AppComponent intialized.');

      if (!this.deviceDetectorService.isDesktop()) {
        this.deviceIsMobile = true;
        this.navLocked = false;
        this.navMode = 'over';
        this.navOpened = false;
      }

      this._routerSub = this.router.events.subscribe((ev: any) => {
        if (ev instanceof NavigationEnd && !this.navLocked)
            this.navOpened = false;
      });

      this._userSub = this.identityService.currentUser$
        .subscribe(user => {
          Promise.resolve(null).then(() => {
            this.currentUser = user;
            this.isAdmin = this.identityService.userIsInRole('Admin');
            if (this.currentUser)
              this._loadLinks();
          });
        });

    }

    public async lockChanged(): Promise<void> {
      this.navOpened = this.navLocked;
      this.navMode = this.navLocked
        ? 'side'
        : 'over';
    }
    public async navClose(): Promise<void> {
      this.navOpened = false;
    }
    public async navOpen(): Promise<void> {
      this.navOpened = true;
    }

    private _loadLinks(): void {
      this.contentService.links$()
        .subscribe(links => {
          this.linksFooter = links
            .filter(l => l.location === LinkLocation.value('Footer'));
          this.linksHeader = links
            .filter(l => l.location === LinkLocation.value('Header'));
        })
    }

}
