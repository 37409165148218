
<mat-card>

  <mat-card-content>
    
    <img *ngIf="!currentUser" class="aw-logo" src="../../../../assets/images/Logo.png"/>
    <h2 class="outer-heading">{{pageTitle}}</h2>
      
    <div class="aw-message">
      <div>To {{action}} your password:</div>
      <div>Please enter your email address and new password.</div>
    </div>
  
    <form [formGroup]="form">

      <mat-form-field appearance="fill" class="col-1-1-1">
        <mat-label>Email Address:</mat-label>
        <input matInput formControlName="email" type="email" />
        <mat-error *ngIf="f.email.invalid">{{getEmailError()}}</mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="fill" class="col-2-2-1">
        <mat-label>New Password:</mat-label>
        <input matInput formControlName="password" type="password" />
        <mat-error *ngIf="f.password.invalid">{{getPasswordError()}}</mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="fill" class="col-2-2-1">
        <mat-label>Re-type New Password:</mat-label>
        <input matInput formControlName="confirmPassword" type="password" />
        <mat-error *ngIf="f.confirmPassword.invalid">{{getConfirmPasswordError()}}</mat-error>
      </mat-form-field>

      <ng-container>
        <div class="mat-small">*Password should be a minimum 10 characters, with one uppercase and one lowercase letter, one number, and one special character.</div>
     </ng-container>
  
      <div class="content-right">
        <button mat-flat-button color="primary" *ngIf="!responseSuccess" (click)="submit()">Reset</button>
        <button mat-flat-button color="primary" (click)="cancel()">Cancel</button>
      </div>
  
    </form>
    
  </mat-card-content>
  
</mat-card>

