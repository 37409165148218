import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import Swal from 'sweetalert2';

import {
  User
} from 'src/app/domain/models';

import {
  ApiResponses,
  AppAlerts,
  FormValidators
} from 'src/app/constants';

import {
  ResetPasswordRequest
} from 'src/app/domain/requestresponseobjects';

import {
  IdentityService
} from 'src/app/services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.dialog.html',
  styleUrls: ['./reset-password.dialog.scss']
})
export class ResetPasswordDialog implements OnDestroy, OnInit {

  private _userSub: Subscription;

  public action: string = 'reset';
  public currentUser: User;
  public formSubmitted: boolean = false;
  public form: FormGroup = new FormGroup({});
  public pageTitle: string = 'Reset Your Password';
  public passwordResetToken: string;
  public responseMessage: string;
  public responseSuccess: boolean = false;

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ResetPasswordDialog>,
    private identityService: IdentityService) {
      this.form.addControl('email', new FormControl('', Validators.compose([Validators.required, Validators.email])));
      this.form.addControl('password', new FormControl('', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(20), Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$') ])));
      this.form.addControl('confirmPassword', new FormControl('', Validators.compose([Validators.required, this._exactMatchValidator.bind(this)])));

      this.passwordResetToken = data.token;
    }

  ngOnDestroy() {
    this._userSub.unsubscribe();
  }

  ngOnInit() {
    this._userSub = this.identityService.currentUser$
      .subscribe(user => {
        if (user) {
          this.currentUser = user;
          this.form.reset({
            email: this.currentUser.email
          });
          this.action = 'change';
          this.pageTitle = 'Change Your Password';
        }
        else {
          this.action = 'reset';
          this.pageTitle = 'Reset Your Password';
        }
      });
  }

  private _exactMatchValidator(control: FormControl) {
    return control.value === this.f.password.value
      ? null
      : { exactMatch: true };
  }

  private _validateForm(): void {
    Object.keys(this.f).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl)
        control.markAsTouched({ onlySelf: true });
    });
  }

  public getConfirmPasswordError(): string {
    return this.f.confirmPassword.hasError('required')
      ? FormValidators.required
      : this.f.confirmPassword.hasError('exactMatch')
        ? FormValidators.exactMatch
        : FormValidators.none;
  }
  public getEmailError(): string {
    return this.f.email.hasError('required')
      ? FormValidators.required
      : this.f.email.hasError('email')
        ? FormValidators.email
        : FormValidators.none;
  }
  public getPasswordError(): string {
    return this.f.password.hasError('minlength') || this.f.password.hasError('maxlength') || this.f.password.hasError('pattern')
    ? FormValidators.password 
    : this.f.password.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }

  public async cancel(): Promise<void> {
    this.dialogRef.close();
  }
  public async submit(): Promise<void> {
    this._validateForm();
    
    if (this.formSubmitted || !this.form.valid) 
      return;

    this.formSubmitted = true;

    const request = new ResetPasswordRequest().deserialize({
      confirmPassword: this.f.confirmPassword.value,
      email: this.f.email.value,
      newPassword: this.f.password.value,
      passwordResetToken: this.passwordResetToken
      });


    this.identityService.resetPassword(request)
      .subscribe(response => {
        if (response.status === ApiResponses.SUCCESS) {
          Swal.fire('Success', AppAlerts.ResetPassword.success, 'success');
          this.dialogRef.close();
        }
        else if (response.status === ApiResponses.INVALID_PASSWORD_RESET_TOKEN)
          Swal.fire('Error', AppAlerts.ResetPasswordToken.failure, 'error');
        else
          Swal.fire('Error', AppAlerts.ResetPassword.failure, 'error');
        
        this.responseSuccess = response.status === ApiResponses.SUCCESS;
        
        this.formSubmitted = false;
      });
  }

}
