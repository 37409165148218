import { Component, OnInit } from '@angular/core';

import {
  IdentityService,
  UtilityService
} from 'src/app/services';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {

  constructor(
    private identityService: IdentityService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.identityService.signOut();
    this.utilityService.redirectToHome();
  }

}
